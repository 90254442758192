<div class="container" style="font-size: 10px;">
  <div class="row mt-5 mx-5 px-5 justify-content-around">
    <div class="card text-white btn-red-custom mb-3" style="height: 22rem; cursor: pointer;" (click)="navigateToProtection()">
      <div class="border-success card-body text-center mx-5 mt-4 pb-2" style="height: 14rem;">
        <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" fill="currentColor" class="bi bi-person-badge" viewBox="0 0 16 16">
          <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
          <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z"/>
        </svg>
      </div>
      <div class="card-footer text-center pb-2" style="border-top: none; background-color: transparent; height: 6rem;">
        <h2 class="card-text" >PROTECTION</h2>
      </div>
    </div>

    <div class="card text-white btn-red-custom mb-3" style="height: 22rem; cursor: pointer;" (click)="navigateToProtectionBusiness()">
      <div class="border-success card-body text-center mx-5 mt-4 pb-2" style="height: 14rem;">
        <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
          <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
        </svg>
        <!-- <h2 class="card-text mt-3">PROTECTION <br> BUSINESS</h2> -->
      </div>
      
      <div class="card-footer text-center pb-2" style="border-top: none; background-color: transparent; height: 6rem;">
        <h2 class="card-text" style="margin-top: -1rem;">PROTECTION <br> BUSINESS</h2>
      </div>
    </div>
  </div>
</div>