import { Component, OnInit} from '@angular/core';
import { registerLocaleData} from '@angular/common';
import es from '@angular/common/locales/es';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  
  constructor(private router: Router){

  }
  
  ngOnInit(): void {
    
  }

  moduleOpened: String;

  navigateToProtection(){
    this.router.navigateByUrl('/protection');
  }
  
  navigateToProtectionBusiness(){
    this.router.navigateByUrl('/protection-business');
  }

  navigateToLTC(){
    this.router.navigateByUrl('/ltc');
  }

  navigateToHome(){
    this.router.navigateByUrl('');
  }

  onActivate(event) {
    this.moduleOpened = event.moduleId;
  }
}