import { Component, OnInit} from '@angular/core';
import { registerLocaleData} from '@angular/common';
import es from '@angular/common/locales/es';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-protection-business',
  templateUrl: './protection-business.component.html',
  styleUrls: ['./protection-business.component.css']
})
export class ProtectionBusinessComponent implements OnInit {

  moduleId: String = "Protection-business";

  garanzieSelezionate:{garanzia:string, premio: any, premioScontato:any}[];
  
  header: {anni:number,tipoLavoratore:number,professione:{titolo:string,classe:number,typeProfessione:number},tipoAbitazione:number,daAltraCompagnia:boolean,collegatoMutuo:boolean, numeroDipendenti:String, postiLetto: any, settore: any, denominazione: any} =
  {anni:35,tipoLavoratore:1,professione:{titolo: "Commercianti – Negozianti – Esercenti attività alberghiere e ristorazione", classe: 2, typeProfessione: 1},tipoAbitazione:1,daAltraCompagnia:false,collegatoMutuo:false, numeroDipendenti: "Da 0 a 5", postiLetto: "0", settore: "Agricoltura", denominazione: null};

  professioneList: {titolo: string, classe: number, typeProfessione: number}[];
  professioneFiltered: {titolo: string, classe: number, typeProfessione: number}[];

  errorListArray: any;
  
  autonomo = [
    {titolo: "Commercianti – Negozianti – Esercenti attività alberghiere e ristorazione", classe: 2, typeProfessione: 1},
    {titolo: "Libero professionista con sola attività in studio (ad es: avvocati, notai, commercialisti, consulenti di settori vari)", classe: 1, typeProfessione: 1},
    {titolo: "Libero professionista anche con missioni esterne e/o accesso a cantieri", classe: 3, typeProfessione: 1},
    {titolo: "Libero professionista anche con missioni esterne ma senza accesso a cantieri", classe: 2, typeProfessione: 1},
    {titolo: "Professioni sanitarie (medici, paramedici, veterinari) – Farmacisti", classe: 2, typeProfessione: 1},
    {titolo: "Agricoltori, Allevatori (equini, bovini, suini, caprini, struzzi e animali domestici), Boscaioli, Pescatori", classe: 3, typeProfessione: 1},
    {titolo: "Imprenditore o Artigiano settore edilizia, Decoratore", classe: 3, typeProfessione: 1},
    {titolo: "Imprenditore o Artigiano settore lavorazione vetro, legno, montaggio mobili", classe: 3, typeProfessione: 1},
    {titolo: "Imprenditore o Artigiano settore marittimo (pesca, costruzione navi,…), Marinai", classe: 3, typeProfessione: 1},
    {titolo: "Imprenditore o Artigiano settore agricolo (agricoltura, allevamenti, ...)", classe: 3, typeProfessione: 1},
    {titolo: "Imprenditore o Artigiano settore meccanico / chimico, compresi accessi a settori tossici", classe: 3, typeProfessione: 1},
    {titolo: "Insegnante attività sportive (esclusi sport Art. 29/f) – Guida alpina (escluse attività Art. 29/f)", classe: 3, typeProfessione: 1},
    {titolo: "Idraulici, Elettricisti, Ascensoristi, Antennisti, Fabbri, Autoriparatori, Vetrai, Benzinai.", classe: 3, typeProfessione: 1},
    {titolo: "Autotrasportatori, Pony express, Corrieri,", classe: 3, typeProfessione: 1},
    {titolo: "Operatori dello spettacolo ((escluse attività circensi e ballerini professionisti)", classe: 3, typeProfessione: 1},
    {titolo: "Imprenditori o Artigiani altri settori (rispetto alle voci precedenti)", classe: 2, typeProfessione: 1},
    {titolo: "Rappresentanti, Consulenti, Agenti di commercio, Promotori", classe: 2, typeProfessione: 1}
  ];

  nonAutonomo=[
    {titolo: "Forze Armate e dell’ordine con mansioni amministrative interne", classe: 2, typeProfessione: 2},
    {titolo: "Impiegati/ Funzionari/ Dirigenti con sola attività amministrativa", classe: 1, typeProfessione: 2},
    {titolo: "Impiegati/ Funzionari/ Dirigenti anche con missioni esterne e/o accesso a cantieri", classe: 3, typeProfessione: 2},
    {titolo: "Professioni sanitarie (medici, paramedici, veterinari) – Farmacisti", classe: 2, typeProfessione: 2},
    {titolo: "Impiegati/ Funzionari/ Dirigenti anche con missioni esterne ma senza accesso a cantieri", classe: 2, typeProfessione: 2},
    {titolo: "Agricoltori, Allevatori (equini, bovini, suini, caprini, struzzi e animali domestici), Boscaioli, Pescatori", classe: 3, typeProfessione: 2},
    {titolo: "Operai imprese settore edilizia, Decoratore", classe: 3, typeProfessione: 2},
    {titolo: "Operai imprese settore lavorazione vetro, legno, montaggio mobili", classe: 3, typeProfessione: 2},
    {titolo: "Operai imprese settore marittimo (pesca, costruzione navi,…), marinai", classe: 3, typeProfessione: 2},
    {titolo: "Operatori dello spettacolo ((escluse attività circensi e ballerini professionisti)", classe: 3, typeProfessione: 2},
    {titolo: "Operai imprese settore meccanico", classe: 3, typeProfessione: 2},
    {titolo: "Dipendenti con accesso ad ambienti con sostanze tossiche", classe: 3, typeProfessione: 2},
    {titolo: "Idraulici, Elettricisti, Ascensoristi, Antennisti, Fabbri, Autoriparatori, Vetrai, Benzinai.", classe: 3, typeProfessione: 2},
    {titolo: "Operai imprese settore trasporti (Autisti, Corrieri, Pony Express, Postini, Macchinisti…)", classe: 3, typeProfessione: 2},
    {titolo: "Forze Armate e dell’ordine con mansioni esterne (missioni all’estero escluse), Vigile del Fuoco, Guardia Forestale, Guardia Giurata", classe: 3, typeProfessione: 2},
    {titolo: "Operai altri settori – Commessi di esercizi commerciali", classe: 2, typeProfessione: 2},
    {titolo: "Insegnanti – Assistenti sociali", classe: 2, typeProfessione: 2},
    {titolo: "Appartenenti al settore ecclesiastico non missionario", classe: 2, typeProfessione: 2},
    {titolo: "Lavoratori domestici – badanti – baby sitter", classe: 2, typeProfessione: 2},
    {titolo: "Lavoratori in cooperative", classe: 2, typeProfessione: 2},
    {titolo: "Dipendenti settore alberghiero e ristorazione (ad es: camerieri, cuochi, etc..)", classe: 2, typeProfessione: 2}
  ];

  nonLavoratori =[
    {titolo: "Studenti", classe: 2, typeProfessione: 3},
    {titolo: "Casalinghe", classe: 2, typeProfessione: 3},
    {titolo: "Pensionati", classe: 2, typeProfessione: 3},
    {titolo: "Disoccupati", classe: 2, typeProfessione: 3},
    {titolo: "Benestanti", classe: 2, typeProfessione: 3}
  ]

  pianiSalute = {
    individuale:{
      over64:{
        A:"636.00",
        B:"2064.00",
        C:"2712.00",
        D:"300.00"
      },
      under65:{
        A:"636.00",
        B:"936.00",
        C:"1416.00",
        D:"300.00"
      }
    },
    familiare:{
      over64:{
        A:"1620.00",
        B:"3948.00",
        C:"5196.00",
        D:"708.00"
      },
      under65:{
        A:"1620.00",
        B:"2148.00",
        C:"3060.00",
        D:"708.00"
      }
    }
  }
tcpArray = [
  {anni:18,	valore:	0.73},
  {anni:19,	valore:	0.79},
  {anni:20,	valore:	0.84},
  {anni:21,	valore:	0.87},
  {anni:22,	valore:	0.9},
  {anni:23,	valore:	0.91},
  {anni:24,	valore:	0.93},
  {anni:25,	valore:	0.92},
  {anni:26,	valore:	0.92},
  {anni:27,	valore:	0.92},
  {anni:28,	valore:	0.92},
  {anni:29,	valore:	0.91},
  {anni:30,	valore:	0.93},
  {anni:31,	valore:	0.94},
  {anni:32,	valore:	0.98},
  {anni:33,	valore:	1.04},
  {anni:34,	valore:	1.11},
  {anni:35,	valore:	1.17},
  {anni:36,	valore:	1.23},
  {anni:37,	valore:	1.28},
  {anni:38,	valore:	1.37},
  {anni:39,	valore:	1.49},
  {anni:40,	valore:	1.6},
  {anni:41,	valore:	1.72},
  {anni:42,	valore:	1.86},
  {anni:43,	valore:	2.01},
  {anni:44,	valore:	2.23},
  {anni:45,	valore:	2.45},
  {anni:46,	valore:	2.67},
  {anni:47,	valore:	2.97},
  {anni:48,	valore:	3.22},
  {anni:49,	valore:	3.54},
  {anni:50,	valore:	3.87},
  {anni:51,	valore:	4.29},
  {anni:52,	valore:	4.76},
  {anni:53,	valore:	5.43},
  {anni:54,	valore:	5.82},
  {anni:55,	valore:	6.36},
  {anni:56,	valore:	7.03},
  {anni:57,	valore:	7.91},
  {anni:58,	valore:	8.67},
  {anni:59,	valore:	9.8},
  {anni:60,	valore:	10.58},
  {anni:61,	valore:	11.44},
  {anni:62,	valore:	12.47},
  {anni:63,	valore:	13.42},
  {anni:64,	valore:	14.83},
  {anni:65,	valore:	16.58},
  {anni:66,	valore:	18.54},
  {anni:67,	valore:	20.6},
  {anni:68,	valore:	22.68},
  {anni:69,	valore:	25.27},
  {anni:70,	valore:	28.22},
  {anni:71,	valore:	31.28},
  {anni:72,	valore:	34.87},
  {anni:73,	valore:	38.61},
  {anni:74,	valore:43.01}
]

graviMalattieArray = [
  {anni:18,	valore: 25},
  {anni:19,	valore: 25},
  {anni:20,	valore: 25},
  {anni:21,	valore: 25},
  {anni:22,	valore: 25},
  {anni:23,	valore: 25},
  {anni:24,	valore: 25},
  {anni:25,	valore: 25},
  {anni:26,	valore: 25},
  {anni:27,	valore: 25},
  {anni:28,	valore: 25},
  {anni:29,	valore: 25},
  {anni:30,	valore: 50},
  {anni:31,	valore: 50},
  {anni:32,	valore: 50},
  {anni:33,	valore: 50},
  {anni:34,	valore: 50},
  {anni:35,	valore: 50},
  {anni:36,	valore: 50},
  {anni:37,	valore: 50},
  {anni:38,	valore: 50},
  {anni:39,	valore: 50},
  {anni:40,	valore: 125},
  {anni:41,	valore: 125},
  {anni:42,	valore: 125},
  {anni:43,	valore: 125},
  {anni:44,	valore: 125},
  {anni:45,	valore: 125},
  {anni:46,	valore: 125},
  {anni:47,	valore: 125},
  {anni:48,	valore: 125},
  {anni:49,	valore: 125},
  {anni:50,	valore:	250},
  {anni:51,	valore:	250},
  {anni:52,	valore:	250},
  {anni:53,	valore:	250},
  {anni:54,	valore:	250},
  {anni:55,	valore:	250},
  {anni:56,	valore:	250},
  {anni:57,	valore:	250},
  {anni:58,	valore:	250},
  {anni:59,	valore:	250},
  {anni:60,	valore:	355},
  {anni:61,	valore:	355},
  {anni:62,	valore:	355},
  {anni:63,	valore:	355},
  {anni:64,	valore:	355}
]

furtoArray1 = {
  entry:{title:"Entry Level - 5.000 €", val:1},
  base:{title:"Entry Level - 5.000 €", val:1}
}

furtoArray2 = {
  entry:{title:"Entry Level - 5.000 €", val:1},
  base:{title:"Entry Level - 5.000 €", val:1},
  plus:{title:"Entry Level - 5.000 €", val:1}
}

furtoArray3 = {
  entry:{title:"Entry Level - 5.000 €", val:1},
  base:{title:"Entry Level - 5.000 €", val:1},
  plus:{title:"Entry Level - 5.000 €", val:1},
  extra:{title:"Entry Level - 5.000 €", val:1}
}

  classiRischio: {id: any, codiceAteco: String, descrizione: String, settorePB: String, categoriaPB: String, codiceAttivitaPB: String, denominazioneAttivita: String, classeIncendio: String, classeFurto: String, classeRCT: String, classeRCO: String}[];
  filteredClassiRischio: {id: any, codiceAteco: String, descrizione: String, settorePB: String, categoriaPB: String, codiceAttivitaPB: String, denominazioneAttivita: String, classeIncendio: String, classeFurto: String, classeRCT: String, classeRCO: String}[];
  classeAttiva: any;
  rct: {dipendenti: {somma:any, numeroDipendenti:any, classeRischio: any, premio:any}[], postiLetto: {somma:any, postiLetto:any, classeRischio: any, premio:any}[]}
  rco: {dipendenti: {somma:any, numeroDipendenti:any, classeRischio: any, premio:any}[], postiLetto: {somma:any, postiLetto:any, classeRischio: any, premio:any}[]}

  sconto: number = 0;
  numeroSelezionati: number;
  totale: number;
  mensilizzato: number;
  totaleScontato: number;
  mensilizzatoScontato: number;
  
  activeMorte: number = 0;
  activeIPInfortunio: number = 0;
  activeITInfortunio: number = 0;
  activeSpeseCura: number = 0;
  activeDiariaRicovero: number = 0;
  activeContoProtetto: number = 0;
  
  activeIPMalattia: number = 0;
  activePianoA: number = 0;
  activePianoB: number = 0;
  activePianoC: number = 0;
  activePianoD: number = 0;
  activeAssistenzaPersona: number = 0;
  activeAssistenzaTravel: number = 30.00 ;
  activeAssistenzaTravelGold: number = 20.00 ;
  activeTCP: number = 0;
  activeGraviMalattie: number = 0;
  activeRCTerzi: number = 0;
  activeRCPrestatori: number = 0;
  activeRCProprieta: number = 0;
  activeTutelaLegale: number = 0;
  activeIncendioFabbricato: number = 0;
  activeIncendioContenuto: number = 0;
  activeRischioLocativo: number = 0;
  activeFurtoContenuto: number = 0;
  activePos: number = 0;
  activeEnergieRinnovabili: number = 0;
  activeElettronica: number = 0;

  scontatoMorte: number;
  scontatoIPInfortunio: number;
  scontatoITInfortunio: number;
  scontatoSpeseCura: number;
  scontatoDiariaRicovero: number;
  scontatoIPMalattia: number;
  scontatoContoProtetto: number;
  scontatoPianoA: number;
  scontatoPianoB: number;
  scontatoPianoC: number;
  scontatoPianoD: number;
  scontatoAssistenzaPersona: number;
  scontatoAssistenzaTravel: number;
  scontatoAssistenzaTravelGold: number;
  scontatoTCP: number;
  scontatoGraviMalattie: number;
  scontatoRCTerzi: number;
  scontatoRCPrestatori: number;
  scontatoRCProprieta: number;
  scontatoTutelaLegale: number;
  scontatoIncendioFabbricato: number;
  scontatoIncendioContenuto: number;
  scontatoRischioLocativo: number;
  scontatoFurtoContenuto: number;
  scontatoPos: number;
  scontatoEnergieRinnovabili: number;
  scontatoElettronica: number;

  selMorte: boolean = false;
  selIPInfortunio: boolean = false;
  selITInfortunio: boolean = false;
  selSpeseCura: boolean = false;
  selDiariaRicovero: boolean = false;
  selContoProtetto: boolean = false;
  selIPMalattia: boolean = false;
  selPianoA: boolean = false;
  selPianoB: boolean = false;
  selPianoC: boolean = false;
  selPianoD: boolean = false;
  selAssistenzaPersona: boolean = false;
  selAssistenzaTravel: boolean = false;
  selAssistenzaTravelGold: boolean = false;
  selTCP: boolean = false;
  selGraviMalattie: boolean = false;
  selRCTerzi: boolean = false;
  selRCPrestatori: boolean = false;
  selRCProprieta: boolean = false;
  selTutelaLegale: boolean = false;
  selIncendioFabbricato: boolean = false;
  selIncendioContenuto: boolean = false;
  selRischioLocativo: boolean = false;
  selFurtoContenuto: boolean = false;
  selPos: boolean = false;
  selEnergieRinnovabili: boolean = false;
  selElettronica: boolean = false;

  sommaMorte: any = 100000;
  premioMorte: string;

  sommaIPInfortunio: number = 100000;
  premioIPInfortunio: string;

  sommaITInfortunio: number = 1;
  premioITInfortunio: string;

  sommaSpeseCura: number = 1;
  premioSpeseCura: string;

  sommaDiariaRicovero: number = 1;
  premioDiariaRicovero: string = "71.75";

  sommaContoProtetto: number = 50000;
  premioContoProtetto: string = "64.70";

  sommaIPMalattia: number = 0;
  premioIPMalattia: any = "Non disponibile";

  premioPianoSaluteA: any = "Non disponibile";
  premioPianoSaluteB: any = "Non disponibile";
  premioPianoSaluteC: any = "Non disponibile";
  premioPianoSaluteD: any = "Non disponibile";
  tipoPianoA: number = 1;
  tipoPianoB: number = 1;
  tipoPianoC: number = 1;
  tipoPianoD: number = 1;

  premioAssistenzaPersona: string = "13.20";
  premioAssistenzaTravel: string = "30,00";
  premioAssistenzaTravelGold: string = "20,00";

  sommaTCP: number = 100000;
  premioTCP: any = "Non disponibile";

  premioGraviMalattie: any = "Non disponibile";

  sommaRCTerzi: number = 1;
  premioRCTerzi: any = "306.33";
  
  sommaRCPrestatori: number = 1;
  premioRCPrestatori: any = "65.31";

  sommaRCProprieta: number = 1;
  premioRCProprieta: any = "7.18";
  
  sommaTutelaLegale: number = 1;
  premioTutelaLegale: any = "339.00";
  
  sommaIncendioFabbricato: number = 500000;
  premioIncendioFabbricato: any = "00.00";
  sommaIncendioContenuto: number = 50000;
  premioIncendioContenuto: any = "00.00";
  sommaRischioLocativo: number = 100000;
  premioRischioLocativo: any = "00.00";

  sommaFurtoContenuto: number = 1;
  premioFurtoContenuto: any = 103.90;
  
  sommaPos: number = 50000;
  premioPos: any = 223.58;

  sommaEnergieRinnovabili: number = 10000;
  premioEnergieRinnovabili: any = "00.00";

  sommaElettronica: number = 1;
  premioElettronica: any = 117.10;

  errorAge: boolean = false;
  
  sommaMorteIsBlocked: boolean = true;
  sommaIPInfortunioIsBlocked: boolean = true;
  sommaIPMalattiaIsBlocked: boolean = true;
  sommaTCPIsBlocked: boolean = true;
  sommaIncendioFabbricatoIsBlocked: boolean = true;
  sommaIncendioContenutoIsBlocked: boolean = true;
  sommaRischioLocativoIsBlocked: boolean = true;
  sommaEnergieRinnovabiliIsBlocked: boolean = true;

  tempMorte: number = 1000;
  tempIPInfortunio: number = 100000;
  tempIPMalattia: number = 100000;
  tempTCP: number = 100000;
  tempIncendioFabbricato: number = 500000;
  tempIncendioContenuto: number = 50000;
  tempRischioLocativo: number = 100000;
  tempEnergieRinnovabili: number = 10000;

  professioneFilterString: string;
  classeFilterString: string;

  prof:number = 0;

  constructor(private http: HttpClient) { }
  
  ngOnInit(): void {
    this.classeAttiva = {id:1, codiceAteco: "01.11.10", descrizione: "Coltivazione Di Cereali (Escluso Il Riso)", settorePB: "Agricoltura ", categoriaPB: "Azienda agricola", codiceAttivitaPB: "100003", denominazioneAttivita: "az. agric. e fabbr. civ. con coltivaz. prod. agric. destinati anche alla vendita, con allevam. bestiame, senza stalle, fienili e trasform. prod. agric.", classeIncendio: "C", classeFurto: "B", classeRCT: "C", classeRCO: "C"}
    registerLocaleData( es );
    this.http.get("assets/files/rct.json").subscribe(data2=>{
      this.http.get("assets/files/rco.json").subscribe(data3=>{
        this.rct = <any>data2;
        this.rco = <any>data3;
        this.setTipoLavoratore(1);
        this.http.get("assets/files/classiRischio.json").subscribe(data=>{
          this.classiRischio = <any>data;
          this.header.settore = "Agricoltura";
          this.setSettoreAttivita("Agricoltura")
        })
      })
    })
  }

  setSettoreAttivita(settore: string){
    this.filteredClassiRischio = [];
    for (let i = 0; i < this.classiRischio.length; i++) {
      if (this.classiRischio[i].settorePB.toUpperCase().includes(settore.toUpperCase())) {
        this.filteredClassiRischio.push(this.classiRischio[i])
      }
    }
    this.header.denominazione = this.filteredClassiRischio[0].id;
    this.setClasse();
  }

  setClasse(){
    for (let i = 0; i < this.filteredClassiRischio.length; i++) {
      if (this.filteredClassiRischio[i].id == this.header.denominazione) {
        this.classeAttiva = this.filteredClassiRischio[i];
        if (this.header.settore != this.classeAttiva.settorePB) {
          this.header.settore = this.classeAttiva.settorePB;
        }
      }
    }
    this.calcolaPremi();
  }
  
    // Metodi per blocco campi input (principalmente per effetto grafico)
    lockMorte(){
      this.sommaMorteIsBlocked = true;
      this.sommaMorte = this.tempMorte;
      this.calcolaPremi();
    }
    
    unlockMorte(){
      this.sommaMorteIsBlocked = false;
      this.calcolaPremi();
    }
    
    lockIPInfortunio(){
      this.sommaIPInfortunioIsBlocked = true;
      this.sommaIPInfortunio = this.tempIPInfortunio;
      this.calcolaPremi();
    }
  
    unlockIPInfortunio(){
      this.sommaIPInfortunioIsBlocked = false;
      this.calcolaPremi();
    }
  
    lockIPMalattia(){
      this.sommaIPMalattiaIsBlocked = true;
      this.sommaIPMalattia = this.tempIPMalattia;
      this.calcolaPremi();
    }
  
    unlockIPMalattia(){
      this.sommaIPMalattiaIsBlocked = false;
      this.calcolaPremi();
    }
    
    lockTCP(){
      this.sommaTCPIsBlocked = true;
      this.sommaTCP = this.tempTCP;
      this.calcolaPremi();
    }
  
    unlockTCP(){
      this.sommaTCPIsBlocked = false;
      this.calcolaPremi();
    }
    
    lockIncendioFabbricato(){
      this.sommaIncendioFabbricatoIsBlocked = true;
      this.sommaIncendioFabbricato = this.tempIncendioFabbricato;
      this.calcolaPremi();
    }
  
    unlockIncendioFabbricato(){
      this.sommaIncendioFabbricatoIsBlocked = false;
      this.calcolaPremi();
    }
    
    lockIncendioContenuto(){
      this.sommaIncendioContenutoIsBlocked = true;
      this.sommaIncendioContenuto = this.tempIncendioContenuto;
      this.calcolaPremi();
    }
  
    unlockIncendioContenuto(){
      this.sommaIncendioContenutoIsBlocked = false;
      this.calcolaPremi();
    }
    
    lockRischioLocativo(){
      this.sommaRischioLocativoIsBlocked = true;
      this.sommaRischioLocativo = this.tempRischioLocativo;
      this.calcolaPremi();
    }
  
    unlockRischioLocativo(){
      this.sommaRischioLocativoIsBlocked = false;
      this.calcolaPremi();
    }
    
    lockEnergieRinnovabili(){
      this.sommaEnergieRinnovabiliIsBlocked = true;
      this.sommaEnergieRinnovabili = this.tempEnergieRinnovabili;
      this.calcolaPremi();
    }
  
    unlockEnergieRinnovabili(){
      this.sommaEnergieRinnovabiliIsBlocked = false;
      this.calcolaPremi();
    }

  // Metodi Header

  changeAbitazione(type: number){
    this.header.tipoAbitazione = type;
    this.sommaFurtoContenuto = 1;
    this.calcolaPremi()
  }

  setTipoLavoratore(type: number){
    this.header.tipoLavoratore = type;
    this.professioneFilterString = "";
    if (type==1) {
      this.professioneList = this.autonomo;
      this.professioneFiltered = this.autonomo;
      this.header.professione.classe = this.professioneList[0].classe;
    } else if (type==2) {
      this.professioneList = this.nonAutonomo;
      this.professioneFiltered = this.nonAutonomo;
      this.header.professione.classe = this.professioneList[0].classe;
    } else if (type==3) {
      this.professioneList = this.nonLavoratori;
      this.professioneFiltered = this.nonLavoratori;
      this.header.professione.classe = this.professioneList[0].classe;
    }
    this.calcolaPremi();
  }

  filterProfessione(){
    this.professioneFiltered = [];
    for (let i = 0; i < this.autonomo.length; i++) {
      if (this.autonomo[i].titolo.toLowerCase().includes(this.professioneFilterString.toLowerCase())) {
        this.professioneFiltered.push(this.autonomo[i])
      }
    }
    for (let i = 0; i < this.nonAutonomo.length; i++) {
      if (this.nonAutonomo[i].titolo.toLowerCase().includes(this.professioneFilterString.toLowerCase())) {
        this.professioneFiltered.push(this.nonAutonomo[i])
      }
    }
    for (let i = 0; i < this.nonLavoratori.length; i++) {
      if (this.nonLavoratori[i].titolo.toLowerCase().includes(this.professioneFilterString.toLowerCase())) {
        this.professioneFiltered.push(this.nonLavoratori[i])
      }
    }
    this.header.professione = this.professioneFiltered[0]
    this.calcolaPremi();
  }

  filterClasse(){
    this.filteredClassiRischio = [];
    for (let i = 0; i < this.classiRischio.length; i++) {
      if (this.classiRischio[i].denominazioneAttivita.toLowerCase().includes(this.classeFilterString.toLowerCase())) {
        if (!this.classiRischio[i].settorePB.includes("RISERVATO DIREZIONE") && !this.classiRischio[i].settorePB.includes("RISCHIO ESCLUSO")) {
          this.filteredClassiRischio.push(this.classiRischio[i])
        }
      }
    }
    this.classeAttiva = this.filteredClassiRischio[0];
    this.calcolaPremi();
  }

  selectProfessione(){
    this.header.professione = this.professioneFiltered[this.prof];
    this.header.tipoLavoratore = this.professioneFiltered[this.prof].typeProfessione;
    this.calcolaPremi();
  }

  daAltraCompagnia(type: number){
    if (type==1) {
      this.header.daAltraCompagnia = true;
    } else {
      this.header.daAltraCompagnia = false;
    }
    this.calcolaPremi();
  }

  collegatoMutuo(type: number){
    if (type==1) {
      this.header.collegatoMutuo = true;
    } else {
      this.header.collegatoMutuo = false;
    }
    this.calcolaPremi();
  }

  ageControl(){
    if (this.header.anni<0 || this.header.anni>100) {
      this.errorAge = true;
    } else {
      this.errorAge = false;
    }
    this.calcolaPremi();
  }

  //Inizializzazione dati

  initialDataLoad(){
    this.garanzieSelezionate = [];
    this.professioneFiltered = [];
    this.professioneList = this.autonomo;
    this.professioneFiltered = this.autonomo;
    this.header.daAltraCompagnia = false;
    this.header.collegatoMutuo = false;
    this.calcolaPremi();
  }

  removeAll(){
    this.selMorte = false;
    this.selIPInfortunio = false;
    this.selITInfortunio = false;
    this.selSpeseCura = false;
    this.selDiariaRicovero = false;
    this.selContoProtetto = false;
    this.selIPMalattia = false;
    this.selPianoA = false;
    this.selPianoB = false;
    this.selPianoC = false;
    this.selPianoD = false;
    this.selAssistenzaPersona = false;
    this.selAssistenzaTravel = false;
    this.selAssistenzaTravelGold = false;
    this.selTCP = false;
    this.selGraviMalattie = false;
    this.selRCTerzi = false;
    this.selRCPrestatori = false;
    this.selRCProprieta = false;
    this.selTutelaLegale = false;
    this.selIncendioFabbricato = false;
    this.selIncendioContenuto = false;
    this.selRischioLocativo = false;
    this.selFurtoContenuto = false;
    this.selPos = false;
    this.selEnergieRinnovabili = false;
    this.selElettronica = false;
    this.calcolaPremi();
  }

  checkPianoSalute(n:number, e:any){
    if (n==1) {
      this.selPianoA = e.target.checked;
      this.selPianoB = false;
      this.selPianoC = false;
    } 
     if (n==2) {
      this.selPianoA = false;
      this.selPianoB = e.target.checked;
      this.selPianoC = false;
    } 
     if (n==3) {
      this.selPianoA = false;
      this.selPianoB = false;
      this.selPianoC = e.target.checked;
    }
    this.calcolaPremi();
  }

  // Casi particolari che richiedono altro prima del metodo principale
  calcoliMorte(n: number){
    if (n==1) {
      this.selTCP = false;
      this.activeTCP = null;
      this.selIPInfortunio = true;
    } else if(n==2){
      this.selMorte = false;
      this.activeMorte = null;
      for (let i = 0; i < this.tcpArray.length; i++) {
        if (this.header.anni == this.tcpArray[i].anni) {
          this.premioTCP = (this.sommaTCP*this.tcpArray[i].valore)/1000;
        }
      }
      if (this.selGraviMalattie) {
        for (let j = 0; j < this.graviMalattieArray.length; j++) {
          if (this.header.anni == this.graviMalattieArray[j].anni) {
            this.premioGraviMalattie = this.graviMalattieArray[j].valore;
          }
        }
      }
    } else if (n==3){
      if (!this.selIPInfortunio) {
        this.selMorte = false;
      }
    }
    if (this.header.anni>75) {
      this.activeTCP = null;
      this.premioTCP = "Non disponibile"
      this.selTCP = false;
    }
    this.calcolaPremi();
  }

  incendioRischio(type: number){
    if (type == 1) {
      this.selRischioLocativo = false;
    } else if (type == 2){
      this.selIncendioFabbricato = false;
    }
    this.calcolaPremi();
  }

  selezioneTipoPianoSalute(type: number){
    this.tipoPianoA=type;
    this.tipoPianoB=type;
    this.tipoPianoC=type;
    this.tipoPianoD=type;
    this.calcolaPremi();
  }

  // Fine casi particolari che richiedono altro prima del metodo principale


  calcolaPremi(){
    // Controllo limiti max min

    if (this.sommaIPInfortunio>500000) {
      this.sommaIPInfortunio = 500000;
      this.tempIPInfortunio = 500000;
    }
    if (this.sommaMorte>500000) {
      this.sommaMorte = 500000;
      this.tempMorte = 500000;
    }
    // if (this.sommaMorte<(this.sommaIPInfortunio/3)) {
    //   this.sommaMorte=Math.ceil(this.sommaIPInfortunio/3)
    //   this.tempMorte=Math.ceil(this.sommaIPInfortunio/3)
    // }
    if (isNaN(this.sommaMorte)) {
      this.sommaMorte = 0;
      this.tempMorte = 0;
    }
    this.sommaIPMalattia = this.sommaIPInfortunio;
    if (this.sommaIPMalattia>250000) {
      this.sommaIPMalattia = 250000;
      this.tempIPMalattia = 250000;
    }
    if (this.header.anni<61) {
      if (this.sommaTCP>300000) {
        this.sommaTCP = 300000;
        this.tempTCP = 300000;
      }
    } else if (this.header.anni<71 && this.header.anni>60 ){
      if (this.sommaTCP>200000) {
        this.sommaTCP = 200000;
        this.tempTCP = 200000;
      }
    }
    if (this.sommaIncendioFabbricato>2000000) {
      this.sommaIncendioFabbricato = 2000000;
      this.tempIncendioFabbricato = 2000000;
    }
    if (this.sommaIncendioContenuto>250000) {
      this.sommaIncendioContenuto = 250000;
      this.tempIncendioContenuto = 250000;
    }
    if (this.sommaRischioLocativo>2000000) {
      this.sommaRischioLocativo = 2000000;
      this.tempRischioLocativo = 2000000;
    }
    if (this.sommaEnergieRinnovabili>80000) {
      this.sommaEnergieRinnovabili = 80000;
      this.tempEnergieRinnovabili = 80000;
    }
    // Fine controllo limini max min

    this.calcoloPianiSalute();
    if (this.header.anni>17 && this.header.anni<65) {
      for (let j = 0; j < this.graviMalattieArray.length; j++) {
        if (this.header.anni == this.graviMalattieArray[j].anni) {
          this.premioGraviMalattie = this.graviMalattieArray[j].valore;
        }
      }
    } else {
      this.selGraviMalattie = false;
      this.premioGraviMalattie = "Non disponibile";
    }

    if (this.header.anni>74) {
      this.premioContoProtetto = "Non disponibile";
    } else {
      this.premioContoProtetto = "64.70";
    }

    if (this.sommaDiariaRicovero==1) {
      if (this.header.anni<26) {
        this.premioDiariaRicovero = "71.75";
      } else if (this.header.anni>25 && this.header.anni<36) {
        this.premioDiariaRicovero = "77.90";
      } else if (this.header.anni>35 && this.header.anni<46) {
        this.premioDiariaRicovero = "101.48";
      } else if (this.header.anni>45 && this.header.anni<56) {
        this.premioDiariaRicovero = "107.63";
      } else if (this.header.anni>55 && this.header.anni<66) {
        this.premioDiariaRicovero = "167.08";
      } else if (this.header.anni>66 && this.header.anni<71) {
        this.premioDiariaRicovero = "191.62";
      } else if (this.header.anni>70) {
        this.selDiariaRicovero = false;
        this.premioDiariaRicovero = "Non disponibile";
      }
    } else if (this.sommaDiariaRicovero==2) {
      if (this.header.anni<26) {
        this.premioDiariaRicovero = "107.63";
      } else if (this.header.anni>25 && this.header.anni<36) {
        this.premioDiariaRicovero = "116.85";
      } else if (this.header.anni>35 && this.header.anni<46) {
        this.premioDiariaRicovero = "152.21";
      } else if (this.header.anni>45 && this.header.anni<56) {
        this.premioDiariaRicovero = "161.44";
      } else if (this.header.anni>55 && this.header.anni<66) {
        this.premioDiariaRicovero = "250.61";
      } else if (this.header.anni>66 && this.header.anni<71) {
        this.premioDiariaRicovero = "287.42";
      } else if (this.header.anni>70) {
        this.selDiariaRicovero = false;
        this.premioDiariaRicovero = "Non disponibile";
      }
    } else if (this.sommaDiariaRicovero==3) {
      if (this.header.anni<26) {
        this.premioDiariaRicovero = "143.50";
      } else if (this.header.anni>25 && this.header.anni<36) {
        this.premioDiariaRicovero = "155.80";
      } else if (this.header.anni>35 && this.header.anni<46) {
        this.premioDiariaRicovero = "202.95";
      } else if (this.header.anni>45 && this.header.anni<56) {
        this.premioDiariaRicovero = "215.25";
      } else if (this.header.anni>55 && this.header.anni<66) {
        this.premioDiariaRicovero = "334.15";
      } else if (this.header.anni>66 && this.header.anni<71) {
        this.premioDiariaRicovero = "383.24";
      } else if (this.header.anni>70) {
        this.selDiariaRicovero = false;
        this.premioDiariaRicovero = "Non disponibile";
      }
    }
    if (this.header.professione.classe==1) {
      this.premioMorte = (0.00086* this.sommaMorte).toFixed(2);
      this.premioIPInfortunio= (0.00110* this.sommaIPInfortunio).toFixed(2);
      if (this.sommaITInfortunio==1) {
        this.premioITInfortunio = "168.50";
      } else if (this.sommaITInfortunio==2) {
        this.premioITInfortunio = "252.75";
      } else if (this.sommaITInfortunio==3) {
        this.premioITInfortunio = "337.00";
      } else if (this.sommaITInfortunio==4) {
        this.premioITInfortunio = "505.50";
      }
      if (this.sommaSpeseCura==1) {
        this.premioSpeseCura = "47.23";
      } else if (this.sommaSpeseCura==2) {
        this.premioSpeseCura = "60.52";
      } else if (this.sommaSpeseCura==3) {
        this.premioSpeseCura = "73.79";
      } else if (this.sommaSpeseCura==4) {
        this.premioSpeseCura = "87.07";
      } else if (this.sommaSpeseCura==5) {
        this.premioSpeseCura = "108.84";
      }
    } else if (this.header.professione.classe==2){
      this.premioMorte = (0.00106* this.sommaMorte).toFixed(2);
      this.premioIPInfortunio= (0.00136* this.sommaIPInfortunio).toFixed(2);
      if (this.sommaITInfortunio==1) {
        this.premioITInfortunio = "205.00";
      } else if (this.sommaITInfortunio==2) {
        this.premioITInfortunio = "307.50";
      } else if (this.sommaITInfortunio==3) {
        this.premioITInfortunio = "410.00";
      } else if (this.sommaITInfortunio==4) {
        this.premioITInfortunio = "615.00";
      }
      if (this.sommaSpeseCura==1) {
        this.premioSpeseCura = "51.29";
      } else if (this.sommaSpeseCura==2) {
        this.premioSpeseCura = "65.73";
      } else if (this.sommaSpeseCura==3) {
        this.premioSpeseCura = "80.16";
      } else if (this.sommaSpeseCura==4) {
        this.premioSpeseCura = "94.58";
      } else if (this.sommaSpeseCura==5) {
        this.premioSpeseCura = "118.22";
      }
    } else if (this.header.professione.classe==3){
      this.premioMorte = (0.00177* this.sommaMorte).toFixed(2);
      this.premioIPInfortunio= (0.00218* this.sommaIPInfortunio).toFixed(2);
      if (this.sommaITInfortunio==1) {
        this.premioITInfortunio = "363.50";
      } else if (this.sommaITInfortunio==2) {
        this.premioITInfortunio = "545.25";
      } else if (this.sommaITInfortunio==3) {
        this.premioITInfortunio = "727.00";
      } else if (this.sommaITInfortunio==4) {
        this.premioITInfortunio = "1090.50";
      }
      if (this.sommaSpeseCura==1) {
        this.premioSpeseCura = "75.66";
      } else if (this.sommaSpeseCura==2) {
        this.premioSpeseCura = "96.95";
      } else if (this.sommaSpeseCura==3) {
        this.premioSpeseCura = "118.23";
      } else if (this.sommaSpeseCura==4) {
        this.premioSpeseCura = "139.52";
      } else if (this.sommaSpeseCura==5) {
        this.premioSpeseCura = "156.53";
      }
    }

    if (this.classeAttiva.classeRCT == 'R.E.' || this.classeAttiva.classeRCT == 'R.D.') {
      this.selRCTerzi = false;
    }
    if (this.classeAttiva.classeRCO == 'R.E.' || this.classeAttiva.classeRCO == 'R.D.') {
      this.selRCPrestatori = false;
    }
    if (this.classeAttiva.classeFurto == 'R.E.' || this.classeAttiva.classeFurto == 'R.D.') {
      this.selFurtoContenuto = false;
    }

    if (this.header.postiLetto==0 || this.header.postiLetto=="0") {
      for (let i = 0; i < this.rct.dipendenti.length; i++) {
        if (this.rct.dipendenti[i].classeRischio == this.classeAttiva.classeRCT &&
          this.rct.dipendenti[i].numeroDipendenti == this.header.numeroDipendenti &&
          this.rct.dipendenti[i].somma == this.sommaRCTerzi
        ) {
          this.premioRCTerzi = parseFloat(this.rct.dipendenti[i].premio);
        } 
      }
    } else {
      for (let i = 0; i < this.rct.postiLetto.length; i++) {
        if (this.rct.postiLetto[i].classeRischio == this.classeAttiva.classeRCT &&
          this.rct.postiLetto[i].postiLetto == this.header.postiLetto &&
          this.rct.postiLetto[i].somma == this.sommaRCTerzi
        ) {
          this.premioRCTerzi = parseFloat(this.rct.postiLetto[i].premio);
        } 
      }
    }

    if (this.header.postiLetto==0 || this.header.postiLetto=="0") {
      for (let i = 0; i < this.rco.dipendenti.length; i++) {
        if (this.rco.dipendenti[i].classeRischio == this.classeAttiva.classeRCT &&
          this.rco.dipendenti[i].numeroDipendenti == this.header.numeroDipendenti &&
          this.rco.dipendenti[i].somma == this.sommaRCPrestatori
        ) {
          this.premioRCPrestatori = parseFloat(this.rco.dipendenti[i].premio);
        } 
      }
    } else {
      for (let i = 0; i < this.rco.postiLetto.length; i++) {
        if (this.rco.postiLetto[i].classeRischio == this.classeAttiva.classeRCT &&
          this.rco.postiLetto[i].postiLetto == this.header.postiLetto &&
          this.rco.postiLetto[i].somma == this.sommaRCPrestatori
        ) {
          this.premioRCPrestatori = parseFloat(this.rco.postiLetto[i].premio);
        } 
      }
    }
    if (this.sommaRCProprieta==1) {
      this.premioRCProprieta = "7.18";
    } else if (this.sommaRCProprieta==2) {
      this.premioRCProprieta = "8.64";
    }

    if (this.selTutelaLegale) {
      if (this.header.numeroDipendenti=="Da 0 a 5") {
        this.premioTutelaLegale= "339.00";
      } else if (this.header.numeroDipendenti=="Da 6 a 10") {
        this.premioTutelaLegale= "542.00";
      } else if (this.header.numeroDipendenti=="Da 11 a 20") {
        this.premioTutelaLegale= "791.00";
      } else if (this.header.numeroDipendenti=="Da 21 a 30") {
        this.premioTutelaLegale= "1130.00";
      } 
    }

    var tassoFurto = 0;
    if (this.classeAttiva.classeFurto=="A") {
      tassoFurto = 13.87;
    } else if (this.classeAttiva.classeFurto=="B") {
      tassoFurto = 21.39;
    } else if (this.classeAttiva.classeFurto=="C") {
      tassoFurto = 29.56;
    } else if (this.classeAttiva.classeFurto=="D") {
      tassoFurto = 47.36;
    } else {
      tassoFurto = 0;
      this.selFurtoContenuto = false;
      this.sommaFurtoContenuto = null;
    }
    if (this.sommaFurtoContenuto==1) {
      this.premioFurtoContenuto = 5*tassoFurto;
    } else if (this.sommaFurtoContenuto==2){
      this.premioFurtoContenuto = 10*tassoFurto;
    } else if (this.sommaFurtoContenuto==3){
      this.premioFurtoContenuto = 15*tassoFurto;
    } else if (this.sommaFurtoContenuto==4){
      this.premioFurtoContenuto = 20*tassoFurto;
    } else if (this.sommaFurtoContenuto==5){
      if (this.classeAttiva.classeFurto=="D" || this.classeAttiva.classeFurto=="C") {
        this.sommaFurtoContenuto = 4;
        this.premioFurtoContenuto = 20*tassoFurto;
      } else {
        this.premioFurtoContenuto = 30*tassoFurto;
      }
    } else if (this.sommaFurtoContenuto==6){
      if (this.classeAttiva.classeFurto=="D" || this.classeAttiva.classeFurto=="C") {
        this.sommaFurtoContenuto = 4;
        this.premioFurtoContenuto = 20*tassoFurto;
      } else {
        this.premioFurtoContenuto = 40*tassoFurto;
      }
    }
    
    if (this.header.anni < 26) {
      this.premioIPMalattia = this.sommaIPMalattia*0.00082;
    } else if(this.header.anni < 36 && this.header.anni > 25) {
      this.premioIPMalattia = this.sommaIPMalattia*0.00092;
    } else if(this.header.anni < 46 && this.header.anni > 34) {
      this.premioIPMalattia = this.sommaIPMalattia*0.00118;
    } else if(this.header.anni < 56 && this.header.anni > 45) {
      this.premioIPMalattia = this.sommaIPMalattia*0.00164;
    } else if(this.header.anni < 66 && this.header.anni > 55) {
      this.premioIPMalattia = this.sommaIPMalattia*0.0032;
    } else if(this.header.anni > 65) { 
      this.selIPMalattia = false;
      this.premioIPMalattia = "Non disponibile";
    }
    
    if (this.classeAttiva.classeIncendio=="A") {
      this.premioIncendioFabbricato = (this.sommaIncendioFabbricato/1000)*1.36;
      this.premioIncendioContenuto = (this.sommaIncendioContenuto/1000)*3.58;
      this.premioRischioLocativo = (this.sommaRischioLocativo/1000)*0.82;
    } else if (this.classeAttiva.classeIncendio=="B") {
      this.premioIncendioFabbricato = (this.sommaIncendioFabbricato/1000)*1.86;
      this.premioIncendioContenuto = (this.sommaIncendioContenuto/1000)*4.19;
      this.premioRischioLocativo = (this.sommaRischioLocativo/1000)*1.13;
    } else if (this.classeAttiva.classeIncendio=="C") {
      this.premioIncendioFabbricato = (this.sommaIncendioFabbricato/1000)*2.21;
      this.premioIncendioContenuto = (this.sommaIncendioContenuto/1000)*4.59;
      this.premioRischioLocativo = (this.sommaRischioLocativo/1000)*1.32;
    } else if (this.classeAttiva.classeIncendio=="D") {
      this.premioIncendioFabbricato = (this.sommaIncendioFabbricato/1000)*3.06;
      this.premioIncendioContenuto = (this.sommaIncendioContenuto/1000)*5.59;
      this.premioRischioLocativo = (this.sommaRischioLocativo/1000)*1.83;
    }else {
      this.selIncendioFabbricato = false;
      this.selIncendioContenuto = false;
      this.selRischioLocativo = false;
      this.premioIncendioFabbricato = null;
      this.premioIncendioContenuto = null;
      this.premioRischioLocativo = null;
    }

    this.premioEnergieRinnovabili = (this.sommaEnergieRinnovabili/1000)*11;

    if (this.sommaElettronica == 1) {
      this.premioElettronica = 117.70;
    } else if (this.sommaElettronica == 2) {
      this.premioElettronica = 235.40;
    } else if (this.sommaElettronica == 3) {
      this.premioElettronica = 353.10;
    } else if (this.sommaElettronica == 4) {
      this.premioElettronica = 470,80;
    } else if (this.sommaElettronica == 5) {
      this.premioElettronica = 706.20;
    } 
    // Fine calcoli vari in base a selezioni
    
    // Attivazione o disattivazione assistenze
    if (this.selMorte || this.selIPInfortunio || this.selITInfortunio || this.selSpeseCura || this.selDiariaRicovero ||
      this.selPianoA || this.selPianoB || this.selPianoC || this.selPianoD || this.selTCP) {
      this.selAssistenzaPersona = true;
    } else {
      this.selAssistenzaPersona = false;
      this.selAssistenzaTravel = false;
      this.selAssistenzaTravelGold = false;
    }
    // Fine attivazione o disattivazione assistenze

    // Metodo per calcolo dei selezionati singoli o in base ai legami
    this.checkActive();

    // Applicazione sconti dove necessario
    this.scontatoMorte = parseFloat((this.activeMorte-((this.activeMorte/100)*this.sconto)).toFixed(2))
    this.scontatoIPInfortunio = parseFloat((this.activeIPInfortunio-((this.activeIPInfortunio/100)*this.sconto)).toFixed(2))
    this.scontatoITInfortunio = parseFloat((this.activeITInfortunio-((this.activeITInfortunio/100)*this.sconto)).toFixed(2))
    this.scontatoSpeseCura = parseFloat((this.activeSpeseCura-((this.activeSpeseCura/100)*this.sconto)).toFixed(2))
    this.scontatoDiariaRicovero = parseFloat((this.activeDiariaRicovero-((this.activeDiariaRicovero/100)*this.sconto)).toFixed(2))
    this.scontatoContoProtetto = parseFloat((this.activeContoProtetto-((this.activeContoProtetto/100)*this.sconto)).toFixed(2))
    this.scontatoIPMalattia = parseFloat((this.activeIPMalattia-((this.activeIPMalattia/100)*this.sconto)).toFixed(2))
    this.scontatoPianoA = this.activePianoA
    this.scontatoPianoB = this.activePianoB
    this.scontatoPianoC = this.activePianoC
    this.scontatoPianoD = this.activePianoD
    this.scontatoAssistenzaPersona = this.activeAssistenzaPersona
    this.scontatoAssistenzaTravel = this.activeAssistenzaTravel
    this.scontatoAssistenzaTravelGold = this.activeAssistenzaTravelGold
    this.scontatoTCP = this.activeTCP
    this.scontatoGraviMalattie = this.activeGraviMalattie
    this.scontatoRCTerzi = parseFloat((this.activeRCTerzi-((this.activeRCTerzi/100)*this.sconto)).toFixed(2))
    this.scontatoRCPrestatori = parseFloat((this.activeRCPrestatori-((this.activeRCPrestatori/100)*this.sconto)).toFixed(2))
    this.scontatoRCProprieta = parseFloat((this.activeRCProprieta-((this.activeRCProprieta/100)*this.sconto)).toFixed(2))
    this.scontatoTutelaLegale = this.activeTutelaLegale;
    this.scontatoIncendioFabbricato = parseFloat((this.activeIncendioFabbricato-((this.activeIncendioFabbricato/100)*this.sconto)).toFixed(2))
    this.scontatoIncendioContenuto = parseFloat((this.activeIncendioContenuto-((this.activeIncendioContenuto/100)*this.sconto)).toFixed(2))
    this.scontatoRischioLocativo = parseFloat((this.activeRischioLocativo-((this.activeRischioLocativo/100)*this.sconto)).toFixed(2))
    this.scontatoFurtoContenuto = parseFloat((this.activeFurtoContenuto-((this.activeFurtoContenuto/100)*this.sconto)).toFixed(2))
    this.scontatoPos = parseFloat((this.activePos-((this.activePos/100)*this.sconto)).toFixed(2))
    this.scontatoEnergieRinnovabili = parseFloat((this.activeEnergieRinnovabili-((this.activeEnergieRinnovabili/100)*this.sconto)).toFixed(2))
    this.scontatoElettronica = parseFloat((this.activeElettronica-((this.activeElettronica/100)*this.sconto)).toFixed(2))
    // Fine applicazione sconti
    

    // Creazione totali, mensilizzati e carrello
    this.garanzieSelezionate = [];
    this.totale = 0;
    this.totaleScontato = 0;
    this.mensilizzato = 0;
    this.mensilizzatoScontato = 0;
    if (this.selMorte){
      this.totale += parseFloat(this.activeMorte.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoMorte.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"MORTE DA INFORTUNIO",premio:this.activeMorte.toFixed(2), premioScontato:this.scontatoMorte.toFixed(2)})
    }
    if (this.selIPInfortunio){
      this.totale += parseFloat(this.activeIPInfortunio.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoIPInfortunio.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"INVALIDITÀ PERMANENTE DA INFORTUNIO",premio:this.activeIPInfortunio.toFixed(2), premioScontato:this.scontatoIPInfortunio.toFixed(2)})
    }
    if (this.selITInfortunio){
      this.totale += parseFloat(this.activeITInfortunio.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoITInfortunio.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"INABILITÀ TEMPORANEA DA INFORTUNIO",premio:this.activeITInfortunio.toFixed(2), premioScontato:this.scontatoITInfortunio.toFixed(2)})
    }
    if (this.selSpeseCura){
      this.totale += parseFloat(this.activeSpeseCura.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoSpeseCura.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"RIMBORSO SPESE CURE DA INFORTUNIO",premio:this.activeSpeseCura.toFixed(2), premioScontato:this.scontatoSpeseCura.toFixed(2)})
    }
    if (this.selDiariaRicovero){
      this.totale += parseFloat(this.activeDiariaRicovero.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoDiariaRicovero.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"DIARIA DA RICOVERO",premio:this.activeDiariaRicovero.toFixed(2), premioScontato:this.scontatoDiariaRicovero.toFixed(2)})
    }
    if (this.selContoProtetto){
      this.totale += parseFloat(this.activeContoProtetto.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoContoProtetto.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"CONTO PROTETTO",premio:this.activeContoProtetto.toFixed(2), premioScontato:this.scontatoContoProtetto.toFixed(2)})
    }
    if (this.selIPMalattia){
      this.totale += parseFloat(this.activeIPMalattia.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoIPMalattia.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"INVALIDITÀ PERMANENTE DA MALATTIA",premio:this.activeIPMalattia.toFixed(2), premioScontato:this.scontatoIPMalattia.toFixed(2)})
    }
    if (this.selPianoA){
      this.totale += parseFloat(this.activePianoA.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoPianoA.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"Piano A",premio:this.activePianoA.toFixed(2), premioScontato:this.scontatoPianoA.toFixed(2)})
    }
    if (this.selPianoB){
      this.totale += parseFloat(this.activePianoB.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoPianoB.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"Piano B",premio:this.activePianoB.toFixed(2), premioScontato:this.scontatoPianoB.toFixed(2)})
    }
    if (this.selPianoC){
      this.totale += parseFloat(this.activePianoC.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoPianoC.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"Piano C",premio:this.activePianoC.toFixed(2), premioScontato:this.scontatoPianoC.toFixed(2)})
    }
    if (this.selPianoD){
      this.totale += parseFloat(this.activePianoD.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoPianoD.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"Piano D",premio:this.activePianoD.toFixed(2), premioScontato:this.scontatoPianoD.toFixed(2)})
    }
    if (this.selAssistenzaPersona){
      this.totale += parseFloat(this.activeAssistenzaPersona.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoAssistenzaPersona.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"ASSISTENZA PERSONA",premio:this.activeAssistenzaPersona.toFixed(2), premioScontato:this.scontatoAssistenzaPersona.toFixed(2)})
    }
    if (this.selAssistenzaTravel){
      this.totale += parseFloat(this.activeAssistenzaTravel.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoAssistenzaTravel.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"ASSISTENZA TRAVEL",premio:this.activeAssistenzaTravel.toFixed(2), premioScontato:this.scontatoAssistenzaTravel.toFixed(2)})
    }
    if (this.selAssistenzaTravelGold){
      this.totale += parseFloat(this.activeAssistenzaTravelGold.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoAssistenzaTravelGold.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"ASSISTENZA TRAVEL GOLD",premio:this.activeAssistenzaTravelGold.toFixed(2), premioScontato:this.scontatoAssistenzaTravelGold.toFixed(2)})
    }
    if (this.selTCP){
      this.totale += parseFloat(this.activeTCP.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoTCP.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"TEMPORANEA CASO MORTE",premio:this.activeTCP.toFixed(2), premioScontato:this.scontatoTCP.toFixed(2)})
    }
    if (this.selGraviMalattie){
      this.totale += parseFloat(this.activeGraviMalattie.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoGraviMalattie.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"INDENNITARIA GRAVI MALATTIE",premio:this.activeGraviMalattie.toFixed(2), premioScontato:this.scontatoGraviMalattie.toFixed(2)})
    }
    if (this.selRCTerzi){
      this.totale += parseFloat(this.activeRCTerzi.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoRCTerzi.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"R.C. DELLA VITA PRIVATA",premio:this.activeRCTerzi.toFixed(2), premioScontato:this.scontatoRCTerzi.toFixed(2)})
    }
    if (this.selRCPrestatori){
      this.totale += parseFloat(this.activeRCPrestatori.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoRCPrestatori.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"R.C. VERSO PRESTATORI DI LAVORO",premio:this.activeRCPrestatori.toFixed(2), premioScontato:this.scontatoRCPrestatori.toFixed(2)})
    }
    if (this.selRCProprieta){
      this.totale += parseFloat(this.activeRCProprieta.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoRCProprieta.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"R.C. DELLA PROPRIETÀ",premio:this.activeRCProprieta.toFixed(2), premioScontato:this.scontatoRCProprieta.toFixed(2)})
    }
    if (this.selTutelaLegale){
      this.totale += parseFloat(this.activeTutelaLegale.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoTutelaLegale.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"TUTELA LEGALE",premio:this.activeTutelaLegale, premioScontato:this.scontatoTutelaLegale})
    }
    if (this.selIncendioFabbricato){
      this.totale += parseFloat(this.activeIncendioFabbricato.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoIncendioFabbricato.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"INCENDIO FABBRICATO",premio:this.activeIncendioFabbricato.toFixed(2), premioScontato:this.scontatoIncendioFabbricato.toFixed(2)})
    }
    if (this.selIncendioContenuto){
      this.totale += parseFloat(this.activeIncendioContenuto.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoIncendioContenuto.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"INCENDIO CONTENUTO",premio:this.activeIncendioContenuto.toFixed(2), premioScontato:this.scontatoIncendioContenuto.toFixed(2)})
    }
    if (this.selRischioLocativo){
      this.totale += parseFloat(this.activeRischioLocativo.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoRischioLocativo.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"RISCHIO LOCATIVO",premio:this.activeRischioLocativo.toFixed(2), premioScontato:this.scontatoRischioLocativo.toFixed(2)})
    }
    if (this.selFurtoContenuto){
      this.totale += parseFloat(this.activeFurtoContenuto.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoFurtoContenuto.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"FURTO DEL CONTENUTO",premio:this.activeFurtoContenuto.toFixed(2), premioScontato:this.scontatoFurtoContenuto.toFixed(2)})
    }
    if (this.selPos){
      this.totale += parseFloat(this.activePos.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoPos.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"INCASSI POS",premio:this.activePos.toFixed(2), premioScontato:this.scontatoPos.toFixed(2)})
    }
    if (this.selElettronica){
      this.totale += parseFloat(this.activeElettronica.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoElettronica.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"ELETTRONICA E GUASTI MACCHINE",premio:this.activeElettronica.toFixed(2), premioScontato:this.scontatoElettronica.toFixed(2)})
    }
    if (this.selEnergieRinnovabili){
      this.totale += parseFloat(this.activeEnergieRinnovabili.toFixed(2));
      this.totaleScontato += parseFloat(this.scontatoEnergieRinnovabili.toFixed(2));
      this.garanzieSelezionate.push({garanzia:"ENERGIE RINNOVABILI ALL RISK",premio:this.activeEnergieRinnovabili.toFixed(2), premioScontato:this.scontatoEnergieRinnovabili.toFixed(2)})
    }
    this.mensilizzato = parseFloat((this.totale/12).toFixed(2));
    this.mensilizzatoScontato = parseFloat((this.totaleScontato/12).toFixed(2));
    // Fine creazione totali, mensilizzati e carrello
  }

  calcoloPianiSalute(){
    if (this.header.anni>17 && this.header.anni<65) {

      if (this.tipoPianoA==1) {
        this.premioPianoSaluteA = this.pianiSalute.individuale.under65.A
      } else if (this.tipoPianoA==2) {
        this.premioPianoSaluteA = this.pianiSalute.familiare.under65.A
      }

      if (this.tipoPianoB==1) {
        this.premioPianoSaluteB = this.pianiSalute.individuale.under65.B
      } else if (this.tipoPianoB==2) {
        this.premioPianoSaluteB = this.pianiSalute.familiare.under65.B
      }

      if (this.tipoPianoC==1) {
        this.premioPianoSaluteC = this.pianiSalute.individuale.under65.C
      } else if (this.tipoPianoC==2) {
        this.premioPianoSaluteC = this.pianiSalute.familiare.under65.C
      }

      if (this.tipoPianoD==1) {
        this.premioPianoSaluteD = this.pianiSalute.individuale.under65.D
      } else if (this.tipoPianoD==2) {
        this.premioPianoSaluteD = this.pianiSalute.familiare.under65.D
      }

    } else if (this.header.anni>64 && this.header.anni<75) {

      if (this.tipoPianoA==1) {
        this.premioPianoSaluteA = this.pianiSalute.individuale.over64.A
      } else if (this.tipoPianoA==2) {
        this.premioPianoSaluteA = this.pianiSalute.familiare.over64.A
      }

      if (this.tipoPianoB==1) {
        this.premioPianoSaluteB = this.pianiSalute.individuale.over64.B
      } else if (this.tipoPianoB==2) {
        this.premioPianoSaluteB = this.pianiSalute.familiare.over64.B
      }

      if (this.tipoPianoC==1) {
        this.premioPianoSaluteC = this.pianiSalute.individuale.over64.C
      } else if (this.tipoPianoC==2) {
        this.premioPianoSaluteC = this.pianiSalute.familiare.over64.C
      }

      if (this.tipoPianoD==1) {
        this.premioPianoSaluteD = this.pianiSalute.individuale.over64.D
      } else if (this.tipoPianoD==2) {
        this.premioPianoSaluteD = this.pianiSalute.familiare.over64.D
      }
      
    } else if(this.header.anni>74 || this.header.anni<18){
      this.premioPianoSaluteD = "Non disponibile";
      this.premioPianoSaluteC = "Non disponibile";
      this.premioPianoSaluteB = "Non disponibile";
      this.premioPianoSaluteA = "Non disponibile";
      this.selPianoA = false;
      this.selPianoB = false;
      this.selPianoC = false;
      this.selPianoD = false;
    }
    if (this.header.anni>17 && this.header.anni<75) {
      for (let i = 0; i < this.tcpArray.length; i++) {
        if (this.header.anni == this.tcpArray[i].anni) {
          this.premioTCP = (this.sommaTCP*this.tcpArray[i].valore)/1000;
        }
      }
    } else {
      this.selTCP = false;
      this.premioTCP = "Non disponibile";
    }
  }

  // Controllo sui requisiti per attivare/disattivare garanzie
  checkActive(){
    var isOneSelected = false;
    this.numeroSelezionati = 0;
    if (this.header.anni >75) {
      this.selMorte = false;
    }
    if (this.selMorte) {
      isOneSelected= true;
      this.activeMorte = parseFloat(this.premioMorte);
      this.activeTCP = null;
      this.selIPInfortunio= true;
    } else if (this.selTCP) {
      this.activeTCP = parseFloat(this.premioTCP);
      this.activeMorte = null;
    }
    else if (!this.selMorte && !this.selTCP){
      this.activeTCP = null;
      this.activeMorte = null;
      this.selIPInfortunio = false;
      this.selITInfortunio = false;
      this.selSpeseCura = false;
    }

    if (this.selTCP) {
      this.numeroSelezionati++;
      this.activeTCP = parseFloat(this.premioTCP);
    } else {
      this.activeTCP = null;
      this.selGraviMalattie = false;
      this.activeGraviMalattie = null;
    }

    if (this.selMorte) {
      isOneSelected= true;
      this.activeIPInfortunio = parseFloat(this.premioIPInfortunio);
    } else {
      this.activeIPInfortunio = null;
    }

    if (this.selIPInfortunio) {
      this.activeIPInfortunio = parseFloat(this.premioIPInfortunio);
    } else {
      this.selITInfortunio = false;
      this.selSpeseCura = false;
      this.selIPMalattia = false;
    }

    if (!this.selMorte && !this.selTCP) {
      this.selContoProtetto = false;
    }

    if (this.selITInfortunio) {
      isOneSelected= true;
      this.activeITInfortunio = parseFloat(this.premioITInfortunio);
    } else {
      this.activeITInfortunio = null;
    }

    if (this.selSpeseCura) {
      isOneSelected= true;
      this.activeSpeseCura = parseFloat(this.premioSpeseCura);
    } else {
      this.activeSpeseCura = null;
    }

    if (this.header.anni>70) {
      this.selDiariaRicovero = false;
    }
    if (this.selDiariaRicovero) {
      isOneSelected= true;
      this.activeDiariaRicovero = parseFloat(this.premioDiariaRicovero);
    } else {
      this.activeDiariaRicovero = null;
    }

    if (this.selContoProtetto) {
      isOneSelected= true;
      this.activeContoProtetto = parseFloat(this.premioContoProtetto);
    } else {
      this.activeContoProtetto = null;
    }

    if (isOneSelected) {
      this.numeroSelezionati++;
      isOneSelected=false;
    }
    
    if (this.selIPMalattia) {
      this.numeroSelezionati++;
      this.activeIPMalattia = parseFloat(this.premioIPMalattia);
    } else {
      this.activeIPMalattia = null;
    }

    if (this.selPianoA) {
      isOneSelected= true;
      this.activePianoA = parseFloat(this.premioPianoSaluteA);
    } else {
      this.activePianoA = null;
    }

    if (this.selPianoB) {
      isOneSelected= true;
      this.activePianoB = parseFloat(this.premioPianoSaluteB);
    } else {
      this.activePianoB = null;
    }

    if (this.selPianoC) {
      isOneSelected= true;
      this.activePianoC = parseFloat(this.premioPianoSaluteC);
    } else {
      this.activePianoC = null;
    }

    if (this.selPianoD) {
      isOneSelected= true;
      this.activePianoD =parseFloat(this.premioPianoSaluteD);
    } else {
      this.activePianoD = null;
    }

    if (isOneSelected) {
      this.numeroSelezionati++;
      isOneSelected=false;
    }

    if (this.selAssistenzaPersona) {
      this.activeAssistenzaPersona = parseFloat(this.premioAssistenzaPersona);
    } else {
      this.activeAssistenzaPersona = null;
    }

    if (this.selGraviMalattie) {
      this.numeroSelezionati++;
      this.activeGraviMalattie = parseFloat(this.premioGraviMalattie);
    } else {
      this.activeGraviMalattie = null;
    }

    this.selRCPrestatori = this.selRCTerzi;
    if (this.selRCTerzi) {
      isOneSelected= true;
      this.activeRCTerzi = parseFloat(this.premioRCTerzi);
      this.activeRCPrestatori = parseFloat(this.premioRCPrestatori);
    } else {
      this.activeRCTerzi = null;
      this.activeRCPrestatori = null;
    }

    if (this.selRCProprieta) {
      isOneSelected= true;
      this.activeRCProprieta = parseFloat(this.premioRCProprieta);
    } else {
      this.activeRCProprieta = null;
    }

    if (isOneSelected) {
      this.numeroSelezionati++;
      isOneSelected=false;
    }

    if (this.selTutelaLegale) {
      this.activeTutelaLegale = parseFloat(this.premioTutelaLegale);

    }

    if (this.selIncendioFabbricato) {
      isOneSelected = true;
      this.activeIncendioFabbricato = parseFloat(this.premioIncendioFabbricato);
    } else {
      this.activeIncendioFabbricato = null;
    }

    if (this.selIncendioContenuto) {
      isOneSelected = true;
      this.activeIncendioContenuto = this.premioIncendioContenuto;
    } else {
      this.activeIncendioContenuto = null;
      this.selFurtoContenuto = false;
      this.activeFurtoContenuto = null;
    }
    if (this.selRischioLocativo) {
      isOneSelected = true;
      this.activeRischioLocativo = this.premioRischioLocativo;
    } else {
      this.activeRischioLocativo = null;
    }

    if (isOneSelected) {
      this.numeroSelezionati++;
      isOneSelected=false;
    }

    if (isOneSelected) {
      this.numeroSelezionati++;
      isOneSelected=false;
    }

    if (this.selFurtoContenuto) {
      this.numeroSelezionati++;
      this.activeFurtoContenuto = this.premioFurtoContenuto;
    } else {
      this.activeFurtoContenuto = null;
    }

    if (this.selPos) {
      this.numeroSelezionati++;
      this.activePos = this.premioPos;
    } else {
      this.activePos = null;
    }

    if (this.selEnergieRinnovabili) {
      this.numeroSelezionati++;
      this.activeEnergieRinnovabili = parseFloat(this.premioEnergieRinnovabili);
    } else {
      this.activeEnergieRinnovabili = null;
    }

    if (this.selElettronica) {
      this.numeroSelezionati++;
      this.activeElettronica = parseFloat(this.premioElettronica);
    } else {
      this.activeElettronica = null;
    }

    if (this.header.collegatoMutuo || this.header.daAltraCompagnia) {
      this.numeroSelezionati++;
    }

    if (this.numeroSelezionati<2) {
      this.sconto = 0;
    } else if (this.numeroSelezionati==2) {
      this.sconto = 20;
    } else if (this.numeroSelezionati==3) {
      this.sconto = 25;
    } else if (this.numeroSelezionati>3) {
      this.sconto = 30;
    }

    // this.errorListArray = [
    //   {if: this.header.anni>75 ,ref:"TEMPORANEA CASO MORTE",des:"Età massima di 74 anni"},
    //   {if: !this.selTCP,ref:"INDENNITARIA GRAVI MALATTIE",des:"Necessaria 'temporanea caso morte ed età massima di 74 anni'"},
    //   {if: this.header.anni>75 ,ref:"MORTE DA INFORTUNIO",des:"Età massima di 75 anni'"},
    //   {if: (this.header.anni>75) || (!this.selMorte && !this.selTCP),ref:"INVALIDITÀ PERMANENTE DA INFORTUNIO",des:"Necessaria almeno una tra 'morte da infortunio' e temporanea caso morte'"},
    //   {if: !this.selIPInfortunio ,ref:"INABILITÀ TEMPORANEA DA INFORTUNIO",des:"Necessaria 'invalidità permanente da infortunio'"},
    //   {if: !this.selIPInfortunio ,ref:"RIMBORSO SPESE CURE DA INFORTUNIO",des:"Necessaria 'invalidità permanente da infortunio'"},
    //   {if: this.header.anni>70 ,ref:"DIARIA DA RICOVERO",des:"Età massima di 70 anni"},
    //   {if: this.header.anni>65 || this.selIPInfortunio ,ref:"INVALIDITÀ PERMANENTE DA MALATTIA",des:"Età massima di 65 anni e necessaria 'invalidità permanente da infortunio'"},
    //   {if: this.header.anni<18 || this.header.anni>74 ,ref:"PIANI A / B / C",des:"Età tra i 18 e i 74 anni, solo uno dei tre (A, B e C) selezionabile"},
    //   {if: this.header.anni<18 || this.header.anni>74 ,ref:"PIANO D",des:"Età tra i 18 e i 74 anni"},
    //   {if: this.header.tipoLavoratore != 2 ,ref:"PERDITA D'IMPIEGO",des:"Solo per lavoratori non autonomi"},
    //   {if: !this.selRCProprieta && !this.selRCTerzi ,ref:"TUTELA LEGALE VITA PRIVATA E IMMOBILE",des:"Necessaria almeno una tra 'RC della vita privata' e 'RC della proprietà'"},
    //   {if: false ,ref:"INCENDIO FABBRICATO / RISCHIO LOCATIVO",des:"Solo uno tra incendio fabbricato e rischio locativo selezionabile"},
    //   {if: !this.selIncendioContenuto ,ref:"FURTO DEL CONTENUTO",des:"Necessaria 'incendio contenuto'"},
    //   {if: !this.selIncendioContenuto ,ref:"ENERGIE RINNOVABILI ALL RISK",des:"Necessaria 'incendio contenuto'"}
    // ]
  }

}
