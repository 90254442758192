import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { MaterialModule } from './material.module';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ProtectionComponent } from './components/protection/protection.component';
import { ProtectionBusinessComponent } from './components/protection-business/protection-business.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { LtcComponent } from './components/ltc/ltc.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ProtectionComponent,
    ProtectionBusinessComponent,
    WelcomeComponent,
    LtcComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MaterialModule,
    CommonModule,
    HttpClientModule
  ],
  providers: [CurrencyPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
