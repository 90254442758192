<div class="container" style="font-size: 10px;">
  <div class="row mt-4">
    <div class="col-10">
      <div class="row">
        <div class="col-4 header-row font-weight-bold header-title">
          Età assicurato
        </div>
        <div class="col-3 header-row">
          <div class="input-group input-group-sm ml-1">
            <input type="number" class="form-control" min="0" max="100" [(ngModel)]="header.anni" (change)="ageControl()"><span class="text-danger" *ngIf="errorAge"><b>Inserire età compresa tra 0 e 100</b> </span>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Tipo lavoratore
        </div>
        <div class="col-8 header-row">
          <button *ngIf="header.tipoLavoratore!=1" class="ml-1 btn btn-sm btn-secondary" (click)="setTipoLavoratore(1)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-handbag-fill" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 0-2 2v2H5V3a3 3 0 1 1 6 0v2h-1V3a2 2 0 0 0-2-2zM5 5H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11v1.5a.5.5 0 0 1-1 0V5H6v1.5a.5.5 0 0 1-1 0V5z"/>
            </svg> Autonomo
          </button>
          <button *ngIf="header.tipoLavoratore==1" class="ml-1 btn btn-sm btn-red-custom" (click)="setTipoLavoratore(1)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-handbag-fill" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 0-2 2v2H5V3a3 3 0 1 1 6 0v2h-1V3a2 2 0 0 0-2-2zM5 5H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11v1.5a.5.5 0 0 1-1 0V5H6v1.5a.5.5 0 0 1-1 0V5z"/>
            </svg> Autonomo
          </button>
          <button *ngIf="header.tipoLavoratore!=2" class="ml-1 btn btn-sm btn-secondary" (click)="setTipoLavoratore(2)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card-2-back" viewBox="0 0 16 16">
              <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z"/>
              <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z"/>
            </svg> Non autonomo
          </button>
          <button *ngIf="header.tipoLavoratore==2" class="ml-1 btn btn-sm btn-red-custom" (click)="setTipoLavoratore(2)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card-2-back" viewBox="0 0 16 16">
              <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z"/>
              <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z"/>
            </svg> Non autonomo
          </button>
          <button *ngIf="header.tipoLavoratore!=3" class="ml-1 btn btn-sm btn-secondary" (click)="setTipoLavoratore(3)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg> Non lavoratore
          </button>
          <button *ngIf="header.tipoLavoratore==3" class="ml-1 btn btn-sm btn-red-custom" (click)="setTipoLavoratore(3)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg> Non lavoratore
          </button>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Professione
        </div>
        <div class="col-2 header-row">
          <div class="input-group input-group-sm ml-1">
            <input type="text" class="form-control" [(ngModel)]="professioneFilterString" (change)="filterProfessione()">
            <div class="input-group-append">
              <span class="input-group-text" style="background-color: white; font-size: x-small;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filter" viewBox="0 0 16 16">
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div class="col-6 header-row">
          <div class="input-group input-group-sm ml-1">
            <select style="width: 275px" class="form-control" [(ngModel)]="prof" (change)="selectProfessione()">
              <option *ngFor="let op of professioneFiltered; let i = index" value="{{i}}">{{op.titolo}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Numero dipendenti
        </div>
        <div class="col-3 header-row">
          <div class="input-group input-group-sm ml-1">
            <select style="width: 275px" class="form-control" [(ngModel)]="header.numeroDipendenti" (change)="calcolaPremi()">
              <option value="Da 0 a 5">Da 0 a 5</option>
              <option value="Da 6 a 10">Da 6 a 10</option>
              <option value="Da 11 a 20">Da 11 a 20</option>
              <option value="Da 21 a 30">Da 21 a 30</option>
            </select>
          </div>
        </div>
      </div>
      
      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Posti letto
        </div>
        <div class="col-3 header-row">
          <div class="input-group input-group-sm ml-1">
            <select style="width: 275px" class="form-control" [(ngModel)]="header.postiLetto" (change)="calcolaPremi()">
              <option value="0">0</option>
              <option value="Da 1 a 10">Da 1 a 10</option>
              <option value="Da 11 a 20">Da 11 a 20</option>
              <option value="Da 21 a 30">Da 21 a 30</option>
              <option value="Da 31 a 40">Da 31 a 40</option>
              <option value="Da 41 a 50">Da 41 a 50</option>
              <option value="Da 51 a 60">Da 51 a 60</option>
              <option value="Da 61 a 70">Da 61 a 70</option>
              <option value="Da 71 a 80">Da 71 a 80</option>
              <option value="Da 81 a 90">Da 81 a 90</option>
              <option value="Da 91 a 100">Da 91 a 100</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Settore attività
        </div>
        <div class="col-8 header-row">
          <button *ngIf="header.settore!='Agricoltura'" class="ml-1 btn btn-sm btn-secondary" (click)="setSettoreAttivita('Agricoltura')">
            Agricoltura
          </button>
          <button *ngIf="header.settore=='Agricoltura'" class="ml-1 btn btn-sm btn-red-custom" (click)="setSettoreAttivita('Agricoltura')">
            Agricoltura
          </button>
          <button *ngIf="header.settore!='Industria e artigianato'" class="ml-min btn btn-sm btn-secondary" (click)="setSettoreAttivita('Industria e artigianato')">
            Industria/artigianato
          </button>
          <button *ngIf="header.settore=='Industria e artigianato'" class="ml-min btn btn-sm btn-red-custom" (click)="setSettoreAttivita('Industria e artigianato')">
            Industria/artigianato
          </button>
          <button *ngIf="header.settore!='Commercio'" class="ml-min btn btn-sm btn-secondary" (click)="setSettoreAttivita('Commercio')">
            Commercio
          </button>
          <button *ngIf="header.settore=='Commercio'" class="ml-min btn btn-sm btn-red-custom" (click)="setSettoreAttivita('Commercio')">
            Commercio
          </button>
          <button *ngIf="header.settore!='Liberi professionisti'" class="ml-min btn btn-sm btn-secondary" (click)="setSettoreAttivita('Liberi professionisti')">
            Liberi professionisti
          </button>
          <button *ngIf="header.settore=='Liberi professionisti'" class="ml-min btn btn-sm btn-red-custom" (click)="setSettoreAttivita('Liberi professionisti')">
            Liberi professionisti
          </button>
          <button *ngIf="header.settore!='Servizi'" class="ml-min btn btn-sm btn-secondary" (click)="setSettoreAttivita('Servizi')">
            Servizi
          </button>
          <button *ngIf="header.settore=='Servizi'" class="ml-min btn btn-sm btn-red-custom" (click)="setSettoreAttivita('Servizi')">
            Servizi
          </button>
          <button *ngIf="header.settore!='Turismo e ristorazione'" class="ml-min btn btn-sm btn-secondary" (click)="setSettoreAttivita('Turismo e ristorazione')">
            Turismo
          </button>
          <button *ngIf="header.settore=='Turismo e ristorazione'" class="ml-min btn btn-sm btn-red-custom" (click)="setSettoreAttivita('Turismo e ristorazione')">
            Turismo
          </button>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Denominazione attività
        </div>
        <div class="col-2 header-row">
          <div class="input-group input-group-sm ml-1">
            <input type="text" class="form-control" [(ngModel)]="classeFilterString" (change)="filterClasse()">
            <div class="input-group-append">
              <span class="input-group-text" style="background-color: white; font-size: x-small;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filter" viewBox="0 0 16 16">
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div class="col-6 header-row">
          <div class="input-group input-group-sm ml-1">
            <select style="width: 275px" class="form-control" [(ngModel)]="header.denominazione" (change)="setClasse()">
              <option *ngFor="let op of filteredClassiRischio" value="{{op.id}}">{{op.denominazioneAttivita}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row text-danger" *ngIf="classeAttiva.classeRCT=='R.E.' || classeAttiva.classeRCT=='R.D.' || classeAttiva.classeRCO=='R.E.' || classeAttiva.classeRCO=='R.D.' || classeAttiva.classeFurto=='R.E.' || classeAttiva.classeFurto=='R.D.'">
        <div class="col-4"></div>
        <div class="col-8">
          <span>Attualmente non disponibili per questa attività:</span>
          <div *ngIf="classeAttiva.classeRCT=='R.E.' || classeAttiva.classeRCT=='R.D.'">- R.C. VERSO TERZI </div>
          <div *ngIf="classeAttiva.classeRCO=='R.E.' || classeAttiva.classeRCO=='R.D.'">- R.C. VERSO PRESTATORI DI LAVORO</div>
          <div *ngIf="classeAttiva.classeFurto=='R.E.' || classeAttiva.classeFurto=='R.D.'">- FURTO CONTENUTO</div>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Rischio proveniente da altra compagnia
        </div>
        <div class="col-3 header-row">
          <button *ngIf="!header.daAltraCompagnia" class="ml-1 btn btn-sm btn-secondary" (click)="daAltraCompagnia(1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </button>
          <button *ngIf="header.daAltraCompagnia" class="ml-1 btn btn-sm btn-success">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </button>
          <button *ngIf="header.daAltraCompagnia" class="ml-1 btn btn-sm btn-secondary" (click)="daAltraCompagnia(2)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
          </button>
          <button *ngIf="!header.daAltraCompagnia" class="ml-1 btn btn-sm btn-red-custom">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
          </button>
        </div>
      </div>
      
      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Rischio collegato a mutuo
        </div>
        <div class="col-3 header-row">
          <button *ngIf="!header.collegatoMutuo" class="ml-1 btn btn-sm btn-secondary" (click)="collegatoMutuo(1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </button>
          <button *ngIf="header.collegatoMutuo" class="ml-1 btn btn-sm btn-success">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </button>
          <button *ngIf="header.collegatoMutuo" class="ml-1 btn btn-sm btn-secondary" (click)="collegatoMutuo(2)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
          </button>
          <button *ngIf="!header.collegatoMutuo" class="ml-1 btn btn-sm btn-red-custom">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="col-2 text-right">
      <button type="button" class="btn btn-red-custom" data-toggle="modal" data-target="#exampleModal">
        Carrello
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-fill" viewBox="0 0 16 16">
          <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
        </svg>
      </button>
      <br>
      <button class="btn btn-red-custom mt-2" (click)="removeAll()">Svuota</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-right font-weight-bold" style="font-size: 13px;">
          Sconto: {{sconto}} %
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <div class="">
      <table class="table table-borderless" style="border-right: solid grey 1px; border-left: solid grey 1px">
          <thead class="bg-red-custom text-light">
            <tr class="tab-head font-weight-bold">
            <td class="tab-head bg-red-custom" scope="col" style="width: 11%; vertical-align: middle;">Bisogno</td>
            <td class="tab-head bg-red-custom" scope="col" style="width: 19%; vertical-align: middle;">Sezione</td>
            <td class="tab-head bg-red-custom" scope="col" style="width: 22%; vertical-align: middle;">Garanzia</td>
            <td class="tab-head bg-red-custom" scope="col" style="width: 8%; vertical-align: middle;">Somma assicurata/Forma</td>
            <td class="tab-head bg-red-custom pl-4" scope="col" style="width: 10%; vertical-align: middle;">Premio</td>
            <td class="tab-head bg-red-custom text-center" scope="col" style="width: 6%; vertical-align: middle;">Selezione</td>
            <td class="tab-head bg-red-custom pl-4" scope="col" style="width: 8%; vertical-align: middle;">Premio selezione</td>
            <td class="tab-head bg-red-custom pl-4" scope="col" style="width: 8%; vertical-align: middle;">Premio scontato</td>
          </tr>
        </thead>
        <tbody>

          <tr>
            <th rowspan="2" style="border-right: 1px solid grey !important;">FAMIGLIA E AFFETTI</th>
            <th  style="border-right: 1px solid grey !important;">TEMPORANEA CASO MORTE</th>
            <th>TEMPORANEA CASO MORTE <span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età massima di 75 anni">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <span *ngIf="!sommaTCPIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempTCP" min="0" max="500000" (blur)="lockTCP()" (keyup.enter)="lockTCP()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaTCPIsBlocked" (click)="unlockTCP()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaTCP | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
            </th>
            <th class="text-right pr-3">
              <span style="font-size: 10px;" *ngIf="premioTCP=='Non disponibile'">{{premioTCP}}</span>
              <span *ngIf="premioTCP!='Non disponibile'">{{premioTCP | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="premioTCP=='Non disponibile'" disabled type="checkbox" (change)="calcoliMorte(2)" [(ngModel)]="selTCP">
              <input *ngIf="premioTCP!='Non disponibile'"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-2-outlined" checked autocomplete="off" [(ngModel)]="selTCP" (change)="calcoliMorte(2)">
              <label *ngIf="premioTCP!='Non disponibile' && !selTCP" class=" btn-outline-danger" for="btn-check-2-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="premioTCP!='Non disponibile' && selTCP" class=" btn-danger" style="border-radius: 3px;" for="btn-check-2-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeTCP!=null && premioTCP!='Non disponibile'">{{premioTCP | number:'1.2-2':'es'}} €</span>
              <span *ngIf="activeTCP!=null && premioTCP=='Non disponibile'">{{premioTCP}}</span>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeTCP!=null">{{scontatoTCP | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th class="bordo-su" style=" border-right: 1px solid grey;">INDENNITARIA GRAVI MALATTIE</th>
            <th class="bordo-su">INDENNITARIA GRAVI MALATTIE<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria 'temporanea caso morte ed età massima di 65 anni">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" style="width: 60%; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="10.000" disabled (change)="calcolaPremi()">
                <div class="input-group-append" style="height: 25px;">
                  <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;&nbsp;</span>
                </div>
              </div>
            </th>
            <th class="bordo-su text-right  pr-3">
              <span *ngIf="premioTCP!='Non disponibile' && premioGraviMalattie!='Non disponibile' && header.anni<65 && header.anni>17">{{premioGraviMalattie | number:'1.2-2':'es'}} €</span>
              <span *ngIf="premioTCP=='Non disponibile' || header.anni>64 || header.anni<18">Non disponibile</span>
            </th>
            <th class="text-center bordo-su check-th-ptop">
              <input *ngIf="!selTCP || header.anni>64 || header.anni<18" disabled type="checkbox" (change)="calcolaPremi()" [(ngModel)]="selGraviMalattie">
              <input *ngIf="selTCP  && header.anni<65 && header.anni>17"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-3-outlined" checked autocomplete="off" [(ngModel)]="selGraviMalattie" (change)="calcolaPremi()">
              <label *ngIf="selTCP && !selGraviMalattie  && header.anni<65 && header.anni>17" class=" btn-outline-danger" for="btn-check-3-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="selTCP && selGraviMalattie" class=" btn-danger" style="border-radius: 3px;" for="btn-check-3-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activeGraviMalattie!=null && premioGraviMalattie!='Non disponibile'">{{premioGraviMalattie | number:'1.2-2':'es'}} €</span>
              <span *ngIf="activeGraviMalattie!=null && premioGraviMalattie=='Non disponibile'">{{premioGraviMalattie}}</span>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activeGraviMalattie!=null">{{scontatoGraviMalattie | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          
          <tr style="border-top: 2px grey solid !important;">
            <th style="border-right: 1px solid grey; vertical-align: middle;" rowspan="14">BENESSERE E SALUTE</th>
            <th rowspan="6" style="vertical-align: middle; border-right: 1px solid grey;">INFORTUNI</th>
            <th>MORTE DA INFORTUNIO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età massima di 75 anni">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <span *ngIf="!sommaMorteIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempMorte" min="0" max="500000" (blur)="lockMorte()" (keyup.enter)="lockMorte()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaMorteIsBlocked" (click)="unlockMorte()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaMorte | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
            </th>
            <th class="text-right pr-3">
              <span *ngIf="header.anni<76">{{premioMorte | number:'1.2-2':'es'}}  €</span>
              <span style="font-size: 10px;" *ngIf="header.anni>75">Non disponibile</span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="header.anni>75" style="padding-top: 0.9em;" class="myinput" type="checkbox" disabled [(ngModel)]="selMorte" >

              <input *ngIf="header.anni<76"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-4-outlined" checked autocomplete="off" [(ngModel)]="selMorte" (change)="calcoliMorte(1)">
              <label *ngIf="header.anni<76 && !selMorte" class=" btn-outline-danger" for="btn-check-4-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="header.anni<76 && selMorte" class=" btn-danger" style="border-radius: 3px;" for="btn-check-4-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class=" text-right pr-4"><span *ngIf="activeMorte!=null">{{premioMorte | number:'1.2-2':'es'}} €</span></th>
            <th class=" text-right pr-4"><span *ngIf="activeMorte!=null">{{scontatoMorte | number:'1.2-2':'es'}} €</span></th>
          </tr>

          <tr>
            <th class="text-uppercase">INVALIDITà PERMANENTE DA INFORTUNIO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria almeno una tra 'morte da infortunio' e 'temporanea caso morte'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <span *ngIf="!sommaIPInfortunioIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempIPInfortunio" min="0" max="500000" (blur)="lockIPInfortunio()" (keyup.enter)="lockIPInfortunio()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaIPInfortunioIsBlocked" (click)="unlockIPInfortunio()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaIPInfortunio | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
           </th>
           <th class="text-right pr-3">
            <span *ngIf="header.anni<76">{{premioIPInfortunio | number:'1.2-2':'es'}}  €</span>
            <span style="font-size: 10px;" *ngIf="header.anni>75">Non disponibile</span>
           </th>


            <th class="text-center check-th-ptop">
              <input *ngIf="!selMorte && !selTCP" type="checkbox" disabled (change)="calcolaPremi()" [(ngModel)]="selIPInfortunio">
              <input *ngIf="(selMorte || selTCP)"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-5-outlined" checked autocomplete="off" [(ngModel)]="selIPInfortunio" (change)="calcoliMorte(3)">
              <label *ngIf="(selMorte || selTCP) && !selIPInfortunio" class=" btn-outline-danger" for="btn-check-5-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="(selMorte || selTCP) && selIPInfortunio" class=" btn-danger" style="border-radius: 3px;" for="btn-check-5-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            
            <th class=" text-right pr-4"><span *ngIf="activeIPInfortunio!=null">{{premioIPInfortunio | number:'1.2-2':'es'}} €</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activeIPInfortunio!=null">{{scontatoIPInfortunio | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th class="text-uppercase">INABILITà TEMPORANEA DA INFORTUNIO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria 'invalidità permanente da infortunio'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaITInfortunio" (change)="calcolaPremi()">
                  <option style="text-align: right;" value="1"> €&nbsp;&nbsp;&nbsp;&nbsp;50,00</option>
                  <option style="text-align: right;" value="2"> €&nbsp;&nbsp;&nbsp;&nbsp;75,00</option>
                  <option style="text-align: right;" value="3"> €&nbsp;&nbsp;&nbsp;&nbsp;100,00</option>
                  <option style="text-align: right;" value="4"> €&nbsp;&nbsp;&nbsp;&nbsp;150,00€</option>
              </select>
              </div>
            </th>
            <th class="text-right pr-3">
              <span *ngIf="header.anni<76">{{premioITInfortunio | number:'1.2-2':'es'}}  €</span>
              <span style="font-size: 10px;" *ngIf="header.anni>75">Non disponibile</span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="!selIPInfortunio" type="checkbox" disabled (change)="calcolaPremi()" [(ngModel)]="selITInfortunio">
              <input *ngIf="selIPInfortunio"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-6-outlined" checked autocomplete="off" [(ngModel)]="selITInfortunio" (change)="calcolaPremi()">
              <label *ngIf="selIPInfortunio && !selITInfortunio" class=" btn-outline-danger" for="btn-check-6-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="selIPInfortunio && selITInfortunio" class=" btn-danger" style="border-radius: 3px;" for="btn-check-6-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class=" text-right pr-4"><span *ngIf="activeITInfortunio!=null">{{premioITInfortunio | number:'1.2-2':'es'}} €</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activeITInfortunio!=null">{{scontatoITInfortunio | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th>RIMBORSO SPESE CURE DA INFORTUNIO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria 'invalidità permanente da infortunio'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right" class="form-control" [(ngModel)]="sommaSpeseCura" (change)="calcolaPremi()">
                  <option style="text-align: right" value="1">€ &nbsp;&nbsp;&nbsp;&nbsp;5.000,00</option>
                  <option style="text-align: right" value="2">€ &nbsp;&nbsp;&nbsp;&nbsp;10.000,00</option>
                  <option style="text-align: right" value="3">€ &nbsp;&nbsp;&nbsp;&nbsp;15.000,00</option>
                  <option style="text-align: right" value="4">€ &nbsp;&nbsp;&nbsp;&nbsp;20.000,00</option>
                  <option style="text-align: right" value="5">€ &nbsp;&nbsp;&nbsp;&nbsp;25.000,00</option>
                </select>
              </div>
            </th>
            <th class="text-right pr-3">
             <span *ngIf="header.anni<76">{{premioSpeseCura | number:'1.2-2':'es'}}  €</span>
             <span   style="font-size: 10px;" *ngIf="header.anni>75">Non disponibile</span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="!selIPInfortunio" type="checkbox" disabled (change)="calcolaPremi()" [(ngModel)]="selSpeseCura">
              <input *ngIf="selIPInfortunio"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-7-outlined" checked autocomplete="off" [(ngModel)]="selSpeseCura" (change)="calcolaPremi()">
              <label *ngIf="selIPInfortunio && !selSpeseCura" class=" btn-outline-danger" for="btn-check-7-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="selIPInfortunio && selSpeseCura" class=" btn-danger" style="border-radius: 3px;" for="btn-check-7-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class=" text-right pr-4"><span *ngIf="activeSpeseCura!=null">{{premioSpeseCura | number:'1.2-2':'es'}} €</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activeSpeseCura!=null">{{scontatoSpeseCura | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th>DIARIA DA RICOVERO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età massima di 70 anni">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
                <div class="input-group input-group-sm">
                  <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right" class="form-control" [(ngModel)]="sommaDiariaRicovero" (change)="calcolaPremi()">
                    <option style="text-align: right;" value="1">€ &nbsp;&nbsp;&nbsp;&nbsp; 50,00</option>
                    <option style="text-align: right;" value="2">€ &nbsp;&nbsp;&nbsp;&nbsp; 75,00</option>
                    <option style="text-align: right;" value="3">€ &nbsp;&nbsp;&nbsp;&nbsp; 100,00</option>
                  </select>
                </div>
            </th>
            <th class="text-right pr-3">
              <span   style="font-size: 10px;" *ngIf="header.anni>70 && premioDiariaRicovero=='Non disponibile'">Non disponibile</span>
              <span *ngIf="header.anni<71 && premioDiariaRicovero!='Non disponibile'">{{premioDiariaRicovero | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input type="checkbox" *ngIf="header.anni>70" disabled [(ngModel)]="selDiariaRicovero">

              <input *ngIf="header.anni<71"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-8-outlined" checked autocomplete="off" [(ngModel)]="selDiariaRicovero" (change)="calcolaPremi()">
              <label *ngIf="header.anni<71 && !selDiariaRicovero" class=" btn-outline-danger" for="btn-check-8-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="header.anni<71 && selDiariaRicovero" class=" btn-danger" style="border-radius: 3px;" for="btn-check-8-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class="text-right pr-4"><span *ngIf="activeDiariaRicovero!=null">{{premioDiariaRicovero | number:'1.2-2':'es'}} €</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activeDiariaRicovero!=null">{{scontatoDiariaRicovero | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th>CONTO PROTETTO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età massima di 75 anni">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" style="width: 60%; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="50.000,00" disabled (change)="calcolaPremi()">
                <div class="input-group-append" style="height: 25px;">
                  <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                </div>
              </div>
            </th>
            <th class="text-right pr-3">{{premioContoProtetto}} <span *ngIf="header.anni<75">€</span> </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="!selMorte && !selTCP" type="checkbox" disabled (change)="calcolaPremi()" [(ngModel)]="selContoProtetto">
              <input *ngIf="(selMorte || selTCP)"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-40-outlined" checked autocomplete="off" [(ngModel)]="selContoProtetto" (change)="calcolaPremi()">
              <label *ngIf="(selMorte || selTCP) && !selContoProtetto" class=" btn-outline-danger" for="btn-check-40-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="(selMorte || selTCP) && selContoProtetto" class=" btn-danger" style="border-radius: 3px;" for="btn-check-40-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class="text-right pr-4"><span *ngIf="selContoProtetto">{{premioContoProtetto}} €</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="selContoProtetto">{{ scontatoContoProtetto | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr class="bordo-su">
            <th class="text-uppercase" style="vertical-align: middle; border-right: 1px solid grey;">Invalidità permanente da malattia</th>
            <th class="text-uppercase">Invalidità permanente da malattia<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età massima di 65 anni e necessaria 'invalidità permanente da infortunio'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
                <div class="input-group input-group-sm">
                  <span *ngIf="!sommaIPMalattiaIsBlocked" >
                    <div class="input-group input-group-sm">
                      <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" disabled [(ngModel)]="sommaIPMalattia" min="0" max="250000" (blur)="lockIPMalattia()" (keyup.enter)="lockIPMalattia()">
                  <div class="input-group-append" style="height: 25px;">
                      <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                      </div>
                    </div>
                  </span>
                  <span *ngIf="sommaIPMalattiaIsBlocked">
                    <div class="input-group input-group-sm">
                      <input type="text" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" disabled value="{{sommaIPMalattia | number:'1.2-2':'es'}}">
                      <div class="input-group-append" style="height: 25px;">
                        <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                      </div>
                    </div>
                  </span>
                </div>
            </th>
            <th class="text-right pr-3">
              <span   style="font-size: 10px;" *ngIf="(header.anni>65 || !selIPInfortunio) && premioIPMalattia=='Non disponibile'">{{premioIPMalattia}}</span>
              <span   style="font-size: 10px;" *ngIf="(header.anni>65 || !selIPInfortunio) && premioIPMalattia!='Non disponibile'">{{premioIPMalattia | number:'1.2-2':'es'}} €</span>
              <span *ngIf="header.anni<66 && selIPInfortunio">{{premioIPMalattia | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="header.anni>65 || !selIPInfortunio" type="checkbox" disabled >
              <input *ngIf="header.anni<66 && selIPInfortunio"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-9-outlined" checked autocomplete="off" [(ngModel)]="selIPMalattia" (change)="calcolaPremi()">
              <label *ngIf="(header.anni<66 && selIPInfortunio) && !selIPMalattia" class=" btn-outline-danger" for="btn-check-9-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="(header.anni<66 && selIPInfortunio) && selIPMalattia" class=" btn-danger" style="border-radius: 3px;" for="btn-check-9-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class="text-right pr-4"><span *ngIf="activeIPMalattia!=null ">{{activeIPMalattia | number:'1.2-2':'es'}} €</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activeIPMalattia!=null">{{scontatoIPMalattia | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th class="bordo-su" rowspan="4" style="vertical-align: middle; border-right: 1px solid grey;">SALUTE</th>
            <th class="bordo-su">Piano A<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età tra i 18 e i 74 anni, solo uno dei tre (A, B e C) selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="tipoPianoA" (change)="selezioneTipoPianoSalute(tipoPianoA)">
                  <option style="text-align: right;" value="1">Individuale</option>
                  <option style="text-align: right;" value="2">Familiare</option>
              </select>
              </div>
            </th>
            <th class="bordo-su text-right pr-3">
              <span style="font-size: 10px;" *ngIf="premioPianoSaluteA=='Non disponibile'">{{premioPianoSaluteA}}</span>
              <span *ngIf="premioPianoSaluteA!='Non disponibile'">{{premioPianoSaluteA | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center bordo-su check-th-ptop">
              <input  *ngIf="premioPianoSaluteA=='Non disponibile'" disabled type="checkbox" (change)="checkPianoSalute(1, $event)" [(ngModel)]="selPianoA">
              <input *ngIf="premioPianoSaluteA!='Non disponibile'"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-10-outlined" checked autocomplete="off" [(ngModel)]="selPianoA" (change)="checkPianoSalute(1, $event)">
                <label *ngIf="premioPianoSaluteA!='Non disponibile' && !selPianoA" class=" btn-outline-danger" for="btn-check-10-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="premioPianoSaluteA!='Non disponibile' && selPianoA" class=" btn-danger" style="border-radius: 3px;" for="btn-check-10-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activePianoA!=null && premioPianoSaluteA!='Non disponibile'">{{premioPianoSaluteA | number:'1.2-2':'es'}} €</span>
              <span *ngIf="activePianoA!=null && premioPianoSaluteA=='Non disponibile'">{{premioPianoSaluteA}}</span></th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activePianoA!=null">{{scontatoPianoA | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th>Piano B<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età tra i 18 e i 74 anni, solo uno dei tre (A, B e C) selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="tipoPianoB" (change)="selezioneTipoPianoSalute(tipoPianoB)">
                  <option style="text-align: right;" value="1">Individuale</option>
                  <option style="text-align: right;" value="2">Familiare</option>
              </select>
              </div>
            </th>
            <th class="text-right pr-3">
              <span   style="font-size: 10px;" *ngIf="premioPianoSaluteB=='Non disponibile'">{{premioPianoSaluteB}}</span>
              <span *ngIf="premioPianoSaluteB!='Non disponibile'">{{premioPianoSaluteB | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input  *ngIf="premioPianoSaluteB=='Non disponibile'" disabled type="checkbox" (change)="checkPianoSalute(2, $event)" [(ngModel)]="selPianoB">
              <input *ngIf="premioPianoSaluteB!='Non disponibile'"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-11-outlined" checked autocomplete="off" [(ngModel)]="selPianoB" (change)="checkPianoSalute(2, $event)">
                <label *ngIf="premioPianoSaluteB!='Non disponibile' && !selPianoB" class=" btn-outline-danger" for="btn-check-11-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="premioPianoSaluteB!='Non disponibile' && selPianoB" class=" btn-danger" style="border-radius: 3px;" for="btn-check-11-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right pr-4">
              <span *ngIf="activePianoB!=null && premioPianoSaluteB!='Non disponibile'">{{premioPianoSaluteB | number:'1.2-2':'es'}} €</span>
              <span *ngIf="activePianoB!=null && premioPianoSaluteB=='Non disponibile'">{{premioPianoSaluteB}}</span>
            </th>
            <th class=" text-right pr-4">
              <span *ngIf="activePianoB!=null">{{scontatoPianoB | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th>Piano C<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età tra i 18 e i 74 anni, solo uno dei tre (A, B e C) selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="tipoPianoC" (change)="selezioneTipoPianoSalute(tipoPianoC)">
                  <option style="text-align: right;" value="1">Individuale</option>
                  <option style="text-align: right;" value="2">Familiare</option>
              </select>
              </div>
            </th>
            <th class="text-right pr-3">
              <span style="font-size: 10px;" *ngIf="premioPianoSaluteC=='Non disponibile'">{{premioPianoSaluteC}}</span>
              <span *ngIf="premioPianoSaluteC!='Non disponibile'">{{premioPianoSaluteC | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input  *ngIf="premioPianoSaluteC=='Non disponibile'" disabled type="checkbox" (change)="checkPianoSalute(3, $event)" [(ngModel)]="selPianoC">
              <input *ngIf="premioPianoSaluteC!='Non disponibile'"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-12-outlined" checked autocomplete="off" [(ngModel)]="selPianoC" (change)="checkPianoSalute(3, $event)">
                <label *ngIf="premioPianoSaluteC!='Non disponibile' && !selPianoC" class=" btn-outline-danger" for="btn-check-12-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="premioPianoSaluteC!='Non disponibile' && selPianoC" class=" btn-danger" style="border-radius: 3px;" for="btn-check-12-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right pr-4">
              <span *ngIf="activePianoC!=null && premioPianoSaluteC!='Non disponibile'">{{premioPianoSaluteC | number:'1.2-2':'es'}} €</span>
              <span *ngIf="activePianoC!=null && premioPianoSaluteC=='Non disponibile'">{{premioPianoSaluteC}}</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activePianoC!=null">{{scontatoPianoC | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th>Piano D<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età tra i 18 e i 74 anni">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="tipoPianoD" (change)="selezioneTipoPianoSalute(tipoPianoD)">
                  <option style="text-align: right;" value="1">Individuale</option>
                  <option style="text-align: right;" value="2">Familiare</option>
              </select>
              </div>
            </th>
            <th class="text-right pr-3">
              <span   style="font-size: 10px;" *ngIf="premioPianoSaluteD=='Non disponibile'">{{premioPianoSaluteD}}</span>
              <span *ngIf="premioPianoSaluteD!='Non disponibile'">{{premioPianoSaluteD | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input  *ngIf="premioPianoSaluteD=='Non disponibile'" disabled type="checkbox" (change)="calcolaPremi()" [(ngModel)]="selPianoD">
              <input *ngIf="premioPianoSaluteD!='Non disponibile'"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-13-outlined" checked autocomplete="off" [(ngModel)]="selPianoD" (change)="checkPianoSalute(4, $event)">
                <label *ngIf="premioPianoSaluteD!='Non disponibile' && !selPianoD" class=" btn-outline-danger" for="btn-check-13-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="premioPianoSaluteD!='Non disponibile' && selPianoD" class=" btn-danger" style="border-radius: 3px;" for="btn-check-13-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right pr-4">
              <span *ngIf="activePianoD!=null && premioPianoSaluteD!='Non disponibile'">{{premioPianoSaluteD | number:'1.2-2':'es'}} €</span>
              <span *ngIf="activePianoD!=null && premioPianoSaluteD=='Non disponibile'">{{premioPianoSaluteD}}</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activePianoD!=null">{{scontatoPianoD | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          
          
          <tr>
            <th class="bordo-su" rowspan="3" style=" border-right: 1px solid grey;">ASSISTENZA</th>
            <th class="bordo-su">ASSISTENZA PERSONA <span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Calcolato automaticamente">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <!-- VUOTO -->
            </th>
            <th class="bordo-su text-right  pr-3">
              {{premioAssistenzaPersona | number:'1.2-2':'es'}} €
            </th>
            <th class="text-center bordo-su check-th-ptop">
              <input type="checkbox" disabled [(ngModel)]="selAssistenzaPersona">
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activeAssistenzaPersona!=null">{{premioAssistenzaPersona | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activeAssistenzaPersona!=null">{{scontatoAssistenzaPersona | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th class="">ASSISTENZA TRAVEL <span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria 'assistenza persona'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <!-- VUOTO -->
            </th>
            <th class="text-right pr-3">
              {{premioAssistenzaTravel}} €
            </th>
            <th class="text-center check-th-ptop">
              <input type="checkbox" *ngIf="!selAssistenzaPersona" disabled [(ngModel)]="selAssistenzaTravel">
              <input  style="display: none;" type="checkbox" class="btn-check" id="btn-check-41-outlined" checked autocomplete="off" [(ngModel)]="selAssistenzaTravel" (change)="calcolaPremi()">
                <label *ngIf="!selAssistenzaTravel && selAssistenzaPersona" class=" btn-outline-danger" for="btn-check-41-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selAssistenzaTravel && selAssistenzaPersona" class=" btn-danger" style="border-radius: 3px;" for="btn-check-41-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="text-right  pr-4">
              <span *ngIf="selAssistenzaTravel">{{activeAssistenzaTravel | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-right  pr-4">
              <span *ngIf="selAssistenzaTravel">{{scontatoAssistenzaTravel | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th class="">ASSISTENZA TRAVEL GOLD <span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria ‘assistenza persona'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <!-- VUOTO -->
          </th>
            <th class="text-right  pr-3">
              {{premioAssistenzaTravelGold}} €
            </th>
            <th class="text-center check-th-ptop">
              <input type="checkbox" *ngIf="!selAssistenzaPersona" disabled [(ngModel)]="selAssistenzaTravelGold">
              <input  style="display: none;" type="checkbox" class="btn-check" id="btn-check-42-outlined" checked autocomplete="off" [(ngModel)]="selAssistenzaTravelGold" (change)="calcolaPremi()">
                <label *ngIf="!selAssistenzaTravelGold && selAssistenzaPersona" class=" btn-outline-danger" for="btn-check-42-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selAssistenzaTravelGold && selAssistenzaPersona" class=" btn-danger" style="border-radius: 3px;" for="btn-check-42-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="text-right  pr-4">
              <span *ngIf="selAssistenzaTravelGold">{{activeAssistenzaTravelGold | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-right  pr-4">
              <span *ngIf="selAssistenzaTravelGold">{{scontatoAssistenzaTravelGold | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          
          <tr style="border-top: 2px grey solid !important;">
            <th scope="row" class="text-uppercase" rowspan="11" style="vertical-align: middle; border-right: grey solid 1px;">ATTIVITà</th>
            <th class="text-uppercase"rowspan="3" style="vertical-align: middle; border-right: 1px solid grey;">RESPONSABILITà CIVILE</th>
            <th>R.C. VERSO TERZI<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Venduta in buddle con 'R.C. verso prestatori di lavoro'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaRCTerzi" (change)="calcolaPremi()">
                  <option value="1">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;1.000.000</option>
                  <option value="2">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;1.500.000</option>
                </select>
              </div>
            </th>
            <th class="text-right  pr-3">
              {{premioRCTerzi | number:'1.2-2':'es'}} €
            </th>
            <th class="text-center check-th-ptop">
              <input type="checkbox" *ngIf="classeAttiva.classeRCT=='R.E.' || classeAttiva.classeRCT=='R.D.'" disabled [(ngModel)]="selRCTerzi">
              <input *ngIf="classeAttiva.classeRCT!='R.E.' && classeAttiva.classeRCT!='R.D.'" style="display: none;" type="checkbox" class="btn-check" id="btn-check-15-outlined" checked autocomplete="off" [(ngModel)]="selRCTerzi" (change)="calcolaPremi()">
                <label *ngIf="!selRCTerzi && classeAttiva.classeRCT!='R.E.' && classeAttiva.classeRCT!='R.D.'" class=" btn-outline-danger" for="btn-check-15-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selRCTerzi && classeAttiva.classeRCT!='R.E.' && classeAttiva.classeRCT!='R.D.'" class=" btn-danger" style="border-radius: 3px;" for="btn-check-15-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="selRCTerzi">{{premioRCTerzi | number:'1.2-2':'es'}} €</span>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="selRCTerzi">{{scontatoRCTerzi | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th>R.C. VERSO PRESTATORI DI LAVORO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Venduta in buddle con 'R.C. verso terzi'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaRCPrestatori" (change)="calcolaPremi()">
                  <option value="1">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;1.000.000</option>
                  <option value="2">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;1.500.000</option>
                </select>
              </div>
            </th>
            <th class="text-right  pr-3">
              {{premioRCPrestatori | number:'1.2-2':'es'}} €
            </th>
            <th class="text-center check-th-ptop">
              <input type="checkbox" *ngIf="classeAttiva.classeRCO=='R.E.' || classeAttiva.classeRCO=='R.D.'" disabled [(ngModel)]="selRCTerzi">
              <input *ngIf="classeAttiva.classeRCO!='R.E.' && classeAttiva.classeRCO!='R.D.'" style="display: none;" type="checkbox" class="btn-check" id="btn-check-15-outlined" checked autocomplete="off" [(ngModel)]="selRCTerzi" (change)="calcolaPremi()">
                <label *ngIf="!selRCTerzi && classeAttiva.classeRCO!='R.E.' && classeAttiva.classeRCO!='R.D.'" class=" btn-outline-danger" for="btn-check-15-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selRCTerzi && classeAttiva.classeRCO!='R.E.' && classeAttiva.classeRCO!='R.D.'" class=" btn-danger" style="border-radius: 3px;" for="btn-check-15-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="selRCTerzi">{{premioRCPrestatori | number:'1.2-2':'es'}} €</span>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="selRCTerzi">{{scontatoRCPrestatori | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th class="text-uppercase">R.C. DELLA PROPRIETà<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Sempre selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaRCProprieta" (change)="calcolaPremi()">
                  <option value="1">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;1.000.000</option>
                  <option value="2">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;1.500.000</option>
                </select>
              </div>
            </th>
            <th class="text-right  pr-3">
              {{premioRCProprieta | number:'1.2-2':'es'}} €
            </th>
            <th class="text-center check-th-ptop">
              <input  style="display: none;" type="checkbox" class="btn-check" id="btn-check-015-outlined" checked autocomplete="off" [(ngModel)]="selRCProprieta" (change)="calcolaPremi()">
                <label *ngIf="!selRCProprieta" class=" btn-outline-danger" for="btn-check-015-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selRCProprieta" class=" btn-danger" style="border-radius: 3px;" for="btn-check-015-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeRCProprieta!=null">{{activeRCProprieta | number:'1.2-2':'es'}} €</span>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeRCProprieta!=null">{{scontatoRCProprieta | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th class="bordo-su" style="vertical-align: middle; border-right: 1px solid grey;">TUTELA LEGALE</th>
            <th class="bordo-su">TUTELA LEGALE<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Sempre selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" style="width: 60%; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="50.000,00" disabled (change)="calcolaPremi()">
                <div class="input-group-append" style="height: 25px;">
                  <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                </div>
              </div>
            </th>
            <th class="bordo-su text-right  pr-3">
              {{premioTutelaLegale | number:'1.2-2':'es'}} €
            </th>
            <th class="text-center bordo-su check-th-ptop">
              <input style="display: none;" type="checkbox" class="btn-check" id="btn-check-16-outlined" checked autocomplete="off" [(ngModel)]="selTutelaLegale" (change)="calcolaPremi()">
                <label *ngIf="!selTutelaLegale" class=" btn-outline-danger" for="btn-check-16-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selTutelaLegale" class=" btn-danger" style="border-radius: 3px;" for="btn-check-16-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="selTutelaLegale">{{activeTutelaLegale | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="selTutelaLegale">{{scontatoTutelaLegale | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          
          <tr>
            <th class="bordo-su" rowspan="3" style="vertical-align: middle; border-right: 1px solid grey;">INCENDIO</th>
            <th class="bordo-su">INCENDIO FABBRICATO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Solo uno tra incendio fabbricato e rischio locativo selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <span *ngIf="!sommaIncendioFabbricatoIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempIncendioFabbricato" min="0" max="500000" (blur)="lockIncendioFabbricato()" (keyup.enter)="lockIncendioFabbricato()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaIncendioFabbricatoIsBlocked" (click)="unlockIncendioFabbricato()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaIncendioFabbricato | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
            </th>
            <th class="bordo-su text-right  pr-3"> {{premioIncendioFabbricato | number:'1.2-2':'es'}} €</th>
            <th class="text-center bordo-su check-th-ptop">
              <input style="display: none;" type="checkbox" class="btn-check" id="btn-check-18-outlined" checked autocomplete="off" [(ngModel)]="selIncendioFabbricato" (change)="incendioRischio(1)">
                <label *ngIf="!selIncendioFabbricato" class=" btn-outline-danger" for="btn-check-18-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selIncendioFabbricato" class=" btn-danger" style="border-radius: 3px;" for="btn-check-18-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activeIncendioFabbricato!=null">{{premioIncendioFabbricato | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activeIncendioFabbricato!=null">{{scontatoIncendioFabbricato | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th>INCENDIO CONTENUTO<span class="ml-2 text-red-custom" title="Sempre selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaIncendioContenuto" (change)="calcolaPremi()">
                  <option value="25000">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;25.000,00</option>
                  <option value="50000">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;50.000,00</option>
                  <option value="100000">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;100.000,00</option>
                  <option value="150000">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;150.000,00</option>
                  <option value="200000">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;200.000,00</option>
                </select>
              </div>
            </th>
            <th class="text-right  pr-3">{{premioIncendioContenuto | number:'1.2-2':'es'}} €</th>
            <th class="text-center check-th-ptop">
              <input style="display: none;" type="checkbox" class="btn-check" id="btn-check-19-outlined" checked autocomplete="off" [(ngModel)]="selIncendioContenuto" (change)="calcolaPremi()">
                <label *ngIf="!selIncendioContenuto" class=" btn-outline-danger" for="btn-check-19-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selIncendioContenuto" class=" btn-danger" style="border-radius: 3px;" for="btn-check-19-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeIncendioContenuto!=null">{{premioIncendioContenuto | number:'1.2-2':'es'}} €</span>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeIncendioContenuto!=null">{{scontatoIncendioContenuto | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th>RISCHIO LOCATIVO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Solo uno tra incendio fabbricato e rischio locativo selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <span *ngIf="!sommaRischioLocativoIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempRischioLocativo" min="0" max="500000" (blur)="lockRischioLocativo()" (keyup.enter)="lockRischioLocativo()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaRischioLocativoIsBlocked" (click)="unlockRischioLocativo()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaRischioLocativo | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
            </th>
            <th class="text-right pr-3">{{premioRischioLocativo | number:'1.2-2':'es'}} €</th>
            <th class="text-center check-th-ptop">
              <input style="display: none;" type="checkbox" class="btn-check" id="btn-check-20-outlined" checked autocomplete="off" [(ngModel)]="selRischioLocativo" (change)="incendioRischio(2)">
                <label *ngIf="!selRischioLocativo" class=" btn-outline-danger" for="btn-check-20-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selRischioLocativo" class=" btn-danger" style="border-radius: 3px;" for="btn-check-20-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right pr-4">
              <span *ngIf="activeRischioLocativo!=null">{{premioRischioLocativo | number:'1.2-2':'es'}} €</span>
            </th>
            <th class=" text-right pr-4">
              <span *ngIf="activeRischioLocativo!=null">{{scontatoRischioLocativo | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th class="bordo-su" style=" border-right: 1px solid grey;">FURTO E RAPINA</th>
            <th class="bordo-su">FURTO DEL CONTENUTO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria 'incendio contenuto'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaFurtoContenuto" (change)="calcolaPremi()">
                  <option value="1">€ 5.000</option>
                  <option value="2">€ 10.000</option>
                  <option value="3">€ 15.000</option>
                  <option value="4">€ 20.000</option>
                  <option *ngIf="classeAttiva.classeFurto!='C' && classeAttiva.classeFurto!='D'" value="5">€ 30.000</option>
                  <option *ngIf="classeAttiva.classeFurto!='C' && classeAttiva.classeFurto!='D'" value="6">€ 40.000</option>
                </select>
              </div>
            </th>
            <th class="bordo-su text-right pr-3">{{premioFurtoContenuto | number:'1.2-2':'es'}} €</th>
            <th class="text-center bordo-su check-th-ptop">
              <input *ngIf="!selIncendioContenuto || (classeAttiva.classeFurto=='R.E.' || classeAttiva.classeFurto=='R.D.')" disabled type="checkbox" [(ngModel)]="selFurtoContenuto" (change)="calcolaPremi()">
              <input *ngIf="selIncendioContenuto && (classeAttiva.classeFurto!='R.E.' && classeAttiva.classeFurto!='R.D.')" style="display: none;" type="checkbox" class="btn-check" id="btn-check-21-outlined" checked autocomplete="off" [(ngModel)]="selFurtoContenuto" (change)="calcolaPremi()">
                <label *ngIf="(selIncendioContenuto && (classeAttiva.classeFurto!='R.E.' && classeAttiva.classeFurto!='R.D.')) && !selFurtoContenuto" class=" btn-outline-danger" for="btn-check-21-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="(selIncendioContenuto && (classeAttiva.classeFurto!='R.E.' && classeAttiva.classeFurto!='R.D.')) && selFurtoContenuto" class=" btn-danger" style="border-radius: 3px;" for="btn-check-21-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activeFurtoContenuto!=null">{{activeFurtoContenuto | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activeFurtoContenuto!=null">{{scontatoFurtoContenuto | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr>
            <th class="bordo-su" style=" border-right: 1px solid grey;">INCASSI POS</th>
            <th class="bordo-su">INCASSI POS
              <span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Sempre selezionabile">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                </svg>
              </span>
            </th>
            <th class="bordo-su">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" style="width: 60%; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{ sommaPos | number:'1.2-2':'es' }}" disabled>
                <div class="input-group-append" style="height: 25px;">
                  <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                </div>
              </div>
            </th>
            <th class="bordo-su text-right pr-3">{{ premioPos | number:'1.2-2':'es' }} €</th>
            <th class="text-center bordo-su check-th-ptop">
              <input style="display: none;" type="checkbox" class="btn-check" id="btn-check-43-outlined" checked autocomplete="off" [(ngModel)]="selPos" (change)="calcolaPremi()">
              <label *ngIf="!selPos" class=" btn-outline-danger" for="btn-check-43-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="selPos" class=" btn-danger" style="border-radius: 3px;" for="btn-check-43-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="selPos">{{ activePos | number:'1.2-2':'es' }} €</span>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="selPos">{{ scontatoPos | number:'1.2-2':'es' }} €</span>
            </th>
          </tr>

          <tr>
            <th class="bordo-su" style=" border-right: 1px solid grey;">ELETTRONICA E GUASTI MACCHINE</th>
            <th class="bordo-su">ELETTRONICA E GUASTI MACCHINE<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Sempre selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaElettronica" (change)="calcolaPremi()">
                  <option value="1">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;5.000,00</option>
                  <option value="2">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;10.000,00</option>
                  <option value="3">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;15.000,00</option>
                  <option value="4">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;20.000,00</option>
                  <option value="5">&nbsp;€ &nbsp;&nbsp;&nbsp;&nbsp;30.000,00</option>
                </select>
              </div>
            </th>
            <th class="bordo-su text-right pr-3">{{premioElettronica | number:'1.2-2':'es'}} €</th>
            <th class="text-center bordo-su check-th-ptop">
              <input style="display: none;" type="checkbox" class="btn-check" id="btn-check-45-outlined" checked autocomplete="off" [(ngModel)]="selElettronica" (change)="calcolaPremi()">
                <label *ngIf="!selElettronica" class=" btn-outline-danger" for="btn-check-45-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selElettronica" class=" btn-danger" style="border-radius: 3px;" for="btn-check-45-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activeElettronica!=null">{{activeElettronica | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activeElettronica!=null">{{scontatoElettronica | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          
          <tr>
            <th class="bordo-su" style=" border-right: 1px solid grey;">ENERGIE RINNOVABILI ALL RISK</th>
            <th class="bordo-su">ENERGIE RINNOVABILI ALL RISK<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria 'incendio contenuto' (?)">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <span *ngIf="!sommaEnergieRinnovabiliIsBlocked">
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempEnergieRinnovabili" min="0" max="800000" (blur)="lockEnergieRinnovabili()" (keyup.enter)="lockEnergieRinnovabili()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaEnergieRinnovabiliIsBlocked" (click)="unlockEnergieRinnovabili()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaEnergieRinnovabili | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
          </th>
            <th class="bordo-su text-right pr-3">
              {{premioEnergieRinnovabili | number:'1.2-2':'es'}} €
            </th>
            <th class="text-center bordo-su check-th-ptop">
              <input style="display: none;" type="checkbox" class="btn-check" id="btn-check-22-outlined" checked autocomplete="off" [(ngModel)]="selEnergieRinnovabili" (change)="calcolaPremi()">
                <label *ngIf="!selEnergieRinnovabili" class=" btn-outline-danger" for="btn-check-22-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selEnergieRinnovabili" class=" btn-danger" style="border-radius: 3px;" for="btn-check-22-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activeEnergieRinnovabili!=null">{{activeEnergieRinnovabili | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activeEnergieRinnovabili!=null">{{scontatoEnergieRinnovabili | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>

          <tr style="border-top: double 3px grey;">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-right"><b> TOTALE</b></th>
            <th class="text-right pr-4"><b> {{totale | number:'1.2-2':'es'}} €</b></th>
            <th class="text-right pr-4"><b> {{totaleScontato | number:'1.2-2':'es'}} €</b></th>
          </tr>
          <tr style="border-top: double 3px grey; border-bottom: double 3px grey;">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-right"><b> MENSILIZZATO</b></th>
            <th class="text-right pr-4"><b> {{mensilizzato | number:'1.2-2':'es'}} €</b></th>
            <th class="text-right pr-4"><b> {{mensilizzatoScontato | number:'1.2-2':'es'}} €</b></th>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</div>
<div class="modal bd-example-modal-lg fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Garanzie selezionate</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
        <table class="table table-hover">
          <thead>
            <tr>
              <td class="carrello" scope="col">Garanzia</td>
              <td class="carrello text-right" scope="col">Premio</td>
              <td class="carrello text-right" scope="col">Premio scontato</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let garanzia of garanzieSelezionate">
              <th class="carrello">{{garanzia.garanzia}}</th>
              <th class="carrello text-right">{{garanzia.premio | number:'1.2-2':'es'}} €</th>
              <th class="carrello text-right">{{garanzia.premioScontato | number:'1.2-2':'es'}} €</th>
            </tr>
            <tr>
              <td class="carrello text-right font-weight-bold">Totale</td>
              <td class="carrello text-right font-weight-bold">{{totale | number:'1.2-2':'es'}} €</td>
              <td class="carrello text-right font-weight-bold">{{totaleScontato | number:'1.2-2':'es'}} €</td>
            </tr>
            <tr>
              <td class="carrello text-right font-weight-bold">Mensilizzato</td>
              <td class="carrello text-right font-weight-bold">{{mensilizzato | number:'1.2-2':'es'}} €</td>
              <td class="carrello text-right font-weight-bold">{{mensilizzatoScontato | number:'1.2-2':'es'}} €</td>
            </tr>
            <tr style="border-top: grey solid 2px;">
              <td class="carrello"></td>
              <td class="carrello text-right font-weight-bold">Percentuale di sconto</td>
              <td class="carrello text-right font-weight-bold">{{sconto}} %</td>
            </tr>
            <tr>
              <td class="carrello text-right font-weight-bold"></td>
              <td class="carrello text-right font-weight-bold">Importo scontato annuo</td>
              <td class="carrello text-right font-weight-bold">{{(totale - totaleScontato) | number:'1.2-2':'es'}} €</td>
            </tr>
            <tr>
              <td class="carrello text-right font-weight-bold"></td>
              <td class="carrello text-right font-weight-bold">Importo scontato mensile</td>
              <td class="carrello text-right font-weight-bold">{{(mensilizzato - mensilizzatoScontato) | number:'1.2-2':'es'}} €</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
      </div>
    </div>
  </div>
</div>