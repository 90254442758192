<div class="container" style="font-size: 10px;">
  <div class="row mt-4">
    <div class="col-10">
      <div class="row">
        <div class="col-4 header-row font-weight-bold header-title">
          Età assicurato
        </div>
        <div class="col-3 header-row">
          <div class="input-group input-group-sm ml-1">
            <input type="number" class="form-control" min="0" max="100" [(ngModel)]="header.anni" (change)="ageControl()"><span class="text-danger" *ngIf="errorAge"><b>Inserire età compresa tra 0 e 100</b> </span>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Tipo lavoratore
        </div>
        <div class="col-8 header-row">
          <!-- <select style="width: 275px" [(ngModel)]="header.tipoLavoratore" (change)="setTipoLavoratore()">
            <option value="1">Lavoratore autonomo</option>
            <option value="2">Lavoratore non autonomo</option>
            <option value="3">Non lavoratore</option>
          </select> -->
          <button *ngIf="header.tipoLavoratore!=1" class="ml-1 btn btn-sm btn-secondary" (click)="setTipoLavoratore(1)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-handbag-fill" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 0-2 2v2H5V3a3 3 0 1 1 6 0v2h-1V3a2 2 0 0 0-2-2zM5 5H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11v1.5a.5.5 0 0 1-1 0V5H6v1.5a.5.5 0 0 1-1 0V5z"/>
            </svg> Autonomo
          </button>
          <button *ngIf="header.tipoLavoratore==1" class="ml-1 btn btn-sm btn-red-custom" (click)="setTipoLavoratore(1)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-handbag-fill" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 0-2 2v2H5V3a3 3 0 1 1 6 0v2h-1V3a2 2 0 0 0-2-2zM5 5H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11v1.5a.5.5 0 0 1-1 0V5H6v1.5a.5.5 0 0 1-1 0V5z"/>
            </svg> Autonomo
          </button>
          <button *ngIf="header.tipoLavoratore!=2" class="ml-1 btn btn-sm btn-secondary" (click)="setTipoLavoratore(2)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card-2-back" viewBox="0 0 16 16">
              <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z"/>
              <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z"/>
            </svg> Non autonomo
          </button>
          <button *ngIf="header.tipoLavoratore==2" class="ml-1 btn btn-sm btn-red-custom" (click)="setTipoLavoratore(2)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card-2-back" viewBox="0 0 16 16">
              <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z"/>
              <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z"/>
            </svg> Non autonomo
          </button>
          <button *ngIf="header.tipoLavoratore!=3" class="ml-1 btn btn-sm btn-secondary" (click)="setTipoLavoratore(3)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg> Non lavoratore
          </button>
          <button *ngIf="header.tipoLavoratore==3" class="ml-1 btn btn-sm btn-red-custom" (click)="setTipoLavoratore(3)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg> Non lavoratore
          </button>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Professione
        </div>
        <div class="col-2 header-row">
          <div class="input-group input-group-sm ml-1">
            <input type="text" class="form-control" [(ngModel)]="professioneFilterString" (change)="filterProfessione()">
            <div class="input-group-append">
              <span class="input-group-text" style="background-color: white; font-size: x-small;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filter" viewBox="0 0 16 16">
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div class="col-6 header-row">
          <div class="input-group input-group-sm ml-1">
            <select style="width: 275px" class="form-control" [(ngModel)]="prof" (change)="selectProfessione()">
              <option *ngFor="let op of professioneFiltered; let i = index" value="{{i}}">{{op.titolo}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Tipo abitazione
        </div>
        <div class="col-8 header-row">
          <!-- <select style="width: 275px" [(ngModel)]="header.tipoAbitazione" (change)="changeAbitazione()">
            <option value="1">Abituale abitazione in appartamento o in villa a schiera</option>
            <option value="2">Abituale abitazione in villa isolata</option>
            <option value="3">Saltuaria</option>
          </select> -->
          <button *ngIf="header.tipoAbitazione!=1" class="ml-1 btn btn-sm btn-secondary" (click)="changeAbitazione(1)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
              <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
            </svg> Appartamento o villa a schiera
          </button>
          <button *ngIf="header.tipoAbitazione==1" class="ml-1 btn btn-sm btn-red-custom" (click)="changeAbitazione(1)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
              <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
            </svg> Appartamento o villa a schiera
          </button>
          <button *ngIf="header.tipoAbitazione!=2" class="ml-1 btn btn-sm btn-secondary" (click)="changeAbitazione(2)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
              <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
            </svg> Villa isolata
          </button>
          <button *ngIf="header.tipoAbitazione==2" class="ml-1 btn btn-sm btn-red-custom" (click)="changeAbitazione(2)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
              <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
            </svg> Villa isolata
          </button>
          <button *ngIf="header.tipoAbitazione!=3" class="ml-1 btn btn-sm btn-secondary" (click)="changeAbitazione(3)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
            </svg> Saltuaria
          </button>
          <button *ngIf="header.tipoAbitazione==3" class="ml-1 btn btn-sm btn-red-custom" (click)="changeAbitazione(3)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
            </svg> Saltuaria
          </button>
        </div>
      </div>


      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Piano immobile
        </div>
        <div class="col-8 header-row">
          <button *ngIf="header.pianoImmobile!=1" class="ml-1 btn btn-sm btn-secondary" (click)="changePianoAbitazione(1)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shop-window" viewBox="0 0 16 16">
              <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h12V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zm2 .5a.5.5 0 0 1 .5.5V13h8V9.5a.5.5 0 0 1 1 0V13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5a.5.5 0 0 1 .5-.5z"/>
            </svg> Piano terra o superiore
          </button>
          <button *ngIf="header.pianoImmobile==1" class="ml-1 btn btn-sm btn-red-custom">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shop-window" viewBox="0 0 16 16">
              <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h12V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zm2 .5a.5.5 0 0 1 .5.5V13h8V9.5a.5.5 0 0 1 1 0V13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5a.5.5 0 0 1 .5-.5z"/>
            </svg> Piano terra o superiore
          </button>
          <button *ngIf="header.pianoImmobile!=2" class="ml-1 btn btn-sm btn-secondary" (click)="changePianoAbitazione(2)">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bricks" viewBox="0 0 16 16">
              <path d="M0 .5A.5.5 0 0 1 .5 0h15a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H2v-2H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 6H2V4H.5a.5.5 0 0 1-.5-.5v-3zM3 4v2h4.5V4H3zm5.5 0v2H13V4H8.5zM3 10v2h4.5v-2H3zm5.5 0v2H13v-2H8.5zM1 1v2h3.5V1H1zm4.5 0v2h5V1h-5zm6 0v2H15V1h-3.5zM1 7v2h3.5V7H1zm4.5 0v2h5V7h-5zm6 0v2H15V7h-3.5zM1 13v2h3.5v-2H1zm4.5 0v2h5v-2h-5zm6 0v2H15v-2h-3.5z"/>
            </svg> Interrato o seminterrato
          </button>
          <button *ngIf="header.pianoImmobile==2" class="ml-1 btn btn-sm btn-red-custom">
            <svg style="padding-bottom: 4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bricks" viewBox="0 0 16 16">
              <path d="M0 .5A.5.5 0 0 1 .5 0h15a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H2v-2H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 6H2V4H.5a.5.5 0 0 1-.5-.5v-3zM3 4v2h4.5V4H3zm5.5 0v2H13V4H8.5zM3 10v2h4.5v-2H3zm5.5 0v2H13v-2H8.5zM1 1v2h3.5V1H1zm4.5 0v2h5V1h-5zm6 0v2H15V1h-3.5zM1 7v2h3.5V7H1zm4.5 0v2h5V7h-5zm6 0v2H15V7h-3.5zM1 13v2h3.5v-2H1zm4.5 0v2h5v-2h-5zm6 0v2H15v-2h-3.5z"/>
            </svg> Interrato o seminterrato
          </button>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Provincia immobile
        </div>
        <div class="col-3 header-row">
          <div class="input-group input-group-sm ml-1" style="vertical-align: middle;">
            <select class="form-control" [(ngModel)]="header.provincia" (change)="calcolaPremi()">
              <option *ngFor="let prov of provinceArray" value="{{prov.provincia}}">{{prov.provincia}}</option>
            </select>
          </div>
        </div>
        <div class="col-4 header-row">
          <div class="input-group input-group-sm ml-1">
          </div>
        </div>
      </div>

      <div class="row mt-1" style="vertical-align: middle;">
        <div class="col-4 header-row font-weight-bold header-title">
          CAP immobile
        </div>
        <div class="col-3 header-row" >
          <div class="input-group input-group-sm ml-1" style="vertical-align: middle;">
            <input type="text" width="8em" maxlength="5" class="form-control" [(ngModel)]="capIns" (keyup)="checkCap()">
          </div>
        </div>
        <div class="col-3 header-row" style="padding-top: 0.3rem;">
          <span class="text-success ml-1 " style="font-size: 14px;" *ngIf="isCapValid"><b>Cap valido</b></span>
          <span class="text-danger ml-1" style="font-size: 14px;" *ngIf="!isCapValid"><b>Cap NON valido</b></span>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Rischio proveniente da altra compagnia
        </div>
        <div class="col-3 header-row">
          <!-- <select [(ngModel)]="header.daAltraCompagnia">
            <option value="false">NO</option>
            <option value="true">SI</option>
          </select> -->
          <button *ngIf="!header.daAltraCompagnia" class="ml-1 btn btn-sm btn-secondary" (click)="daAltraCompagnia(1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </button>
          <button *ngIf="header.daAltraCompagnia" class="ml-1 btn btn-sm btn-success">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </button>
          <button *ngIf="header.daAltraCompagnia" class="ml-1 btn btn-sm btn-secondary" (click)="daAltraCompagnia(2)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
          </button>
          <button *ngIf="!header.daAltraCompagnia" class="ml-1 btn btn-sm btn-red-custom">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Rischio collegato a mutuo
        </div>
        <div class="col-3 header-row">
          <!-- <select [(ngModel)]="header.collegatoMutuo">
            <option value="false">NO</option>
            <option value="true">SI</option>
          </select> -->
          <button *ngIf="!header.collegatoMutuo" class="ml-1 btn btn-sm btn-secondary" (click)="collegatoMutuo(1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </button>
          <button *ngIf="header.collegatoMutuo" class="ml-1 btn btn-sm btn-success">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </button>
          <button *ngIf="header.collegatoMutuo" class="ml-1 btn btn-sm btn-secondary" (click)="collegatoMutuo(2)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
          </button>
          <button *ngIf="!header.collegatoMutuo" class="ml-1 btn btn-sm btn-red-custom">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-4 header-row font-weight-bold header-title">
          Sport pericolosi
        </div>
        <div class="col-3 header-row">
          <!-- <select [(ngModel)]="header.collegatoMutuo">
            <option value="false">NO</option>
            <option value="true">SI</option>
          </select> -->
          <button *ngIf="!header.sportPericolosi" class="ml-1 btn btn-sm btn-secondary" (click)="setSportPericolosi(true)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </button>
          <button *ngIf="header.sportPericolosi" class="ml-1 btn btn-sm btn-success">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </button>
          <button *ngIf="header.sportPericolosi" class="ml-1 btn btn-sm btn-secondary" (click)="setSportPericolosi(false)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
          </button>
          <button *ngIf="!header.sportPericolosi" class="ml-1 btn btn-sm btn-red-custom">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="col-2 text-right">
      <button type="button" class="btn btn-red-custom" data-toggle="modal" data-target="#exampleModal">
        Carrello
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-fill" viewBox="0 0 16 16">
          <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
        </svg>
      </button>
      <!-- <button type="button" class="btn btn-red-custom mt-2" data-toggle="modal" data-target="#requisitiModal">
        Requisiti
      </button> -->
      <br>
      <button class="btn btn-red-custom mt-2" (click)="removeAll()">Svuota</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-right font-weight-bold" style="font-size: 13px;">
          Sconto: {{sconto}} %
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <div class="">
      <table class="table table-borderless" style="border-right: solid grey 1px; border-left: solid grey 1px">
          <thead class="bg-red-custom text-light">
            <tr class="tab-head font-weight-bold">
            <td class="tab-head bg-red-custom" scope="col" style="width: 11%; vertical-align: middle;">Bisogno</td>
            <td class="tab-head bg-red-custom" scope="col" style="width: 19%; vertical-align: middle;">Sezione</td>
            <td class="tab-head bg-red-custom" scope="col" style="width: 22%; vertical-align: middle;">Garanzia</td>
            <td class="tab-head bg-red-custom" scope="col" style="width: 8%; vertical-align: middle;">Somma assicurata/Forma</td>
            <td class="tab-head bg-red-custom pl-4" scope="col" style="width: 10%; vertical-align: middle;">Premio</td>
            <td class="tab-head bg-red-custom text-center" scope="col" style="width: 6%; vertical-align: middle;">Selezione</td>
            <td class="tab-head bg-red-custom pl-4" scope="col" style="width: 8%; vertical-align: middle;">Premio selezione</td>
            <td class="tab-head bg-red-custom pl-4" scope="col" style="width: 8%; vertical-align: middle;">Premio scontato</td>
          </tr>
        </thead>
        <tbody>

          <tr>
            <th rowspan="2" style="border-right: 1px solid grey !important;">FAMIGLIA E AFFETTI</th>
            <th  style="border-right: 1px solid grey !important;">TEMPORANEA CASO MORTE</th>
            <th>TEMPORANEA CASO MORTE <span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età massima di 75 anni">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <span *ngIf="!sommaTCPIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempTCP" min="0" max="500000" (blur)="lockTCP()" (keyup.enter)="lockTCP()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaTCPIsBlocked" (click)="unlockTCP()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaTCP | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
            </th>
            <th class="text-right pr-3">
              <span style="font-size: 10px;" *ngIf="premioTCP=='Non disponibile'">{{premioTCP}}</span>
              <span *ngIf="premioTCP!='Non disponibile'">{{premioTCP | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="premioTCP=='Non disponibile'" disabled type="checkbox" (change)="calcoliMorte(2)" [(ngModel)]="selTCP">
              <input *ngIf="premioTCP!='Non disponibile'"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-2-outlined" checked autocomplete="off" [(ngModel)]="selTCP" (change)="calcoliMorte(2)">
              <label *ngIf="premioTCP!='Non disponibile' && !selTCP" class=" btn-outline-danger" for="btn-check-2-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="premioTCP!='Non disponibile' && selTCP" class=" btn-danger" style="border-radius: 3px;" for="btn-check-2-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeTCP!=null && premioTCP!='Non disponibile'">{{premioTCP | number:'1.2-2':'es'}} €</span>
              <span *ngIf="activeTCP!=null && premioTCP=='Non disponibile'">{{premioTCP}}</span>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeTCP!=null">{{scontatoTCP | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="bordo-su" style=" border-right: 1px solid grey;">INDENNITARIA GRAVI MALATTIE</th>
            <th class="bordo-su">INDENNITARIA GRAVI MALATTIE<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria 'temporanea caso morte ed età massima di 74 anni">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <!-- <div class="input-group input-group-sm">
                <input type="text" class="form-control" style="width: 60%; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="10.000" disabled (change)="calcolaPremi()">
                <div class="input-group-append" style="height: 25px;">
                  <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;&nbsp;</span>
                </div>
              </div> -->
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaGraviMalattie" (change)="calcolaPremi()">
                  <option value="10000">€ &nbsp;&nbsp;&nbsp;&nbsp;10.000,00</option>
                  <option value="20000">€ &nbsp;&nbsp;&nbsp;&nbsp;20.000,00</option>
                  <option value="30000">€ &nbsp;&nbsp;&nbsp;&nbsp;30.000,00</option>
              </select>
              </div>
            </th>
            <th class="bordo-su text-right  pr-3">
              <span *ngIf="premioTCP!='Non disponibile' && premioGraviMalattie!='Non disponibile' && header.anni<65 && header.anni>17">{{premioGraviMalattie | number:'1.2-2':'es'}} €</span>
              <span *ngIf="premioTCP=='Non disponibile' || header.anni>64 || header.anni<18">Non disponibile</span>
            </th>
            <th class="text-center bordo-su check-th-ptop">
              <input *ngIf="!selTCP || header.anni>64 || header.anni<18" disabled type="checkbox" (change)="calcolaPremi()" [(ngModel)]="selGraviMalattie">
              <input *ngIf="selTCP && header.anni<65 && header.anni>17"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-3-outlined" checked autocomplete="off" [(ngModel)]="selGraviMalattie" (change)="calcolaPremi()">
              <label *ngIf="selTCP && !selGraviMalattie  && header.anni<65 && header.anni>17" class=" btn-outline-danger" for="btn-check-3-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="selTCP && selGraviMalattie" class=" btn-danger" style="border-radius: 3px;" for="btn-check-3-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activeGraviMalattie!=null && premioGraviMalattie!='Non disponibile'">{{premioGraviMalattie | number:'1.2-2':'es'}} €</span>
              <span *ngIf="activeGraviMalattie!=null && premioGraviMalattie=='Non disponibile'">{{premioGraviMalattie}}</span>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activeGraviMalattie!=null">{{scontatoGraviMalattie | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <!-- INIZIO INFORTUNI -->
          <tr style="border-top: 2px grey solid !important;">
            <th style="border-right: 1px solid grey; vertical-align: middle;" rowspan="12">BENESSERE E SALUTE</th>
            <th rowspan="5" style="vertical-align: middle; border-right: 1px solid grey;">INFORTUNI</th>
            <th>MORTE DA INFORTUNIO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età massima di 75 anni">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <span *ngIf="!sommaMorteIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempMorte" min="0" max="500000" (blur)="lockMorte()" (keyup.enter)="lockMorte()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaMorteIsBlocked" (click)="unlockMorte()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaMorte | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
            </th>
            <th class="text-right pr-3">
              <span *ngIf="header.anni<76">{{premioMorte | number:'1.2-2'}}  €</span>
              <span style="font-size: 10px;" *ngIf="header.anni>75">Non disponibile</span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="header.anni>75" style="padding-top: 0.9em;" class="myinput" type="checkbox" disabled [(ngModel)]="selMorte" >

              <input *ngIf="header.anni<76"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-4-outlined" checked autocomplete="off" [(ngModel)]="selMorte" (change)="calcoliMorte(1)">
              <label *ngIf="header.anni<76 && !selMorte" class=" btn-outline-danger" for="btn-check-4-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="header.anni<76 && selMorte" class=" btn-danger" style="border-radius: 3px;" for="btn-check-4-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class=" text-right pr-4"><span *ngIf="activeMorte!=null">{{premioMorte | number:'1.2-2'}} €</span></th>
            <th class=" text-right pr-4"><span *ngIf="activeMorte!=null">{{scontatoMorte | number:'1.2-2'}} €</span></th>
          </tr>
          <tr>
            <th class="text-uppercase">INVALIDITà PERMANENTE DA INFORTUNIO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria almeno una tra 'morte da infortunio' e temporanea caso morte'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <span *ngIf="!sommaIPInfortunioIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempIPInfortunio" min="0" max="500000" (blur)="lockIPInfortunio()" (keyup.enter)="lockIPInfortunio()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaIPInfortunioIsBlocked" (click)="unlockIPInfortunio()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaIPInfortunio | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
           </th>
           <th class="text-right pr-3">
            <span *ngIf="header.anni<76">{{premioIPInfortunio | number:'1.2-2':'es'}}  €</span>
            <span style="font-size: 10px;" *ngIf="header.anni>75">Non disponibile</span>
           </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="!selMorte && !selTCP" type="checkbox" disabled (change)="calcolaPremi()" [(ngModel)]="selIPInfortunio">
              <input *ngIf="(selMorte || selTCP)"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-5-outlined" checked autocomplete="off" [(ngModel)]="selIPInfortunio" (change)="calcoliMorte(3)">
              <label *ngIf="(selMorte || selTCP) && !selIPInfortunio" class=" btn-outline-danger" for="btn-check-5-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="(selMorte || selTCP) && selIPInfortunio" class=" btn-danger" style="border-radius: 3px;" for="btn-check-5-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class=" text-right pr-4"><span *ngIf="activeIPInfortunio!=null">{{premioIPInfortunio | number:'1.2-2':'es'}} €</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activeIPInfortunio!=null">{{scontatoIPInfortunio | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="text-uppercase">INABILITà TEMPORANEA DA INFORTUNIO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria 'invalidità permanente da infortunio'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaITInfortunio" (change)="calcolaPremi()">
                  <option value="1">€ &nbsp;&nbsp;&nbsp;&nbsp;50,00</option>
                  <option value="2">€ &nbsp;&nbsp;&nbsp;&nbsp;75,00</option>
                  <option value="3">€ &nbsp;&nbsp;&nbsp;&nbsp;100,00</option>
                  <option value="4">€ &nbsp;&nbsp;&nbsp;&nbsp;150,00</option>
              </select>
              </div>
            </th>
            <th class="text-right pr-3">
              <span *ngIf="header.anni<76">{{premioITInfortunio | number:'1.2-2':'es'}}  €</span>
              <span style="font-size: 10px;" *ngIf="header.anni>75">Non disponibile</span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="!selIPInfortunio" type="checkbox" disabled (change)="calcolaPremi()" [(ngModel)]="selITInfortunio">
              <input *ngIf="selIPInfortunio"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-6-outlined" checked autocomplete="off" [(ngModel)]="selITInfortunio" (change)="calcolaPremi()">
              <label *ngIf="selIPInfortunio && !selITInfortunio" class=" btn-outline-danger" for="btn-check-6-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="selIPInfortunio && selITInfortunio" class=" btn-danger" style="border-radius: 3px;" for="btn-check-6-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class=" text-right pr-4"><span *ngIf="activeITInfortunio!=null">{{premioITInfortunio | number:'1.2-2':'es'}} €</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activeITInfortunio!=null">{{scontatoITInfortunio | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th>RIMBORSO SPESE CURE DA INFORTUNIO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria 'invalidità permanente da infortunio'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right" class="form-control" [(ngModel)]="sommaSpeseCura" (change)="calcolaPremi()">
                  <option value="1">€ &nbsp;&nbsp;&nbsp;&nbsp;5.000,00</option>
                  <option value="2">€ &nbsp;&nbsp;&nbsp;&nbsp;10.000,00</option>
                  <option value="3">€ &nbsp;&nbsp;&nbsp;&nbsp;15.000,00</option>
                  <option value="4">€ &nbsp;&nbsp;&nbsp;&nbsp;20.000,00</option>
                  <option value="5">€ &nbsp;&nbsp;&nbsp;&nbsp;25.000,00</option>
                </select>
              </div>
            </th>
            <th class="text-right pr-3">
             <span *ngIf="header.anni<76">{{premioSpeseCura | number:'1.2-2':'es'}}  €</span>
             <span   style="font-size: 10px;" *ngIf="header.anni>75">Non disponibile</span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="!selIPInfortunio" type="checkbox" disabled (change)="calcolaPremi()" [(ngModel)]="selSpeseCura">
              <input *ngIf="selIPInfortunio"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-7-outlined" checked autocomplete="off" [(ngModel)]="selSpeseCura" (change)="calcolaPremi()">
              <label *ngIf="selIPInfortunio && !selSpeseCura" class=" btn-outline-danger" for="btn-check-7-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="selIPInfortunio && selSpeseCura" class=" btn-danger" style="border-radius: 3px;" for="btn-check-7-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class=" text-right pr-4"><span *ngIf="activeSpeseCura!=null">{{premioSpeseCura | number:'1.2-2':'es'}} €</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activeSpeseCura!=null">{{scontatoSpeseCura | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th>DIARIA DA RICOVERO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età massima di 70 anni">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
                <div class="input-group input-group-sm">
                  <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right" class="form-control" [(ngModel)]="sommaDiariaRicovero" (change)="calcolaPremi()">
                    <option value="1">€ &nbsp;&nbsp;&nbsp;&nbsp; 50,00</option>
                    <option value="2">€ &nbsp;&nbsp;&nbsp;&nbsp; 75,00</option>
                    <option value="3">€ &nbsp;&nbsp;&nbsp;&nbsp; 100,00</option>
                  </select>
                </div>
            </th>
            <th class="text-right pr-3">
              <span   style="font-size: 10px;" *ngIf="header.anni>70">Non disponibile</span>
              <span *ngIf="header.anni<71 && premioDiariaRicovero != 'Non disponibile'">{{premioDiariaRicovero | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input type="checkbox" *ngIf="header.anni>70" disabled [(ngModel)]="selDiariaRicovero">

              <input *ngIf="header.anni<71"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-8-outlined" checked autocomplete="off" [(ngModel)]="selDiariaRicovero" (change)="calcolaPremi()">
              <label *ngIf="header.anni<71 && !selDiariaRicovero" class=" btn-outline-danger" for="btn-check-8-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="header.anni<71 && selDiariaRicovero" class=" btn-danger" style="border-radius: 3px;" for="btn-check-8-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class="text-right pr-4"><span *ngIf="activeDiariaRicovero!=null">{{premioDiariaRicovero | number:'1.2-2':'es'}} €</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activeDiariaRicovero!=null">{{scontatoDiariaRicovero | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <!-- FINE INFORTUNI -->

          <tr class="bordo-su">
            <th class="text-uppercase" style="vertical-align: middle; border-right: 1px solid grey;">Invalidità permanente da malattia</th>
            <th class="text-uppercase">Invalidità permanente da malattia<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età massima di 65 anni e necessaria 'invalidità permanente da infortunio">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
                <div class="input-group input-group-sm">
                  <span *ngIf="!sommaIPMalattiaIsBlocked" >
                    <div class="input-group input-group-sm">
                      <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" disabled [(ngModel)]="sommaIPMalattia" min="0" max="250000" (blur)="lockIPMalattia()" (keyup.enter)="lockIPMalattia()">
                  <div class="input-group-append" style="height: 25px;">
                      <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                      </div>
                    </div>
                  </span>
                  <span *ngIf="sommaIPMalattiaIsBlocked">
                    <div class="input-group input-group-sm">
                      <input type="text" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" disabled value="{{sommaIPMalattia | number:'1.2-2':'es'}}">
                      <div class="input-group-append" style="height: 25px;">
                        <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                      </div>
                    </div>
                  </span>
                </div>
            </th>
            <th class="text-right pr-3">
              <span   style="font-size: 10px;" *ngIf="(header.anni>65 || !selIPInfortunio) && premioIPMalattia=='Non disponibile'">{{premioIPMalattia}}</span>
              <span   style="font-size: 10px;" *ngIf="(header.anni>65 || !selIPInfortunio) && premioIPMalattia!='Non disponibile'">{{premioIPMalattia | number:'1.2-2':'es'}} €</span>
              <span *ngIf="header.anni<66 && selIPInfortunio">{{premioIPMalattia | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="header.anni>65 || !selIPInfortunio" type="checkbox" disabled >
              <input *ngIf="header.anni<66 && selIPInfortunio"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-9-outlined" checked autocomplete="off" [(ngModel)]="selIPMalattia" (change)="calcolaPremi()">
              <label *ngIf="(header.anni<66 && selIPInfortunio) && !selIPMalattia" class=" btn-outline-danger" for="btn-check-9-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="(header.anni<66 && selIPInfortunio) && selIPMalattia" class=" btn-danger" style="border-radius: 3px;" for="btn-check-9-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class="text-right pr-4"><span *ngIf="activeIPMalattia!=null ">{{activeIPMalattia | number:'1.2-2':'es'}} €</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activeIPMalattia!=null">{{scontatoIPMalattia | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="bordo-su" rowspan="4" style="vertical-align: middle; border-right: 1px solid grey;">SALUTE</th>
            <th class="bordo-su">Piano A<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età tra i 18 e i 74 anni, solo uno dei tre (A, B e C) selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="tipoPianoA" (change)="selezioneTipoPianoSalute(tipoPianoA)">
                  <option value="1">Individuale</option>
                  <option value="2">Familiare</option>
              </select>
              </div>
            </th>
            <th class="bordo-su text-right pr-3">
              <span style="font-size: 10px;" *ngIf="premioPianoSaluteA=='Non disponibile'">{{premioPianoSaluteA}}</span>
              <span *ngIf="premioPianoSaluteA!='Non disponibile'">{{premioPianoSaluteA | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center bordo-su check-th-ptop">
              <input  *ngIf="premioPianoSaluteA=='Non disponibile'" disabled type="checkbox" (change)="checkPianoSalute(1, $event)" [(ngModel)]="selPianoA">
              <input *ngIf="premioPianoSaluteA!='Non disponibile'"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-10-outlined" checked autocomplete="off" [(ngModel)]="selPianoA" (change)="checkPianoSalute(1, $event)">
                <label *ngIf="premioPianoSaluteA!='Non disponibile' && !selPianoA" class=" btn-outline-danger" for="btn-check-10-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="premioPianoSaluteA!='Non disponibile' && selPianoA" class=" btn-danger" style="border-radius: 3px;" for="btn-check-10-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activePianoA!=null && premioPianoSaluteA!='Non disponibile'">{{premioPianoSaluteA | number:'1.2-2':'es'}} €</span>
              <span *ngIf="activePianoA!=null && premioPianoSaluteA=='Non disponibile'">{{premioPianoSaluteA}}</span></th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activePianoA!=null">{{scontatoPianoA | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th>Piano B<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età tra i 18 e i 74 anni, solo uno dei tre (A, B e C) selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="tipoPianoB" (change)="selezioneTipoPianoSalute(tipoPianoB)">
                  <option value="1">Individuale</option>
                  <option value="2">Familiare</option>
              </select>
              </div>
            </th>
            <th class="text-right pr-3">
              <span   style="font-size: 10px;" *ngIf="premioPianoSaluteB=='Non disponibile'">{{premioPianoSaluteB}}</span>
              <span *ngIf="premioPianoSaluteB!='Non disponibile'">{{premioPianoSaluteB | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input  *ngIf="premioPianoSaluteB=='Non disponibile'" disabled type="checkbox" (change)="checkPianoSalute(2, $event)" [(ngModel)]="selPianoB">
              <input *ngIf="premioPianoSaluteB!='Non disponibile'"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-11-outlined" checked autocomplete="off" [(ngModel)]="selPianoB" (change)="checkPianoSalute(2, $event)">
                <label *ngIf="premioPianoSaluteB!='Non disponibile' && !selPianoB" class=" btn-outline-danger" for="btn-check-11-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="premioPianoSaluteB!='Non disponibile' && selPianoB" class=" btn-danger" style="border-radius: 3px;" for="btn-check-11-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right pr-4">
              <span *ngIf="activePianoB!=null && premioPianoSaluteB!='Non disponibile'">{{premioPianoSaluteB | number:'1.2-2':'es'}} €</span>
              <span *ngIf="activePianoB!=null && premioPianoSaluteB=='Non disponibile'">{{premioPianoSaluteB}}</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activePianoB!=null">{{scontatoPianoB | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th>Piano C<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età tra i 18 e i 74 anni, solo uno dei tre (A, B e C) selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="tipoPianoC" (change)="selezioneTipoPianoSalute(tipoPianoC)">
                  <option value="1">Individuale</option>
                  <option value="2">Familiare</option>
              </select>
              </div>
            </th>
            <th class="text-right pr-3">
              <span style="font-size: 10px;" *ngIf="premioPianoSaluteC=='Non disponibile'">{{premioPianoSaluteC}}</span>
              <span *ngIf="premioPianoSaluteC!='Non disponibile'">{{premioPianoSaluteC | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input  *ngIf="premioPianoSaluteC=='Non disponibile'" disabled type="checkbox" (change)="checkPianoSalute(3, $event)" [(ngModel)]="selPianoC">
              <input *ngIf="premioPianoSaluteC!='Non disponibile'"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-12-outlined" checked autocomplete="off" [(ngModel)]="selPianoC" (change)="checkPianoSalute(3, $event)">
                <label *ngIf="premioPianoSaluteC!='Non disponibile' && !selPianoC" class=" btn-outline-danger" for="btn-check-12-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="premioPianoSaluteC!='Non disponibile' && selPianoC" class=" btn-danger" style="border-radius: 3px;" for="btn-check-12-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right pr-4">
              <span *ngIf="activePianoC!=null && premioPianoSaluteC!='Non disponibile'">{{premioPianoSaluteC | number:'1.2-2':'es'}} €</span>
              <span *ngIf="activePianoC!=null && premioPianoSaluteC=='Non disponibile'">{{premioPianoSaluteC}}</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activePianoC!=null">{{scontatoPianoC | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th>Piano D<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Età tra i 18 e i 74 anni">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="tipoPianoD" (change)="selezioneTipoPianoSalute(tipoPianoD)">
                  <option value="1">Individuale</option>
                  <option value="2">Familiare</option>
              </select>
              </div>
            </th>
            <th class="text-right pr-3">
              <span   style="font-size: 10px;" *ngIf="premioPianoSaluteD=='Non disponibile'">{{premioPianoSaluteD}}</span>
              <span *ngIf="premioPianoSaluteD!='Non disponibile'">{{premioPianoSaluteD | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input  *ngIf="premioPianoSaluteD=='Non disponibile'" disabled type="checkbox" (change)="calcolaPremi()" [(ngModel)]="selPianoD">
              <input *ngIf="premioPianoSaluteD!='Non disponibile'"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-13-outlined" checked autocomplete="off" [(ngModel)]="selPianoD" (change)="checkPianoSalute(4, $event)">
                <label *ngIf="premioPianoSaluteD!='Non disponibile' && !selPianoD" class=" btn-outline-danger" for="btn-check-13-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="premioPianoSaluteD!='Non disponibile' && selPianoD" class=" btn-danger" style="border-radius: 3px;" for="btn-check-13-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right pr-4">
              <span *ngIf="activePianoD!=null && premioPianoSaluteD=='Non disponibile'">{{premioPianoSaluteD}}</span>
              <span *ngIf="activePianoD!=null && premioPianoSaluteD!='Non disponibile'">{{premioPianoSaluteD | number:'1.2-2':'es'}} €</span></th>
            <th class=" text-right pr-4">
              <span *ngIf="activePianoD!=null">{{scontatoPianoD | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="bordo-su" style=" border-right: 1px solid grey;">PERDITA D'IMPIEGO</th>
            <th class="bordo-su">PERDITA D'IMPIEGO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Solo per lavoratori non autonomi">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaPerditaImpiego" (change)="calcolaPremi()">
                  <option value="1">€ &nbsp;&nbsp;&nbsp;&nbsp;500,00</option>
                  <option value="2">€ &nbsp;&nbsp;&nbsp;&nbsp;750,00</option>
                  <option value="3">€ &nbsp;&nbsp;&nbsp;&nbsp;1.000,00</option>
              </select>
              </div>
            </th>
            <th class="bordo-su text-right  pr-3">
              {{premioPerditaImpiego | number:'1.2-2':'es'}} €
            </th>
            <th class="text-center bordo-su check-th-ptop">
              <input *ngIf="(!selTCP && !selAssistenzaPersona) || header.tipoLavoratore != 2" type="checkbox" value="false" disabled>
              <input *ngIf="(selTCP || selAssistenzaPersona) && header.tipoLavoratore == 2"  style="display: none;" type="checkbox" class="btn-check" id="btn-check-14-outlined" checked autocomplete="off" [(ngModel)]="selPerditaImpiego" (change)="calcolaPremi()">
                <label *ngIf="((selTCP || selAssistenzaPersona) && header.tipoLavoratore == 2) && !selPerditaImpiego" class=" btn-outline-danger" for="btn-check-14-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="((selTCP || selAssistenzaPersona) && header.tipoLavoratore == 2) && selPerditaImpiego" class=" btn-danger" style="border-radius: 3px;" for="btn-check-14-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right  pr-4"><span *ngIf="activePerditaImpiego!=null && selPerditaImpiego">{{premioPerditaImpiego | number:'1.2-2':'es'}} €</span></th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activePerditaImpiego!=null && selPerditaImpiego">{{scontatoPerditaImpiego | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="bordo-su" style=" border-right: 1px solid grey;">ASSISTENZA</th>
            <th class="bordo-su">ASSISTENZA PERSONA <span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Calcolato automaticamente">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <!-- VUOTO -->
          </th>
            <th class="bordo-su text-right  pr-3">
              {{premioAssistenzaPersona | number:'1.2-2':'es'}} €
            </th>
            <th class="text-center bordo-su check-th-ptop">
              <input type="checkbox" disabled [(ngModel)]="selAssistenzaPersona">
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activeAssistenzaPersona!=null">{{premioAssistenzaPersona | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activeAssistenzaPersona!=null">{{scontatoAssistenzaPersona | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          
          <tr style="border-top: 2px grey solid !important;">
            <th scope="row" rowspan="14" style="vertical-align: middle; border-right: grey solid 1px;">PATRIMONIO</th>
            <th class="text-uppercase"rowspan="2" style="vertical-align: middle; border-right: 1px solid grey;">RESPONSABILITà CIVILE</th>
            <th>R.C. DELLA VITA PRIVATA<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Sempre selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaRCVita" (change)="calcolaPremi()">
                  <option value="1">€ &nbsp;&nbsp;&nbsp;&nbsp;500.000</option>
                  <option value="2">€ &nbsp;&nbsp;&nbsp;&nbsp;1.000.000</option>
                  <option value="3">€ &nbsp;&nbsp;&nbsp;&nbsp;1.500.000</option>
                  <option value="4">€ &nbsp;&nbsp;&nbsp;&nbsp;2.000.000</option>
                </select>
              </div>
            </th>
            <th class="text-right  pr-3">
              {{premioRCVita | number:'1.2-2':'es'}} €
            </th>
            <th class="text-center check-th-ptop">
              <input  style="display: none;" type="checkbox" class="btn-check" id="btn-check-15-outlined" checked autocomplete="off" [(ngModel)]="selRCVita" (change)="calcolaPremi()">
                <label *ngIf="!selRCVita" class=" btn-outline-danger" for="btn-check-15-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selRCVita" class=" btn-danger" style="border-radius: 3px;" for="btn-check-15-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeRCVita!=null">{{premioRCVita | number:'1.2-2':'es'}} €</span>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeRCVita!=null">{{scontatoRCVita | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="text-uppercase">R.C. DELLA PROPRIETà<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Sempre selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaRCProprieta" (change)="calcolaPremi()">
                  <option value="1">€ &nbsp;&nbsp;&nbsp;&nbsp;500.000</option>
                  <option value="2">€ &nbsp;&nbsp;&nbsp;&nbsp;1.000.000</option>
                  <option value="3">€ &nbsp;&nbsp;&nbsp;&nbsp;1.500.000</option>
                  <option value="4">€ &nbsp;&nbsp;&nbsp;&nbsp;2.000.000</option>
                </select>
              </div>
            </th>
            <th class="text-right  pr-3">
              {{premioRCProprieta | number:'1.2-2':'es'}} €
            </th>
            <th class="text-center check-th-ptop">
              <input  style="display: none;" type="checkbox" class="btn-check" id="btn-check-015-outlined" checked autocomplete="off" [(ngModel)]="selRCProprieta" (change)="calcolaPremi()">
                <label *ngIf="!selRCProprieta" class=" btn-outline-danger" for="btn-check-015-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selRCProprieta" class=" btn-danger" style="border-radius: 3px;" for="btn-check-015-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeRCProprieta!=null">{{premioRCProprieta | number:'1.2-2':'es'}} €</span>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeRCProprieta!=null">{{scontatoRCProprieta | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="bordo-su" rowspan="2" style="vertical-align: middle; border-right: 1px solid grey;">TUTELA LEGALE E CYBER RISK</th>
            <th class="bordo-su">TUTELA LEGALE VITA PRIVATA E IMMOBILE<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria almeno una tra 'RC della vita privata' e 'RC della proprietà'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" style="width: 60%; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="80.000" disabled (change)="calcolaPremi()">
                <div class="input-group-append" style="height: 25px;">
                  <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                </div>
              </div>
            </th>
            <th class="bordo-su text-right  pr-3">30.00 €</th>
            <th class="text-center bordo-su check-th-ptop">
              <input *ngIf="!selRCProprieta && !selRCVita" type="checkbox" disabled [(ngModel)]="selTutelaLegale" (change)="calcolaPremi()">
              <input *ngIf="selRCProprieta || selRCVita" style="display: none;" type="checkbox" class="btn-check" id="btn-check-16-outlined" checked autocomplete="off" [(ngModel)]="selTutelaLegale" (change)="calcolaPremi()">
                <label *ngIf="(selRCProprieta || selRCVita) && !selTutelaLegale" class=" btn-outline-danger" for="btn-check-16-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="(selRCProprieta || selRCVita) && selTutelaLegale" class=" btn-danger" style="border-radius: 3px;" for="btn-check-16-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="selTutelaLegale">30.00 €</span>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="selTutelaLegale">30.00 €</span>
            </th>
          </tr>
          <tr>
            <th>CYBER RISK<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria 'tutela legale vita privata e immobili'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" style="width: 60%; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="80.000" disabled (change)="calcolaPremi()">
                <div class="input-group-append" style="height: 25px;">
                  <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                </div>
              </div>
            </th>
            <th class="text-right  pr-3">30.00 €</th>
            <th class="text-center check-th-ptop">
              <input *ngIf="!selTutelaLegale" type="checkbox" disabled [(ngModel)]="selCyberRisk" (change)="calcolaPremi()">
              <input *ngIf="selRCProprieta || selRCVita" style="display: none;" type="checkbox" class="btn-check" id="btn-check-17-outlined" checked autocomplete="off" [(ngModel)]="selCyberRisk" (change)="calcolaPremi()">
                <label *ngIf="selTutelaLegale && !selCyberRisk" class=" btn-outline-danger" for="btn-check-17-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selTutelaLegale && selCyberRisk" class=" btn-danger" style="border-radius: 3px;" for="btn-check-17-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="selCyberRisk">30.00 €</span>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="selCyberRisk">30.00 €</span>
            </th>
          </tr>
          <tr>
            <th class="bordo-su" rowspan="3" style="vertical-align: middle; border-right: 1px solid grey;">INCENDIO</th>
            <th class="bordo-su">INCENDIO FABBRICATO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Solo uno tra incendio fabbricato e rischio locativo selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <span *ngIf="!sommaIncendioFabbricatoIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempIncendioFabbricato" min="0" max="500000" (blur)="lockIncendioFabbricato()" (keyup.enter)="lockIncendioFabbricato()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaIncendioFabbricatoIsBlocked" (click)="unlockIncendioFabbricato()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaIncendioFabbricato | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
            </th>
            <th class="bordo-su text-right  pr-3"> {{premioIncendioFabbricato | number:'1.2-2':'es'}} €</th>
            <th class="text-center bordo-su check-th-ptop">
              <input style="display: none;" type="checkbox" class="btn-check" id="btn-check-18-outlined" checked autocomplete="off" [(ngModel)]="selIncendioFabbricato" (change)="incendioRischio(1)">
                <label *ngIf="!selIncendioFabbricato" class=" btn-outline-danger" for="btn-check-18-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selIncendioFabbricato" class=" btn-danger" style="border-radius: 3px;" for="btn-check-18-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activeIncendioFabbricato!=null">{{premioIncendioFabbricato | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="activeIncendioFabbricato!=null">{{scontatoIncendioFabbricato | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th>INCENDIO CONTENUTO<span class="ml-2 text-red-custom" title="Sempre selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <span *ngIf="!sommaIncendioContenutoIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempIncendioContenuto" min="0" max="500000" (blur)="lockIncendioContenuto()" (keyup.enter)="lockIncendioContenuto()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaIncendioContenutoIsBlocked" (click)="unlockIncendioContenuto()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaIncendioContenuto | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
            </th>
            <th class="text-right  pr-3">{{premioIncendioContenuto | number:'1.2-2':'es'}} €</th>
            <th class="text-center check-th-ptop">
              <input style="display: none;" type="checkbox" class="btn-check" id="btn-check-19-outlined" checked autocomplete="off" [(ngModel)]="selIncendioContenuto" (change)="calcolaPremi()">
                <label *ngIf="!selIncendioContenuto" class=" btn-outline-danger" for="btn-check-19-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selIncendioContenuto" class=" btn-danger" style="border-radius: 3px;" for="btn-check-19-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeIncendioContenuto!=null">{{premioIncendioContenuto | number:'1.2-2':'es'}} €</span>
            </th>
            <th class=" text-right  pr-4">
              <span *ngIf="activeIncendioContenuto!=null">{{scontatoIncendioContenuto | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th>RISCHIO LOCATIVO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Solo uno tra incendio fabbricato e rischio locativo selezionabile">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center">
              <span *ngIf="!sommaRischioLocativoIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempRischioLocativo" min="0" max="500000" (blur)="lockRischioLocativo()" (keyup.enter)="lockRischioLocativo()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaRischioLocativoIsBlocked" (click)="unlockRischioLocativo()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaRischioLocativo | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
            </th>
            <th class="text-right pr-3">{{premioRischioLocativo | number:'1.2-2':'es'}} €</th>
            <th class="text-center check-th-ptop">
              <input style="display: none;" type="checkbox" class="btn-check" id="btn-check-20-outlined" checked autocomplete="off" [(ngModel)]="selRischioLocativo" (change)="incendioRischio(2)">
                <label *ngIf="!selRischioLocativo" class=" btn-outline-danger" for="btn-check-20-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selRischioLocativo" class=" btn-danger" style="border-radius: 3px;" for="btn-check-20-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class=" text-right pr-4">
              <span *ngIf="activeRischioLocativo!=null">{{premioRischioLocativo | number:'1.2-2':'es'}} €</span>
            </th>
            <th class=" text-right pr-4">
              <span *ngIf="activeRischioLocativo!=null">{{scontatoRischioLocativo | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="bordo-su" style=" border-right: 1px solid grey;">FURTO E RAPINA</th>
            <th class="bordo-su">FURTO DEL CONTENUTO<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria 'incendio contenuto'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <div class="input-group input-group-sm">
                <select dir="rtl" style="width: 60%; height: 25px; font-size: x-small; text-align: right;" class="form-control" [(ngModel)]="sommaFurtoContenuto" (change)="calcolaPremi()">
                  <option value="1">€ Entry Level - 5.000</option>
                  <option value="2">€ Base - 10.000</option>
                  <option *ngIf="header.tipoAbitazione!=3" value="3">€ Plus - 25.000</option>
                  <option *ngIf="header.tipoAbitazione==1" value="4">€ Extra - 50.000</option>
                </select>
              </div>
            </th>
            <th class="bordo-su text-right pr-3">{{premioFurtoContenuto | number:'1.2-2':'es'}} €</th>
            <th class="text-center bordo-su check-th-ptop">
              <input *ngIf="!selIncendioContenuto" disabled type="checkbox" [(ngModel)]="selFurtoContenuto" (change)="calcolaPremi()">
              <input *ngIf="selIncendioContenuto" style="display: none;" type="checkbox" class="btn-check" id="btn-check-21-outlined" checked autocomplete="off" [(ngModel)]="selFurtoContenuto" (change)="calcolaPremi()">
                <label *ngIf="selIncendioContenuto && !selFurtoContenuto" class=" btn-outline-danger" for="btn-check-21-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selIncendioContenuto && selFurtoContenuto" class=" btn-danger" style="border-radius: 3px;" for="btn-check-21-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activeFurtoContenuto!=null">{{activeFurtoContenuto | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activeFurtoContenuto!=null">{{scontatoFurtoContenuto | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="bordo-su" style=" border-right: 1px solid grey;">ENERGIE RINNOVABILI ALL RISK</th>
            <th class="bordo-su">ENERGIE RINNOVABILI ALL RISK<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Necessaria 'incendio contenuto'">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <span *ngIf="!sommaEnergieRinnovabiliIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempEnergieRinnovabili" min="0" max="500000" (blur)="lockEnergieRinnovabili()" (keyup.enter)="lockEnergieRinnovabili()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="sommaEnergieRinnovabiliIsBlocked" (click)="unlockEnergieRinnovabili()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaEnergieRinnovabili | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
          </th>
            <th class="bordo-su text-right pr-3">
              {{premioEnergieRinnovabili | number:'1.2-2':'es'}} €
            </th>
            <th class="text-center bordo-su check-th-ptop">
              <input *ngIf="!selIncendioContenuto && !selIncendioFabbricato" type="checkbox" disabled [(ngModel)]="selEnergieRinnovabili" (change)="calcolaPremi()">
              <input *ngIf="selIncendioContenuto || selIncendioFabbricato" style="display: none;" type="checkbox" class="btn-check" id="btn-check-22-outlined" checked autocomplete="off" [(ngModel)]="selEnergieRinnovabili" (change)="calcolaPremi()">
                <label *ngIf="(selIncendioContenuto || selIncendioFabbricato) && !selEnergieRinnovabili" class=" btn-outline-danger" for="btn-check-22-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="(selIncendioContenuto || selIncendioFabbricato) && selEnergieRinnovabili" class=" btn-danger" style="border-radius: 3px;" for="btn-check-22-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activeEnergieRinnovabili!=null">{{activeEnergieRinnovabili | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activeEnergieRinnovabili!=null">{{scontatoEnergieRinnovabili | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>


          <tr>
            <th class="bordo-su" rowspan="4"  style=" border-right: 1px solid grey;">TERREMOTO ED ALLUVIONE</th>
            <th class="bordo-su text-uppercase">Terremoto del fabbricato<span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Selezionabili solo insieme 'terremoto e alluvione del fabbricato', se selezionato 'incendio del fabbricato' la somma è calcolata in automatico e il CAP deve essere valido">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <span *ngIf="!selIncendioFabbricato && !sommaTerremotoFabbricatoIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempTerremotoFabbricato" min="0" max="1500000" (blur)="lockTerremotoFabbricato()" (keyup.enter)="lockTerremotoFabbricato()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="!selIncendioFabbricato && sommaTerremotoFabbricatoIsBlocked" (click)="unlockTerremotoFabbricato()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaTerremotoFabbricato | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="selIncendioFabbricato">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaTerremotoFabbricato | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
            </th>
            <th class="text-right pr-3 bordo-su">
              <span *ngIf="!isCapValid">
                Non disponibile
              </span>
              <span *ngIf="isCapValid">
                {{premioTerremotoFabbricato | number:'1.2-2':'es'}} €
              </span>
            </th>
            <th class="text-center bordo-su check-th-ptop">
              <input *ngIf="!isCapValid" disabled type="checkbox">
              <input *ngIf="isCapValid" style="display: none;" type="checkbox" class="btn-check" id="btn-check-23-outlined" checked autocomplete="off" [(ngModel)]="selTerremotoFabbricato" (change)="checkTerrAllFab(1)">
                <label *ngIf="isCapValid && !selTerremotoFabbricato" class=" btn-outline-danger" for="btn-check-23-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="isCapValid && selTerremotoFabbricato" class=" btn-danger" style="border-radius: 3px;" for="btn-check-23-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="selTerremotoFabbricato">{{activeTerremotoFabbricato | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="selTerremotoFabbricato">{{scontatoTerremotoFabbricato | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="text-uppercase">Terremoto del contenuto<span style="vertical-align: text-bottom;" class="text-red-custom ml-2" title="Selezionabili solo insieme 'terremoto e alluvione del contenuto', se selezionate 'incendio e alluvione fabbricato' la somma è calcolata in automatico e la provincia deve essere valida">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center" >
              <div class="input-group input-group-sm">
                <input class="form-control" disabled style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaTerremotoContenuto | number:'1.2-2':'es'}}">
                <div class="input-group-append" style="height: 25px;">
                 <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                </div>
              </div>
            </th>
            <th class="text-right pr-3">
              <span *ngIf="!isCapValid">
                Non disponibile
              </span>
              <span *ngIf="isCapValid">
                {{premioTerremotoContenuto | number:'1.2-2':'es'}} €
              </span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="!selTerremotoFabbricato" disabled type="checkbox" [(ngModel)]="selTerremotoContenuto">
              <input *ngIf="selTerremotoFabbricato" style="display: none;" type="checkbox" class="btn-check" id="btn-check-24-outlined" checked autocomplete="off" [(ngModel)]="selTerremotoContenuto" (change)="checkTerrAllCon(1)">
                <label *ngIf="selTerremotoFabbricato && !selTerremotoContenuto" class=" btn-outline-danger" for="btn-check-24-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selTerremotoFabbricato && selTerremotoContenuto" class=" btn-danger" style="border-radius: 3px;" for="btn-check-24-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="text-right pr-4">
              <span *ngIf="selTerremotoContenuto">{{activeTerremotoContenuto | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-right pr-4">
              <span *ngIf="selTerremotoContenuto">{{scontatoTerremotoContenuto | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="text-uppercase">Alluvione del fabbricato
              <span class="ml-2 text-red-custom" style="vertical-align: text-bottom;" title="Selezionabili solo insieme 'terremoto e alluvione del fabbricato', se selezionato 'incendio del fabbricato' la somma è calcolata in automatico e il CAP deve essere valido">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                </svg>
              </span>
            </th>
            <th class="text-center">
              <span *ngIf="!selIncendioFabbricato && !sommaTerremotoFabbricatoIsBlocked" >
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" [(ngModel)]="tempTerremotoFabbricato" min="0" max="1500000" (blur)="lockTerremotoFabbricato()" (keyup.enter)="lockTerremotoFabbricato()">
                  <div class="input-group-append" style="height: 25px;">
                   <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="!selIncendioFabbricato && sommaTerremotoFabbricatoIsBlocked" (click)="unlockTerremotoFabbricato()">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaTerremotoFabbricato | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
              <span *ngIf="selIncendioFabbricato">
                <div class="input-group input-group-sm">
                  <input disabled type="text" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;" value="{{sommaTerremotoFabbricato | number:'1.2-2':'es'}}">
                  <div class="input-group-append" style="height: 25px;">
                    <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                  </div>
                </div>
              </span>
            </th>
            <th class="text-right pr-3">
              <span *ngIf="!isCapValid">
                Non disponibile
              </span>
              <span *ngIf="isCapValid">
                {{premioAlluvioneFabbricato | number:'1.2-2':'es'}} €
              </span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="!isCapValid" disabled type="checkbox">
              <input *ngIf="isCapValid" style="display: none;" type="checkbox" class="btn-check" id="btn-check-25-outlined" checked autocomplete="off" [(ngModel)]="selAlluvioneFabbricato" (change)="checkTerrAllFab(2)">
                <label *ngIf="isCapValid && !selAlluvioneFabbricato" class=" btn-outline-danger" for="btn-check-25-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="isCapValid && selAlluvioneFabbricato" class=" btn-danger" style="border-radius: 3px;" for="btn-check-25-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="text-right pr-4">
              <span *ngIf="selAlluvioneFabbricato">{{activeAlluvioneFabbricato | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-right pr-4">
              <span *ngIf="selAlluvioneFabbricato">{{scontatoAlluvioneFabbricato | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="text-uppercase">Alluvione del contenuto
              <span style="vertical-align: text-bottom;" class="ml-2 text-red-custom" title="Selezionabili solo insieme 'terremoto e alluvione del contenuto', se selezionate 'incendio e alluvione fabbricato' la somma è calcolata in automatico e la provincia deve essere valida">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                </svg>
              </span>
            </th>
            <th class="text-center">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" disabled style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: right;"  value="{{sommaTerremotoContenuto | number:'1.2-2':'es'}}">
                <div class="input-group-append" style="height: 25px;">
                 <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                </div>
              </div>
            </th>
            <th class="text-right pr-3">
              <span *ngIf="!isCapValid">
                Non disponibile
              </span>
              <span *ngIf="isCapValid">
                {{premioAlluvioneContenuto | number:'1.2-2':'es'}} €
              </span>
            </th>
            <th class="text-center check-th-ptop">
              <input *ngIf="!selAlluvioneFabbricato" disabled type="checkbox" [(ngModel)]="selAlluvioneContenuto">
              <input *ngIf="selAlluvioneFabbricato" style="display: none;" type="checkbox" class="btn-check" id="btn-check-26-outlined" checked autocomplete="off" [(ngModel)]="selAlluvioneContenuto" (change)="checkTerrAllCon(2)">
                <label *ngIf="selAlluvioneFabbricato && !selAlluvioneContenuto" class=" btn-outline-danger" for="btn-check-26-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
                </label>
                <label *ngIf="selAlluvioneFabbricato && selAlluvioneContenuto" class=" btn-danger" style="border-radius: 3px;" for="btn-check-26-outlined">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                </label>
            </th>
            <th class="text-right pr-4">
              <span *ngIf="selAlluvioneContenuto">{{activeAlluvioneContenuto | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-right pr-4">
              <span *ngIf="selAlluvioneContenuto">{{scontatoAlluvioneContenuto | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>



          <tr>
            <th class="bordo-su" style=" border-right: 1px solid grey;">ASSISTENZA ABITAZIONE</th>
            <th class="bordo-su">ASSISTENZA ABITAZIONE <span  style="vertical-align: text-bottom;" class="text-red-custom ml-2" title="Calcolato automaticamente">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </span></th>
            <th class="text-center bordo-su">
              <!-- VUOTO -->
            </th>
            <th class="bordo-su text-right pr-3">8.80 €</th>
            <th class="text-center bordo-su check-th-ptop">
              <input type="checkbox" disabled  [(ngModel)]="selAssistenzaAbitazione" >
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activeAssistenzaAbitazione!=null">{{premioAssistenzaAbitazione}} €</span>
            </th>
            <th class="bordo-su text-right pr-4">
              <span *ngIf="activeAssistenzaAbitazione!=null">{{scontatoAssistenzaAbitazione | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr style="border-top: double 3px grey;">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-right"><b> TOTALE</b></th>
            <th class="text-right pr-4"><b> {{totale | number:'1.2-2':'es'}} €</b></th>
            <th class="text-right pr-4"><b> {{totaleScontato | number:'1.2-2':'es'}} €</b></th>
          </tr>
          <tr style="border-top: double 3px grey; border-bottom: double 3px grey;">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-right"><b> MENSILIZZATO</b></th>
            <th class="text-right pr-4"><b> {{mensilizzato | number:'1.2-2':'es'}} €</b></th>
            <th class="text-right pr-4"><b> {{mensilizzatoScontato | number:'1.2-2':'es'}} €</b></th>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</div>
<div class="modal bd-example-modal-lg fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Garanzie selezionate</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
        <table class="table table-hover">
          <thead>
            <tr>
              <td class="carrello" scope="col">Garanzia</td>
              <td class="carrello text-right" scope="col">Premio</td>
              <td class="carrello text-right" scope="col">Premio scontato</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let garanzia of garanzieSelezionate">
              <th class="carrello">{{garanzia.garanzia}}</th>
              <th class="carrello text-right">{{garanzia.premio | number:'1.2-2':'es'}} €</th>
              <th class="carrello text-right">{{garanzia.premioScontato | number:'1.2-2':'es'}} €</th>
            </tr>
            <tr>
              <td class="carrello text-right font-weight-bold">Totale</td>
              <td class="carrello text-right font-weight-bold">{{totale | number:'1.2-2':'es'}} €</td>
              <td class="carrello text-right font-weight-bold">{{totaleScontato | number:'1.2-2':'es'}} €</td>
            </tr>
            <tr>
              <td class="carrello text-right font-weight-bold">Mensilizzato</td>
              <td class="carrello text-right font-weight-bold">{{mensilizzato | number:'1.2-2':'es'}} €</td>
              <td class="carrello text-right font-weight-bold">{{mensilizzatoScontato | number:'1.2-2':'es'}} €</td>
            </tr>
            <tr style="border-top: grey solid 2px;">
              <td class="carrello"></td>
              <td class="carrello text-right font-weight-bold">Percentuale di sconto</td>
              <td class="carrello text-right font-weight-bold">{{sconto}} %</td>
            </tr>
            <tr>
              <td class="carrello text-right font-weight-bold"></td>
              <td class="carrello text-right font-weight-bold">Importo scontato annuo</td>
              <td class="carrello text-right font-weight-bold">{{(totale - totaleScontato) | number:'1.2-2':'es'}} €</td>
            </tr>
            <tr>
              <td class="carrello text-right font-weight-bold"></td>
              <td class="carrello text-right font-weight-bold">Importo scontato mensile</td>
              <td class="carrello text-right font-weight-bold">{{(mensilizzato - mensilizzatoScontato) | number:'1.2-2':'es'}} €</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
      </div>
    </div>
  </div>
</div>