import { Component, OnInit } from '@angular/core';
import { registerLocaleData} from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-ltc',
  templateUrl: './ltc.component.html',
  styleUrls: ['./ltc.component.css']
})
export class LtcComponent implements OnInit {

  array = [
    {anni:18, ltc:28.9401082796472, tcm:3.64695243277227},
    {anni:19, ltc:29.3439783899204, tcm:3.70613598186871},
    {anni:20, ltc:29.7623970561374, tcm:3.76735509977533},
    {anni:21, ltc:30.1959500501521, tcm:3.83071458706156},
    {anni:22, ltc:30.645338844125, tcm:3.89632331794099},
    {anni:23, ltc:31.1112916977185, tcm:3.9642934387242},
    {anni:24, ltc:31.5946168335241, tcm:4.03474274179523},
    {anni:25, ltc:32.096190618767, tcm:4.10779782384711},
    {anni:26, ltc:32.616966077596, tcm:4.18359705912074},
    {anni:27, ltc:33.1580002530525, tcm:4.26229343674864},
    {anni:28, ltc:33.7203826291446, tcm:4.34405722535815},
    {anni:29, ltc:34.3052659297571, tcm:4.42908065301949},
    {anni:30, ltc:34.9139304622958, tcm:4.5175820352114},
    {anni:31, ltc:35.5477168895683, tcm:4.60980643530229},
    {anni:32, ltc:36.2080705655268, tcm:4.70602583303455},
    {anni:33, ltc:36.8965655872337, tcm:4.80652249711779},
    {anni:34, ltc:37.6148362269008, tcm:4.91158810220812},
    {anni:35, ltc:38.3643516366981, tcm:5.02152349430967},
    {anni:36, ltc:39.1467225375995, tcm:5.13663835878972},
    {anni:37, ltc:39.9638028549612, tcm:5.25725257405661},
    {anni:38, ltc:40.8174809086907, tcm:5.38370029292134},
    {anni:39, ltc:41.7096038065611, tcm:5.51632176883901},
    {anni:40, ltc:42.6425921431385, tcm:5.65544536973463},
    {anni:41, ltc:43.618199171232, tcm:5.80138005440497},
    {anni:42, ltc:44.6374703097448, tcm:5.95453759291991},
    {anni:43, ltc:45.7022924300385, tcm:6.1154455606211},
    {anni:44, ltc:46.8160887038317, tcm:6.28473550070602},
    {anni:45, ltc:47.9823327204143, tcm:6.46310736845128},
    {anni:46, ltc:49.2040094802798, tcm:6.6512759268456},
    {anni:47, ltc:50.4852854779705, tcm:6.84991112326407},
    {anni:48, ltc:51.8281265390931, tcm:7.05966330592271},
    {anni:49, ltc:53.2337244187338, tcm:7.2810542829534},
    {anni:50, ltc:54.7068878585232, tcm:7.51443699188292},
    {anni:51, ltc:56.2458900055927, tcm:7.7600026871612},
    {anni:52, ltc:57.856070640262, tcm:8.01811550105269},
    {anni:53, ltc:59.54211865217, tcm:8.28929947756811},
    {anni:54, ltc:61.2994343306073, tcm:8.57489965525388},
    {anni:55, ltc:63.1258043193247, tcm:8.87742901880454},
    {anni:56, ltc:65.034363038867, tcm:9.19952599680296},
    {anni:57, ltc:67.0104455533257, tcm:9.54357891655495},
    {anni:58, ltc:69.0633677733946, tcm:9.911183363264},
    {anni:59, ltc:71.2214892097478, tcm:10.303815724226},
    {anni:60, ltc:73.4907718597004, tcm:10.722877601931},
    {anni:61, ltc:75.8789546511933, tcm:11.1703077224644},
    {anni:62, ltc:78.4053360195116, tcm:11.6487657913448},
    {anni:63, ltc:81.1027123808621, tcm:12.1616310389592},
    {anni:64, ltc:83.9904811126989, tcm:12.7129062354748},
    {anni:65, ltc:87.079381155291, tcm:13.3079623521316},
    {anni:66, ltc:90.3371120486268, tcm:13.9528920256688},
    {anni:67, ltc:93.7628572509315, tcm:14.6533025573023},
    {anni:68, ltc:97.451949272896, tcm:15.4144173528404},
    {anni:69, ltc:101.412676334807, tcm:16.2464558610787},
    {anni:70, ltc:105.58741152387, tcm:17.1653039337376},
    {anni:71, ltc:110.070405699401, tcm:18.1945778918222},
    {anni:72, ltc:114.677178264084, tcm:19.3710363799251},
    {anni:73, ltc:119.543154452888, tcm:20.7427195353359},
    {anni:74, ltc:124.922573550831, tcm:22.3829657499735},
    {anni:75, ltc:130.756496029141, tcm:24.4004100451944}
  ]

  moduleId: String = "LTC";

  anni: number = 18;
  valoreLTC: number = 28.9401082796472;
  valoreTCM: number = 3.64695243277227;

  selLTC: boolean;
  sommaLTC: number = 2500;
  premioLTC: number;
  activeLTC: number = 0;
  selTCM: boolean;
  sommaTCM: number = 2500;
  premioTCM: number;
  activeTCM: number = 0;

  sommaLTCIsBlocked:boolean = true;
  sommaTCMIsBlocked:boolean = true;
  tempLTC:any = 2500;
  tempTCM:any = 2500;

  totale: number = 0;
  mensilizzato: number = 0;
  garanzieSelezionate: {garanzia:string, premio: any, premioScontato:any}[];
  constructor() { }

  ngOnInit(): void {
    registerLocaleData( es );
    this.garanzieSelezionate = [];
    this.calcolaPremi();
  }

  ageControl(){
    if (this.anni>75) {
      this.anni = 75;
    } else if (this.anni<18) {
      this.anni = 18;
    }
    this.calcolaPremi();
  }

  calcolaPremi(){
    this.tempLTC = (Math.ceil(this.tempLTC/100.0)*100);
    this.sommaLTC = (Math.ceil(this.sommaLTC/100.0)*100);
    for (let i = 0; i < this.array.length; i++) {
      if (this.array[i].anni == this.anni) {
        this.valoreLTC = this.array[i].ltc;
        this.valoreTCM = this.array[i].tcm;
      }
    }
    this.sommaTCM = this.sommaLTC;
    this.premioLTC = (this.valoreLTC/1000)*this.sommaLTC*12;
    this.premioTCM = (this.valoreTCM/1000)*this.sommaTCM*12;
    this.limitControl();
    if (this.selLTC) {
      this.activeLTC = this.premioLTC;
    } else {
      this.selTCM = false;
      this.activeLTC = 0;
    }
    if (this.selTCM) {
      this.activeTCM = this.premioTCM;
    } else {
      this.activeTCM = 0;
    }
    this.calcoloTotali();
  }

  limitControl(){
    if (this.sommaLTC>2500) {
      this.sommaLTC = 2500;
      this.tempLTC = 2500;
    } else if (this.sommaLTC<500) {
      this.sommaLTC = 500;
      this.tempLTC = 500;
    }
  }

  removeAll(){
    this.totale = 0;
    this.mensilizzato = 0;
    this.garanzieSelezionate = [];
    this.selLTC = false;
    this.selTCM = false;
  }

  calcoloTotali(){
    this.totale = this.activeLTC + this.activeTCM;
    this.mensilizzato = this.totale/12;
    this.garanzieSelezionate = [];
    if (this.selLTC){
      this.garanzieSelezionate.push({garanzia: "LTC", premio: this.activeLTC.toFixed(2), premioScontato: this.activeLTC.toFixed(2)})
    } 
    if (this.selTCM){
      this.garanzieSelezionate.push({garanzia: "TEMPORANEA CASO MORTE", premio: this.activeTCM.toFixed(2), premioScontato: this.activeTCM.toFixed(2)})
    }
  }

  lockLTC(){
    this.sommaLTCIsBlocked = true;
    this.sommaLTC = this.tempLTC;
    this.calcolaPremi();
  }

  unlockLTC(){
    this.sommaLTCIsBlocked = false;
    this.calcolaPremi();
  }

  lockTCM(){
    this.sommaTCMIsBlocked = true;
    this.sommaLTC = this.tempLTC;
    this.calcolaPremi();
  }

  unlockTCM(){
    this.sommaTCMIsBlocked = false;
    this.calcolaPremi();
  }


}
