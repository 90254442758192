<div class="container-fluid" style="height: 50.81px;">
  <div class="row bg-red-custom"  style="vertical-align: middle !important;">
    <img class="bg-light px-2" height="50.81px" width="200em" src="assets/svg/logo_Sparkasse-01.svg" alt="">
    <img class="bg-light px-1 py-1" height="50.81px" src="assets/img/nins-logo.jpeg" alt="">
    <button style="vertical-align: middle !important; border-right: 1px white solid; height: 50.81px;" class="btn text-center btn-lg btn-red-custom-nohover titolo-navbar" (click)="navigateToHome()">
      <svg style="vertical-align: middle" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi home bi-house-door-fill" viewBox="0 0 16 16">
        <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"/>
      </svg>
    </button>
    <div class="col text-light" style="vertical-align: middle !important;">
      <button *ngIf="moduleOpened=='Protection'" style="vertical-align: middle !important; height: 50.81px;" class="btn btn-lg btn-red-custom-selected titolo-navbar" (click)="navigateToProtection()">Protection</button>
      <button *ngIf="moduleOpened!='Protection'" style="vertical-align: middle !important; height: 50.81px;" class="btn btn-lg btn-red-custom-nohover titolo-navbar" (click)="navigateToProtection()">Protection</button>
      <button *ngIf="moduleOpened=='Protection-business'" style="vertical-align: middle !important; height: 50.81px;" class="ml-3 btn btn-lg btn-red-custom-selected titolo-navbar" (click)="navigateToProtectionBusiness()">Protection Business</button>
      <button *ngIf="moduleOpened!='Protection-business'" style="vertical-align: middle !important; height: 50.81px;" class="ml-3 btn btn-lg btn-red-custom-nohover titolo-navbar" (click)="navigateToProtectionBusiness()">Protection Business</button>
      <button *ngIf="moduleOpened=='LTC'" style="vertical-align: middle !important; height: 50.81px;" class="ml-3 btn btn-lg btn-red-custom-selected titolo-navbar" (click)="navigateToLTC()">Protection LTC</button>
      <button *ngIf="moduleOpened!='LTC'" style="vertical-align: middle !important; height: 50.81px;" class="ml-3 btn btn-lg btn-red-custom-nohover titolo-navbar" (click)="navigateToLTC()">Protection LTC</button>
    </div>
  </div>
</div>
<router-outlet (activate)="onActivate($event)"></router-outlet>