<div class="container" style="font-size: 10px;">
  <div class="row mt-4">
    <div class="col-10">
      <div class="row">
        <div class="col-4 header-row font-weight-bold header-title">
          Età assicurato
        </div>
        <div class="col-3 header-row">
          <div class="input-group input-group-sm ml-1">
            <input type="number" class="form-control" min="18" max="75" [(ngModel)]="anni" (change)="ageControl()">
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 text-right mb-4">
      <button type="button" class="btn btn-red-custom" data-toggle="modal" data-target="#exampleModal">
        Carrello
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-fill" viewBox="0 0 16 16">
          <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
        </svg>
      </button>
      <br>
      <button class="btn btn-red-custom mt-2" (click)="removeAll()">Svuota</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-right font-weight-bold" style="font-size: 13px;">
          Sconto: 0 %
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <div class="">
      <table class="table table-borderless" style="border-right: solid grey 1px; border-left: solid grey 1px">
          <thead class="bg-red-custom text-light">
            <tr class="tab-head font-weight-bold">
            <td class="tab-head bg-red-custom" scope="col" style="width: 11%; vertical-align: middle;">Bisogno</td>
            <td class="tab-head bg-red-custom" scope="col" style="width: 19%; vertical-align: middle;">Sezione</td>
            <td class="tab-head bg-red-custom" scope="col" style="width: 22%; vertical-align: middle;">Garanzia</td>
            <td class="tab-head bg-red-custom" scope="col" style="width: 8%; vertical-align: middle;">Somma assicurata/Forma</td>
            <td class="tab-head bg-red-custom pl-4" scope="col" style="width: 10%; vertical-align: middle;">Premio</td>
            <td class="tab-head bg-red-custom text-center" scope="col" style="width: 6%; vertical-align: middle;">Selezione</td>
            <td class="tab-head bg-red-custom pl-4" scope="col" style="width: 8%; vertical-align: middle;">Premio selezione</td>
            <td class="tab-head bg-red-custom pl-4" scope="col" style="width: 8%; vertical-align: middle;">Premio scontato</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th rowspan="2" style=" border-right: 1px solid grey;">VITA SERENA</th>
            <th rowspan="2" style=" border-right: 1px solid grey;">LONG TERM CARE</th>
            <th style=" border-right: 1px solid grey;">LONG TERM CARE</th>
            <th class="text-center">
                <span *ngIf="!sommaLTCIsBlocked" >
                  <div class="input-group input-group-sm">
                    <input type="number" step="100" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: end;" [(ngModel)]="tempLTC" min="500" max="2500" (blur)="lockLTC()" (keyup.enter)="lockLTC()">
                    <div class="input-group-append" style="height: 25px;">
                     <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                    </div>
                  </div>
                </span>
                <span *ngIf="sommaLTCIsBlocked" (click)="unlockLTC()">
                  <div class="input-group input-group-sm">
                    <input disabled type="text" step="100" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: end;" value="{{sommaLTC | number:'1.2-2':'es'}}">
                    <div class="input-group-append" style="height: 25px;">
                      <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                    </div>
                  </div>
                </span>
            </th>
            <th class="text-right pr-3">
              <span>{{premioLTC | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center check-th-ptop">
              <input style="display: none;" type="checkbox" class="btn-check" id="btn-check-3-outlined" checked autocomplete="off" [(ngModel)]="selLTC" (change)="calcolaPremi()">
              <label *ngIf="!selLTC" class=" btn-outline-danger" for="btn-check-3-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="selLTC" class=" btn-danger" style="border-radius: 3px;" for="btn-check-3-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class="text-right  pr-4">
              <span *ngIf="selLTC">{{premioLTC | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-right  pr-4">
              <span *ngIf="selLTC">{{premioLTC | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr>
            <th class="bordo-su" style=" border-right: 1px solid grey;">TEMPORANEA CASO MORTE</th>
            <th class="text-center bordo-su">
                <span *ngIf="!sommaTCMIsBlocked" >
                  <div class="input-group input-group-sm">
                    <input type="number" class="form-control" style="width: 60%; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: end;" [(ngModel)]="tempLTC" min="500" max="2500" (blur)="lockTCM()" (keyup.enter)="lockTCM()">
                    <div class="input-group-append" style="height: 25px;">
                     <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                    </div>
                  </div>
                </span>
                <span *ngIf="sommaTCMIsBlocked" (click)="unlockTCM()">
                  <div class="input-group input-group-sm">
                    <input disabled type="text" class="form-control" style="width: 60%; background-color: white; border: lightgrey solid 1px; height: 25px; font-size: x-small; border-right: none; text-align: end;" value="{{sommaLTC | number:'1.2-2':'es'}}">
                    <div class="input-group-append" style="height: 25px;">
                      <span class="input-group-text" style="background-color: white; font-size: x-small; height: 25px;">€&nbsp;</span>
                    </div>
                  </div>
                </span>
            </th>
            <th class="bordo-su text-right  pr-3">
              <span>{{premioTCM | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="text-center bordo-su check-th-ptop">
              <input *ngIf="!selLTC" type="checkbox" class="btn-check" disabled>
              <input style="display: none;" type="checkbox" class="btn-check" id="btn-check-1-outlined" checked autocomplete="off" [(ngModel)]="selTCM" (change)="calcolaPremi()">
              <label *ngIf="!selTCM && selLTC" class=" btn-outline-danger" for="btn-check-1-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                  <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                </svg>
              </label>
              <label *ngIf="selTCM && selLTC" class=" btn-danger" style="border-radius: 3px;" for="btn-check-1-outlined">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </label>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="selTCM">{{premioTCM | number:'1.2-2':'es'}} €</span>
            </th>
            <th class="bordo-su text-right  pr-4">
              <span *ngIf="selTCM">{{premioTCM | number:'1.2-2':'es'}} €</span>
            </th>
          </tr>
          <tr style="border-top: double 3px grey;">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-right"><b> TOTALE</b></th>
            <th class="text-right pr-4"><b> {{totale | number:'1.2-2':'es'}} €</b></th>
            <th class="text-right pr-4"><b> {{totale | number:'1.2-2':'es'}} €</b></th>
          </tr>
          <tr style="border-top: double 3px grey; border-bottom: double 3px grey;">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th class="text-right"><b> MENSILIZZATO</b></th>
            <th class="text-right pr-4"><b> {{mensilizzato | number:'1.2-2':'es'}} €</b></th>
            <th class="text-right pr-4"><b> {{mensilizzato | number:'1.2-2':'es'}} €</b></th>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</div>

<div class="modal bd-example-modal-lg fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Garanzie selezionate</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
        <table class="table table-hover">
          <thead>
            <tr>
              <td class="carrello" scope="col">Garanzia</td>
              <td class="carrello text-right" scope="col">Premio</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let garanzia of garanzieSelezionate">
              <th class="carrello">{{garanzia.garanzia}}</th>
              <th class="carrello text-right">{{garanzia.premio | number:'1.2-2':'es'}} €</th>
            </tr>
            <tr>
              <td class="carrello text-right font-weight-bold">Totale</td>
              <td class="carrello text-right font-weight-bold">{{totale | number:'1.2-2':'es'}} €</td>
            </tr>
            <tr>
              <td class="carrello text-right font-weight-bold">Mensilizzato</td>
              <td class="carrello text-right font-weight-bold">{{mensilizzato | number:'1.2-2':'es'}} €</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
      </div>
    </div>
  </div>
</div>