import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LtcComponent } from './components/ltc/ltc.component';
import { ProtectionBusinessComponent } from './components/protection-business/protection-business.component';
import { ProtectionComponent } from './components/protection/protection.component';


const routes: Routes = [
  { path: '', component: ProtectionComponent },
  { path: 'protection', component: ProtectionComponent },
  { path: 'protection-business', component: ProtectionBusinessComponent },
  { path: 'ltc', component: LtcComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
